import { Interview } from 'models/interview';
import { Project } from 'models/project';

export const routes = {
  home: () => '/',
  login: () => '/login',
  signUp: () => '/signup',
  forgotPassword: () => '/forgot-password',
  resetPassword: () => '/reset-password',
  privacy: () => '/privacy',
  termsOfService: () => '/terms-of-service',
  cookiePolicy: () => '/cookie-policy',
  marketplace: () => '/marketplace',
  profile: () => '/profile',
  projects: () => '/projects',
  project: (projectId?: Project['id']) => `/projects/${projectId || ':projectId'}`,
  interviews: (projectId?: Project['id']) => `/projects/${projectId || ':projectId'}/interviews`,
  interview: (projectId?: Project['id'], interviewId?: Interview['id']) =>
    `/projects/${projectId || ':projectId'}/interviews/${interviewId || ':interviewId'}`,
  report: (projectId?: Project['id']) => `/projects/${projectId || ':projectId'}/report`,
  sharedReport: (uuid?: string) => `/shared-report/${uuid || ':uuid'}`,
  paymentStatus: (paymentStatusCode?: string) => `/pay-info/${paymentStatusCode || ':paymentStatusCode'}`,
  billing: () => '/billing',
};

