import { useTour } from '@reactour/tour';
import React, { ReactNode, useCallback, useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';

import { routes } from 'api/routes';

import { useUser } from 'hooks/user';

import {
  projectsPageSteps,
  projectPageSteps,
  interviewsPageSteps,
  interviewPageSteps,
  reportPageSteps,
} from './onboarding.steps';

export interface OnboardingMiddlewareProps {
  children: ReactNode;
  onFinish: () => Promise<void>;
}

export function OnboardingMiddleware({ children, onFinish }: OnboardingMiddlewareProps) {
  const location = useLocation();
  const { setSteps, setIsOpen, setCurrentStep } = useTour();

  const { userPermissions } = useUser();

  const resetTour = useCallback(() => {
    setIsOpen(true);
    setCurrentStep(0);
  }, []);

  const handleFinish = () => {
    setIsOpen(false);
    onFinish();
  };

  useEffect(() => {
    if (!userPermissions || userPermissions.onboarding) {
      return;
    }

    if (matchPath(routes.projects(), location.pathname)) {
      resetTour();
      setSteps?.(projectsPageSteps);
    } else if (matchPath(routes.project(), location.pathname)) {
      resetTour();
      setSteps?.(projectPageSteps);
    } else if (matchPath(routes.interviews(), location.pathname)) {
      resetTour();
      setSteps?.(interviewsPageSteps);
    } else if (matchPath(routes.interview(), location.pathname)) {
      resetTour();
      setSteps?.(interviewPageSteps);
    } else if (matchPath(routes.report(), location.pathname)) {
      resetTour();
      setSteps?.(reportPageSteps(handleFinish));
    } else {
      setIsOpen(false);
      setSteps?.([]);
    }
  }, [location, userPermissions]);

  return <>{children}</>;
}

