import { createSlice } from '@reduxjs/toolkit';

import { storeHelper } from 'helpers/store';

import { RootState } from 'store/store';

interface State {
  uploadProgress: number;
}

const initialState: State = {
  uploadProgress: 0,
};

const { setAction } = storeHelper<State>();

export const fileSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    setUploadProgress: setAction('uploadProgress'),
  },
});

export const fileActions = fileSlice.actions;

export const uploadProgressSelector = (state: RootState): State => state.file;

