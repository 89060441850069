import { Radio as AntdRadio } from 'antd';
import { RadioGroupProps as AntdRadioGroupProps } from 'antd/lib/radio';
import cn from 'classnames';
import React from 'react';

import styles from './radio.module.scss';

export interface RadioProps extends AntdRadioGroupProps {
  className?: string;
}

export function Radio({ className, size = 'large', ...props }: RadioProps) {
  const classes = cn(styles.radio, className);

  return <AntdRadio.Group className={classes} size={size} {...props} />;
}

