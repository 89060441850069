import { Table as AntdTable } from 'antd';
import { TableProps as AntdTableProps, TablePaginationConfig } from 'antd/lib/table';
import cn from 'classnames';
import React from 'react';

import { DEFAULT_TABLE_PAGE_SIZE } from 'helpers/constants';

import styles from './table.module.scss';

export interface TableProps<T> extends AntdTableProps<T> {
  className?: string;
}

const defaultPagination: TablePaginationConfig = {
  position: ['bottomLeft'],
  hideOnSinglePage: true,
  showSizeChanger: false,
  defaultPageSize: DEFAULT_TABLE_PAGE_SIZE,
};

export function Table<T extends object>({ className, pagination, showSorterTooltip = false, ...props }: TableProps<T>) {
  const classes = cn(styles.table, className);

  return (
    <AntdTable<T>
      className={classes}
      pagination={{ ...defaultPagination, ...pagination }}
      showSorterTooltip={showSorterTooltip}
      {...props}
    />
  );
}

