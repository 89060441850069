import { notification } from 'antd';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import { Table, TablePaginationConfig } from 'shared/table';

import { DEFAULT_TABLE_PAGE_SIZE } from 'helpers/constants';
import { getResponseError } from 'helpers/response';

import { BillingRow } from 'models/billing';

import { useGetFilesQuery } from '../../billing.service';
import { columns } from './files.column-props';
import styles from './files.module.scss';

export interface FilesProps {
  className?: string;
}

export function Files({ className }: FilesProps) {
  const classes = cn(styles.files, className);

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
  });

  const { data, error, isError, isLoading } = useGetFilesQuery({
    page: pagination.current,
    results: DEFAULT_TABLE_PAGE_SIZE,
  });

  useEffect(() => {
    const key = `notification-${Date.now}`;
    if (isError) {
      notification.error({
        message: getResponseError(error),
        duration: 0,
        key,
      });
    }

    return () => {
      notification.close(key);
    };
  }, [isError]);

  useEffect(() => {
    setPagination((currentPagination) => ({
      ...currentPagination,
      total: data?.total,
    }));
  }, [data]);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setPagination(pagination);
  };

  return (
    <Table<BillingRow>
      className={classes}
      rowKey={(record) => record.id}
      columns={columns}
      dataSource={data?.items}
      loading={isLoading}
      onChange={handleTableChange}
      pagination={pagination}
    />
  );
}

