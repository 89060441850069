import { RadioChangeEvent, notification } from 'antd';
import React, { useMemo, useState } from 'react';

import { Alert } from 'shared/alert';
import { Form, FormItem, useForm, useWatch } from 'shared/form';
import { Modal } from 'shared/modal';
import { Radio, RadioItem } from 'shared/radio';
import { Select } from 'shared/select';
import { Spin } from 'shared/spin';
import { Text } from 'shared/typography';

import { Hint } from 'components/hint';
import { TariffModal } from 'components/tariff-modal';

import { declOfNum } from 'helpers/decl-of-num';
import { getResponseError } from 'helpers/response';

import { WithModalProps } from 'hooks/modal';
import { useUser } from 'hooks/user';

import { Transcription, translateTranscriptionType } from 'models/transcription';
import {
  createTranscriptionSettings,
  TranscriptionSettings as ITranscriptionSettings,
} from 'models/transcription-settings';
import { UserPermissions } from 'models/user';

import { useTranscribeFileMutation } from 'store/services/file.service';
import { useGetTariffQuery } from 'store/services/tariff.service';

import styles from './transcription-settings.module.scss';

export interface TranscriptionSettingsProps extends WithModalProps {
  id?: Transcription['id'];
  price?: Transcription['price'];
  onChangeStatusAndType: (status: Transcription['transcribeStatus'], type: Transcription['type']) => void;
}

export function TranscriptionSettings({
  id,
  price,
  open,
  toggleModal,
  onChangeStatusAndType,
}: TranscriptionSettingsProps) {
  const { userPermissions } = useUser();

  const [form] = useForm<ITranscriptionSettings>();
  const type = useWatch('type', form);

  const [error, setError] = useState<null | string>(null);

  const [transcribeFile, { isLoading: isTranscribing }] = useTranscribeFileMutation();
  const onStartTranscribe = async () => {
    if (id) {
      try {
        const values = form.getFieldsValue();
        await transcribeFile({ id, settings: values }).unwrap();
        const { type } = values;
        onChangeStatusAndType('in_progress', type);
        toggleModal();
        notification.success({
          message: `${translateTranscriptionType(type)} транскрибация файла началась`,
        });
      } catch (error) {
        setError(getResponseError(error));
      }
    }
  };

  const { data: tariff } = useGetTariffQuery();

  const onChangeType = (event: RadioChangeEvent) => {
    if (event.target.value === 'AUTO') {
      form.setFieldValue('priority', tariff?.auto.priority);
    } else if (event.target.value === 'MANUAL') {
      form.setFieldValue('priority', tariff?.manual.priority_4.priority);
    }
  };

  const restrictions = useMemo(() => checkRestrictions(type, userPermissions), [type, userPermissions]);

  return (
    <Modal
      className={styles.transcriptionSettings}
      width={400}
      open={open}
      title='Параметры транскрибации'
      onCancel={toggleModal}
      okButtonProps={{ loading: isTranscribing, disabled: restrictions.isExhauested }}
      onOk={onStartTranscribe}
      okText='Продолжить'
      destroyOnClose={true}
    >
      <Form form={form} initialValues={createTranscriptionSettings()} autoComplete='off' error={error}>
        <FormItem name='type'>
          <Radio onChange={onChangeType}>
            <RadioItem value={'AUTO'}>
              {translateTranscriptionType('AUTO')}{' '}
              <Hint content='Транскрибации машинными алгоритмами. Обрабатывает час аудио около 5-10 минут. Качество транскрипта зависит от качества аудио.' />
            </RadioItem>
            <RadioItem value={'MANUAL'}>
              {translateTranscriptionType('MANUAL')}{' '}
              <Hint content='Создается заботливыми руками транскрибаторов. Получите транскрипцию в течении 1-3х суток, в зависимости от выбранного тарифа. Точно разделим говорящих в аудио и разберемся даже с плохим качеством записи.' />
            </RadioItem>
          </Radio>
        </FormItem>
        <FormItem noStyle={true} shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}>
          {({ getFieldValue }) =>
            getFieldValue('type') === 'AUTO' ? (
              <FormItem name='lang' label='Выберите язык транскрибации'>
                <Select
                  options={[
                    { value: 'ru-RU', label: 'Русский' },
                    { value: 'en-US', label: 'Английский' },
                  ]}
                />
              </FormItem>
            ) : null
          }
        </FormItem>
        <FormItem noStyle={true} shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}>
          {({ getFieldValue }) => (
            <FormItem
              name='priority'
              label={<>Стоимость транскрибации {tariff && <TariffModal tariff={tariff} hideText={true} />}</>}
            >
              {tariff && price ? (
                <Radio>
                  {getFieldValue('type') === 'AUTO' && (
                    <>
                      <RadioItem value={tariff.auto.priority}>
                        {price.auto.price} {declOfNum(price.auto.price, ['рубль', 'рубля', 'рублей'])}{' '}
                        <Text type='secondary'>({tariff.auto.name.toLowerCase()})</Text>
                      </RadioItem>
                    </>
                  )}
                  {getFieldValue('type') === 'MANUAL' && (
                    <>
                      <RadioItem value={tariff.manual.priority_4.priority}>
                        {price.manual.priority_4.price}{' '}
                        {declOfNum(price.manual.priority_4.price, ['рубль', 'рубля', 'рублей'])}{' '}
                        <Text type='secondary'>({tariff.manual.priority_4.name.toLowerCase()})</Text>
                      </RadioItem>
                      <RadioItem value={tariff.manual.priority_3.priority}>
                        {price.manual.priority_3.price}{' '}
                        {declOfNum(price.manual.priority_3.price, ['рубль', 'рубля', 'рублей'])}{' '}
                        <Text type='secondary'>({tariff.manual.priority_3.name.toLowerCase()})</Text>
                      </RadioItem>
                      <RadioItem value={tariff.manual.priority_2.priority}>
                        {price.manual.priority_2.price}{' '}
                        {declOfNum(price.manual.priority_2.price, ['рубль', 'рубля', 'рублей'])}{' '}
                        <Text type='secondary'>({tariff.manual.priority_2.name.toLowerCase()})</Text>
                      </RadioItem>
                      <RadioItem value={tariff.manual.priority_1.priority}>
                        {price.manual.priority_1.price}{' '}
                        {declOfNum(price.manual.priority_1.price, ['рубль', 'рубля', 'рублей'])}{' '}
                        <Text type='secondary'>({tariff.manual.priority_1.name.toLowerCase()})</Text>
                      </RadioItem>
                    </>
                  )}
                </Radio>
              ) : (
                <Spin />
              )}
            </FormItem>
          )}
        </FormItem>
        {!restrictions.isAllowed && !restrictions.isExhauested && (
          <FormItem>
            <Alert message={allowedAlerts[type]} type='warning' />
          </FormItem>
        )}
        {restrictions.isExhauested && (
          <FormItem>
            <Alert message={exhaustedAlerts[type]} type='error' />
          </FormItem>
        )}
      </Form>
    </Modal>
  );
}

function checkRestrictions(type?: ITranscriptionSettings['type'], permissions?: UserPermissions) {
  switch (type) {
    case 'AUTO':
      return {
        isAllowed: permissions?.autoTranscribeAllowed,
        isExhauested: permissions?.autoTranscribeExhausted,
      };
    case 'MANUAL':
      return {
        isAllowed: permissions?.manualTranscribeAllowed,
        isExhauested: permissions?.manualTranscribeExhausted,
      };
    default:
      return {
        isAllowed: true,
        isExhauested: true,
      };
  }
}

const allowedAlerts: { [key in ITranscriptionSettings['type']]: string } = {
  AUTO: 'Вы можете загрузить 1 файл для пробного автоматического транскрипта',
  MANUAL: 'Вы можете загрузить 1 файл длительностью до 10 минут для пробного ручного транскрипта',
};

const exhaustedAlerts: { [key in ITranscriptionSettings['type']]: string } = {
  AUTO: 'Вы уже исчерпали лимит пробного автоматического транскрипта',
  MANUAL: 'Вы уже исчерпали лимит пробного ручного транскрипта',
};

