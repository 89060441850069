import { AxiosError, AxiosProgressEvent, AxiosResponse } from 'axios';

import { paths } from 'api/paths';

import { FileRelatedId, FileInfo, FileRelatedType } from 'models/file';
import { Interview } from 'models/interview';
import { SuccessResponse } from 'models/response';
import { TranscriptionSettings } from 'models/transcription-settings';

import { instance } from 'store/instance';
import { api } from 'store/service';
import { fileActions } from 'store/slices/file.slice';

export const fileApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addFile: builder.mutation<
      FileInfo,
      { type: FileRelatedType; id: FileRelatedId; file: File; wasInvalidateTags?: boolean; isTranscription?: boolean }
    >({
      queryFn: async ({ file, id, type, isTranscription }, api) => {
        const url = paths.files(type, id, isTranscription);
        const formData = new FormData();
        formData.append('file', file);

        try {
          api.dispatch(fileActions.setUploadProgress(0));
          const response = await instance.post<
            SuccessResponse<FileInfo>,
            AxiosResponse<SuccessResponse<FileInfo>>,
            FormData
          >(url, formData, {
            onUploadProgress: (upload: AxiosProgressEvent) => {
              const uploadProgress = Math.round((100 * upload.loaded) / (upload.total || 100));

              api.dispatch(fileActions.setUploadProgress(uploadProgress));
            },
          });

          return { data: response.data.data };
        } catch (reason: unknown) {
          const error = reason as AxiosError;

          return { error: { status: error.response?.status || 500, data: error.response?.data || error.message } };
        }
      },
      invalidatesTags: (result, _, query) => (result && query.wasInvalidateTags ? ['Interviews', 'Interview'] : []),
    }),
    transcribeFile: builder.mutation<FileInfo, { id: Interview['id']; settings: TranscriptionSettings }>({
      query: ({ id, settings }) => ({
        url: paths.transcribeFile(id),
        method: 'POST',
        body: settings,
      }),
      invalidatesTags: (result) => (result ? ['UserPermissions', 'Interviews', 'Tariff', 'BillingFiles'] : []),
    }),
    addCloudLink: builder.mutation<
      FileInfo,
      { type: FileRelatedType; id: FileRelatedId; link: string; wasInvalidateTags?: boolean; isTranscription?: boolean }
    >({
      query: ({ type, id, link, isTranscription }) => {
        return {
          url: paths.cloudLinks(type, id, isTranscription),
          method: 'POST',
          body: { link },
        };
      },
      transformResponse: (response: SuccessResponse<FileInfo>) => response.data,
      invalidatesTags: (result, _, query) => (result && query.wasInvalidateTags ? ['Interviews', 'Interview'] : []),
    }),
  }),
  overrideExisting: false,
});

export const { useAddFileMutation, useTranscribeFileMutation, useAddCloudLinkMutation } = fileApi;

