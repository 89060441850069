import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { routes } from 'api/routes';

import { Paragraph, Title } from 'shared/typography';

import { Box } from 'components/box';

import { declOfNum } from 'helpers/decl-of-num';

import { User } from 'models/user';

import { MakePayment } from 'features/make-payment';
import { Tariff } from 'features/tariff';

import styles from './profile.balance.module.scss';

export interface ProfileBalanceProps {
  className?: string;
  balance?: User['balance'];
}

export function ProfileBalance({ className, balance }: ProfileBalanceProps) {
  const classes = cn(styles.profileBalance, className);

  return (
    <Box className={classes} size='small' type='colored'>
      <Title level={4}>Ваш баланс</Title>
      <Paragraph>
        {typeof balance === 'number' ? `${balance} ${declOfNum(balance, ['рубль', 'рубля', 'рублей'])}` : 'Неизвестно'}
      </Paragraph>
      <Paragraph>
        <Link to={routes.billing()}>Детализация оплат и списаний</Link>
      </Paragraph>
      <Title level={4}>Доступно минут</Title>
      <Tariff className='ant-typography' />
      <Title level={4}>Пополнить баланс</Title>
      <MakePayment />
    </Box>
  );
}

