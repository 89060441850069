import { createSlice } from '@reduxjs/toolkit';

import { storeHelper } from 'helpers/store';

import { User, UserPermissions } from 'models/user';

import { RootState } from 'store/store';

interface State {
  user?: User;
  userPermissions?: UserPermissions;
  isLoading: boolean;
}

const initialState: State = {
  user: undefined,
  userPermissions: undefined,
  isLoading: true,
};

const { setAction } = storeHelper<State>();

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: setAction('user'),
    setUserPermissions: setAction('userPermissions'),
    setIsLoading: setAction('isLoading'),
  },
});

export const userActions = userSlice.actions;

export const userSelector = (state: RootState): State => state.user;

