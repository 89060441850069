import React from 'react';
import { Link } from 'react-router-dom';

import { routes } from 'api/routes';

import { ColumnsType } from 'shared/table';
import { Text } from 'shared/typography';

import { formatTime } from 'helpers/format-time';

import { BillingRow } from 'models/billing';

export const columns: ColumnsType<BillingRow> = [
  {
    title: 'Дата и время',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
  {
    title: 'Название проекта',
    dataIndex: 'project',
    key: 'project',
    render: (_, record) => <Link to={routes.project(record.project?.id)}>{record.project?.name}</Link>,
  },
  {
    title: 'Название интервью',
    dataIndex: 'interview',
    key: 'interview',
    render: (_, record) => (
      <Link to={routes.interview(record.project?.id, record.interview?.id)}>{record.interview?.name}</Link>
    ),
  },
  {
    title: 'Длительность',
    dataIndex: 'duration',
    key: 'duration',
    render: (text) => formatTime(text),
  },
  {
    title: 'Сумма списания',
    dataIndex: 'amount',
    key: 'amount',
    render: (text) => <Text type='danger'>{text}</Text>,
  },
];

