import React from 'react';
import { RenderElementProps } from 'slate-react';

import { PlayerCurrentTime, PlayerProgress } from 'components/player';

import { Transcription, TranscriptionChannel } from 'models/transcription';

import { InterviewItem } from './components/interview-item';
import { InterviewWrap } from './components/interview-wrap';

export interface RenderEditorElementProps extends RenderElementProps {
  channels?: TranscriptionChannel[];
  onReplicaPlayClick?: (currentTime: PlayerCurrentTime) => void;
  onChangeChannels?: (channels: TranscriptionChannel[]) => void;
  onRemoveTranscription?: (transcription: Transcription) => void;
  onDownloadTranscription?: (transcription: Transcription) => void;
  onProgressChange?: (progress: PlayerProgress) => void;
  playerProgress?: PlayerProgress;
  currentTime?: PlayerCurrentTime;
  isDownloadingTranscription?: boolean;
  activeChannelIds?: Set<TranscriptionChannel['id']>;
}

export function EditorElement({
  channels,
  onChangeChannels,
  attributes,
  children,
  element,
  activeChannelIds,
  onRemoveTranscription,
  onDownloadTranscription,
  isDownloadingTranscription,
  onProgressChange,
  playerProgress,
  onReplicaPlayClick,
  currentTime,
}: RenderEditorElementProps) {
  switch (element.type) {
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'heading-three':
      return <h3 {...attributes}>{children}</h3>;
    case 'heading-four':
      return <h4 {...attributes}>{children}</h4>;
    case 'heading-five':
      return <h5 {...attributes}>{children}</h5>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'interview-item':
      return (
        <InterviewItem
          onReplicaPlayClick={onReplicaPlayClick}
          playerProgress={playerProgress}
          channels={channels}
          onChangeChannels={onChangeChannels}
          element={element}
          attributes={attributes}
          activeChannelIds={activeChannelIds}
        >
          {children}
        </InterviewItem>
      );
    case 'interview-wrap':
      return (
        <InterviewWrap
          currentTime={currentTime}
          onRemoveTranscription={onRemoveTranscription}
          onDownloadTranscription={onDownloadTranscription}
          isDownloadingTranscription={isDownloadingTranscription}
          onProgressChange={onProgressChange}
          attributes={attributes}
          element={element}
        >
          {children}
        </InterviewWrap>
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
}

