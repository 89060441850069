import { declOfNum } from 'helpers/decl-of-num';

export function pricePerHour(price: number): string {
  return `${price} ${declOfNum(price, ['рубль', 'рубля', 'рублей'])}/час`;
}

export function pricePerMinute(price: number): string {
  return `${price} ${declOfNum(price, ['рубль', 'рубля', 'рублей'])}/минута`;
}

