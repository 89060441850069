import cn from 'classnames';
import React from 'react';

import { PageHeader } from 'shared/page-header';
import { Title, Paragraph } from 'shared/typography';

import { PageLayout } from 'components/page-layout';

import { useDocumentTitle } from 'hooks/document-title';

import styles from './terms-of-service.page.module.scss';

export interface TermsOfServicePageProps {
  className?: string;
}

export function TermsOfServicePage({ className }: TermsOfServicePageProps) {
  const classes = cn(styles.termsOfServicePage, className);
  useDocumentTitle('Условия использования');

  return (
    <PageLayout className={classes}>
      <PageHeader title={'Условия использования'}>
        <Paragraph>
          Платформа «Qulap» предоставляет свои функциональные возможности на платной и бесплатной основе. С тарифами
          можно ознакомиться на странице https://qulap.com/pricing. Переходя к использованию платформы, вы соглашаетесь
          с изложенными ниже правилами.
        </Paragraph>
        <Paragraph>
          Лицензиар — ИП Кононов Егор, созданное по законодательству Российской Федерации и зарегистрированное по
          адресу: г. Новосибирск ул 2-ая Обская д. 154 кв. 5, настоящим предлагает любому заинтересованному лицу в
          соответствии со ст. 437 ГК РФ, присоединиться на условиях изложенных ниже в Правилах использования, к
          использованию платформой Qulap.
        </Paragraph>
        <Title level={4}>1. Общие правила</Title>
        <Paragraph>
          1.1. Настоящие Правила пользования платформы Qulap (далее — Правила) определяют условия использования
          программного обеспечения Qulap на веб-сайте в сети Интернет по адресу: https://qulap.com (далее — Платформа
          Qulap), а также права и обязанности Пользователей и Лицензиара. Настоящие Правила распространяются также на
          отношения, связанные с правами и интересами третьих лиц, не являющимися Пользователями Платформы Qulap, но чьи
          права и интересы могут быть затронуты в результате действий Пользователей Платформы Qulap.
        </Paragraph>
        <Paragraph>
          1.2. Настоящие Правила являются юридически обязательным соглашением между Пользователем и Лицензиаром,
          предметом которого является предоставление Лицензиаром Пользователю права использования на условиях простой
          (неисключительной) лицензии Платформы Qulap в соответствие с функциональными возможностями, в объеме,
          определенном Лицензиаром, а также оказания иных сопутствующих услуг и предоставления иных сервисов (далее —
          Услуги). Помимо настоящих Правил, к соглашению между Пользователем и Лицензиаром относятся все специальные
          документы, регулирующие предоставление Услуг и размещенные в соответствующих разделах Платформы Qulap.
        </Paragraph>
        <Paragraph>
          1.3. Пользователь обязан полностью ознакомиться с настоящими Правилами до момента регистрации на Платформе
          Qulap. Регистрация Пользователя на Платформе Qulap означает полное и безоговорочное принятие Пользователем
          настоящих Правил в соответствии со ст. 438 Гражданского кодекса Российской Федерации.
        </Paragraph>
        <Paragraph>
          1.4. Настоящие Правила могут быть изменены и/или дополнены Лицензиаром в одностороннем порядке без какого-либо
          специального уведомления. Настоящие Правила являются открытым и общедоступным документом.
        </Paragraph>
        <Paragraph>
          1.5. Пользователь обязан регулярно проверять условия настоящих Правил на предмет их изменения и/или
          дополнения. Продолжение использования Платформs Qulap Пользователем после внесения изменений и/или дополнений
          в настоящие Правила означает принятие и согласие Пользователя с такими изменениями и/или дополнениями.
        </Paragraph>
        <Paragraph>
          1.6. Никакие положения настоящих Правил не предоставляют Пользователю право на использование фирменного
          наименования, товарных знаков, доменных имен и результатов интеллектуальной деятельности, используемых на
          Платформе Qulap, за исключением случаев, когда такое использование допускается с письменного согласия
          Лицензиара.
        </Paragraph>
        <Paragraph>
          1.7. Пользователь вправе заключить с Лицензиаром отдельный лицензионный договор на использование Платформы
          Qulap без ограничений, установленных для использования Платформы Qulap в бесплатной версии.
        </Paragraph>
        <Title level={4}>2. Регистрация на Платформе Qulap</Title>
        <Paragraph>
          2.1. Пользователем Платформы Qulap является физическое лицо, зарегистрированное на Платформе Qulap, в
          соответствии с установленным настоящими Правилами порядком, достигшее возраста, допустимого в соответствии с
          законодательством Российской Федерации для акцепта настоящих Правил, и обладающее соответствующими
          полномочиями (ранее и далее — Пользователь).
        </Paragraph>
        <Paragraph>2.2. Регистрация Пользователя на Платформе Qulap является бесплатной и добровольной.</Paragraph>
        <Paragraph>
          2.3. При регистрации на Платформе Qulap Пользователь обязан предоставить Лицензиару необходимую достоверную и
          актуальную информацию.
        </Paragraph>
        <Paragraph>
          2.4. Пользователь несет ответственность за достоверность, актуальность, полноту и соответствие
          законодательству Российской Федерации предоставленной при регистрации информации и ее чистоту от претензий
          третьих лиц.
        </Paragraph>
        <Paragraph>
          2.5. При регистрации Пользователь соглашается с настоящими Правилами и принимает на себя указанные в них права
          и обязанности, связанные с использованием и функционированием Платформы Qulap.
        </Paragraph>
        <Paragraph>
          2.6. Принимая настоящие Правила, Пользователь подтверждает свое согласие на обработку Лицензиаром его
          персональных данных, предоставленных при регистрации, а также размещаемых Пользователем добровольно на
          Платформе Qulap.
        </Paragraph>
        <Paragraph>
          2.7. Пользователь не имеет права передавать свои логин и пароль третьим лицам, несет полную ответственность за
          их сохранность, самостоятельно выбирая способ их хранения. Пользователь на используемом им
          аппаратно-программном обеспечении может разрешить хранение логина и пароля (с использованием файлов cookies)
          для последующей автоматической авторизации на Платформе Qulap.
        </Paragraph>
        <Paragraph>
          2.8. Если Пользователем не доказано обратное, любые действия, совершенные с использованием его логина и
          пароля, считаются совершенными соответствующим Пользователем. В случае несанкционированного доступа к логину и
          паролю и/или персональной странице Пользователя, или распространения логина и пароля Пользователь обязан
          незамедлительно сообщить об этом Лицензиару.
        </Paragraph>
        <Paragraph>
          2.9. После регистрации Пользователь получает право на условиях простой (неисключительной) лицензии
          использовать ПО и пользоваться иными Услугами на Платформе Qulap, в определенном Лицензиаром объеме.
        </Paragraph>
        <Paragraph>
          2.10. Пользователь несет полную ответственность за свои действия, связанные с использованием Платформы Qulap,
          в соответствии с действующим законодательством Российской Федерации.
        </Paragraph>
        <Paragraph>
          2.11. В случае обнаружения факта нарушения Пользователем настоящих Правил, действующего законодательства
          Российской Федерации, Лицензиар вправе заблокировать доступ Пользователя к Платформе Qulap.
        </Paragraph>
        <Title level={4}>3. Обязанности Пользователя</Title>
        <Paragraph>3.1. При использовании Платформы Qulap и Услугами Пользователь обязан:</Paragraph>
        <Paragraph>
          – соблюдать положения действующего законодательства Российской Федерации, настоящих Правил и иных специальных
          документов, размещаемых Лицензиаром на Платформе Qulap;
        </Paragraph>
        <Paragraph>
          – предоставлять при регистрации достоверные, полные и актуальные данные, следить за их актуализацией;
        </Paragraph>
        <Paragraph>
          – информировать Лицензиара о несанкционированном доступе к персональной странице и/или о несанкционированном
          доступе и/или использовании пароля и логина Пользователя;
        </Paragraph>
        <Paragraph>
          – не размещать на Платформе Qulap информацию и объекты (включая ссылки на них), которые могут нарушать права и
          интересы других лиц;
        </Paragraph>
        <Paragraph>
          – хранить в тайне и не предоставлять другим Пользователям и третьим лицам ставшие ему известными в результате
          общения с другими Пользователями и иного использования Платформы Qulap персональные данные (включая, но не
          ограничиваясь, домашними адресами, номерами телефонов, адресами электронной почты, ICQ, паспортными данными,
          банковской информацией) и информацию о частной жизни других Пользователей и третьих лиц без получения
          соответствующего предварительного разрешения последних;
        </Paragraph>
        <Paragraph>– осуществлять резервное копирование важной для Пользователя информации.</Paragraph>
        <Paragraph>3.2. Пользователю при использовании Платформы Qulap запрещается:</Paragraph>
        <Paragraph>
          3.2.1. Регистрироваться в качестве Пользователя от имени или вместо другого лица («фальшивый аккаунт»). При
          этом возможна регистрация от имени и поручению другого физического лица или юридического лица при условии
          получения необходимых полномочий в порядке и форме, предусмотренных законодательством Российской Федерации;
        </Paragraph>
        <Paragraph>
          3.2.2. Загружать, хранить, публиковать, распространять и предоставлять доступ или иным образом использовать
          любую информацию, которая:
        </Paragraph>
        <Paragraph>
          – содержит угрозы, дискредитирует, оскорбляет, порочит честь и достоинство или деловую репутацию или нарушает
          неприкосновенность частной жизни других Пользователей или третьих лиц;
        </Paragraph>
        <Paragraph>– нарушает права несовершеннолетних лиц;</Paragraph>
        <Paragraph>
          – является вульгарной или непристойной, содержит нецензурную лексику, содержит порнографические изображения и
          тексты или сцены сексуального характера с участием несовершеннолетних;
        </Paragraph>
        <Paragraph>– содержит сцены насилия, либо бесчеловечного обращения с животными;</Paragraph>
        <Paragraph>– содержит описание средств и способов суицида, любое подстрекательство к его совершению;</Paragraph>
        <Paragraph>
          – пропагандирует и/или способствует разжиганию расовой, религиозной, этнической ненависти или вражды,
          пропагандирует фашизм или идеологию расового превосходства;
        </Paragraph>
        <Paragraph>– содержит экстремистские материалы;</Paragraph>
        <Paragraph>
          – пропагандирует преступную деятельность или содержит советы, инструкции или руководства по совершению
          преступных действий;
        </Paragraph>
        <Paragraph>
          – содержит информацию ограниченного доступа, включая, но не ограничиваясь, государственной и коммерческой
          тайной, информацией о частной жизни третьих лиц;
        </Paragraph>
        <Paragraph>
          – содержит рекламу или описывает привлекательность употребления наркотических веществ, в том числе «цифровых
          наркотиков» (звуковых файлов, оказывающих воздействие на мозг человека за счет бинауральных ритмов),
          информацию о распространении наркотиков, рецепты их изготовления и советы по употреблению;
        </Paragraph>
        <Paragraph>– носит мошеннический характер;</Paragraph>
        <Paragraph>
          – а также нарушает иные права и интересы граждан и юридических лиц или требования законодательства Российской
          Федерации.
        </Paragraph>
        <Paragraph>
          3.2.3. Незаконно загружать, хранить, публиковать, распространять и предоставлять доступ или иным образом
          использовать интеллектуальную собственность Пользователей и третьих лиц;
        </Paragraph>
        <Paragraph>
          3.2.4. Использовать программное обеспечение и осуществлять действия, направленные на нарушении нормального
          функционирования Платформы Qulap и его сервисов;
        </Paragraph>
        <Paragraph>
          3.2.5. Загружать, хранить, публиковать, распространять и предоставлять доступ или иным образом использовать
          вирусы, трояны и другие вредоносные программы;
        </Paragraph>
        <Paragraph>
          3.2.6. Использовать без специального на то разрешения Лицензиара автоматизированные скрипты (программы) для
          сбора информации на Платформе Qulap и(или) взаимодействия с Платформой Qulap и сервисами;
        </Paragraph>
        <Paragraph>
          3.2.7. Любым способом, в том числе, но не ограничиваясь, путем обмана, злоупотребления доверием, взлома,
          пытаться получить доступ к логину и паролю другого Пользователя;
        </Paragraph>
        <Paragraph>3.2.8. Осуществлять незаконно сбор и обработку персональных данных других лиц;</Paragraph>
        <Paragraph>
          3.2.9. Осуществлять (пытаться получить) доступ к каким-либо Услугам иным способом, кроме как через интерфейс,
          предоставленный Лицензиаром, за исключением случаев, когда такие действия были прямо разрешены Пользователю в
          соответствии с отдельным соглашением с Лицензиаром;
        </Paragraph>
        <Paragraph>
          3.2.10. Воспроизводить, дублировать, копировать, продавать, осуществлять торговые операции и перепродавать
          Услуги для каких-либо целей, за исключением случаев, когда такие действия были прямо разрешены Пользователю в
          соответствии с условиями отдельного соглашения с Лицензиаром;
        </Paragraph>
        <Paragraph>
          3.2.11. Размещать любую другую информацию, которая, по личному мнению Лицензиара, является нежелательной, не
          соответствует целям использования на Платформе Qulap, ущемляет интересы Пользователей или по другим причинам
          является нежелательной для размещения на Платформе Qulap.
        </Paragraph>
        <Paragraph>
          3.2.12. Пользователь несет личную ответственность за любую информацию, которую размещает на Платформе Qulap,
          сообщает другим Пользователям, а также за любые взаимодействия с другими Пользователями, осуществляемые на
          свой риск.
        </Paragraph>
        <Paragraph>
          3.2.13. В случае несогласия Пользователя с настоящими Правилами или их обновлениями, Пользователь обязан
          отказаться от использования Платформы Qulap, проинформировав об этом Лицензиара в установленном порядке.
        </Paragraph>
        <Title level={4}>4. Условия об интеллектуальных правах</Title>
        <Paragraph>
          4.1. Исключительные права на результаты интеллектуальной деятельности (далее РИД), используемые в Платформе
          Qulap.
        </Paragraph>
        <Paragraph>
          4.1.1. Все РИД, размещенные и используемые на Платформе Qulap, в том числе элементы дизайна, текст,
          графические изображения, иллюстрации, видео, скрипты, программы, музыка, звуки и другие объекты и их подборки
          (далее — Контент), являются объектами исключительных прав Лицензиара и других правообладателей, все права на
          эти объекты охраняются действующим законодательством Российской Федерации и международным законодательством.
        </Paragraph>
        <Paragraph>
          4.1.2. Кроме случаев, установленных настоящими Правилами, а также действующим законодательством Российской
          Федерации, никакой Контент не может быть скопирован (воспроизведен), переработан, распространен, отображен во
          фрейме, опубликован, скачан, передан, продан или иным способом использован целиком или по частям без
          предварительного разрешения правообладателя, кроме случаев, когда правообладатель явным в письменной форме
          выразил свое согласие на свободное использование Контента любым лицом.
        </Paragraph>
        <Paragraph>
          4.1.5. Любое использование Платформы Qulap и РИД размещенных в данной системе категорически запрещается за
          исключением способов использования прямо оговоренных в настоящих правилах.
        </Paragraph>
        <Title level={4}>5. Функционирование Платформы Qulap и ответственность при его использовании</Title>
        <Paragraph>
          5.1. Пользователи несут ответственность за собственные действия в связи с созданием и размещением информации
          на Платформе Qulap в соответствии с действующим законодательством Российской Федерации.
        </Paragraph>
        <Paragraph>
          5.2. Лицензиар сохраняет за собой право в любое время изменять оформление Платформы Qulap, его содержание,
          список сервисов, изменять или дополнять используемые скрипты, программное обеспечение и другие объекты,
          используемые или хранящиеся на Платформе Qulap, любые серверные приложения в любое время с предварительным
          уведомлением или без такового.
        </Paragraph>
        <Paragraph>
          5.3. Лицензиар не несет ответственности за нарушение Пользователем настоящих Правил и оставляет за собой право
          по своему собственному усмотрению, а также при получении информации от других пользователей либо третьих лиц о
          нарушении Пользователем настоящих Правил, изменять (модерировать) или удалять любую публикуемую Пользователем
          информацию, нарушающую запреты, установленные настоящими Правилами (включая личные сообщения),
          приостанавливать, ограничивать или прекращать доступ Пользователя ко всем или к любому из разделов или
          сервисов на Платформе Qulap в любое время по любой причине или без объяснения причин, с предварительным
          уведомлением или без такового, не отвечая за любой вред, который может быть причинен Пользователю таким
          действием. Лицензиар вправе без объяснения причин приостановить, ограничить или прекратить доступ Пользователя
          к Платформе Qulap или к любой из Услуг. Лицензиар не несет ответственности за осуществленное в соответствии с
          настоящими Правилами временное блокирование или удаление информации, или удаление персональной страницы
          (прекращение регистрации) Пользователя.
        </Paragraph>
        <Paragraph>
          5.4. Прекращение доступа Пользователя к Платформе Qulap означает, что пользователь более не вправе
          использовать Платформу Qulap.
        </Paragraph>
        <Paragraph>
          5.5. Лицензиар обеспечивает функционирование и работоспособность Платформы Qulap и обязуется оперативно
          восстанавливать его работоспособность в случае технических сбоев и перерывов. Лицензиар не несет
          ответственности за временные сбои и перерывы в работе Платформы Qulap и вызванные ими потерю информации.
          Лицензиар не несет ответственности за любой ущерб компьютеру Пользователя или иного лица, мобильным
          устройствам, любому другому оборудованию или программному обеспечению, вызванный или связанный со скачиванием
          материалов из Платформы Qulap или по ссылкам, размещенным на Платформе Qulap.
        </Paragraph>
        <Paragraph>
          5.6. Лицензиар имеет право направлять Пользователю информацию о развитие Платформы Qulap и его сервисов, а
          также рекламировать собственную деятельность и услуги.
        </Paragraph>
        <Paragraph>
          5.7. Лицензиар обеспечивает конфиденциальность и безопасность персональных данных при их обработке
          Пользователем с использованием Платформы Qulap:
        </Paragraph>
        <Paragraph>– предоставления доступа к Платформе Qulap по протоколу HTTPS;</Paragraph>
        <Paragraph>
          – антивирусной защиты Сервера на котором размещена Платформы Qulap с помощью стандартного антивирусного
          программного обеспечения;
        </Paragraph>
        <Paragraph>
          – использования межсетевого экрана, пропускающего только сетевой трафик, необходимый для использования и
          обслуживания Платформы Qulap;
        </Paragraph>
        <Paragraph>– контроль вводимых пользователями паролей — не менее шести буквенно-цифровых символов;</Paragraph>
        <Paragraph>– регистрация входа (выхода) пользователей в Систему (из Системы).</Paragraph>
        <Title level={4}>6. Ограничение ответственности Лицензиара</Title>
        <Paragraph>
          6.1. Платформа Qulap и ее сервисы, включая все скрипты, приложения, контент и оформление поставляются «как
          есть». Лицензиар отказывается от всяких гарантий того, что Платформа Qulap или сервисы могут подходить или не
          подходить для конкретных целей Пользователя. Лицензиар не может гарантировать и не обещает никаких
          специфических результатов от использования Платформы Qulap и/или сервисов;
        </Paragraph>
        <Paragraph>
          6.2. Ни при каких обстоятельствах Лицензиар не несет ответственность перед Пользователем или перед любыми
          третьими лицами за любой косвенный, случайный, неумышленный ущерб, включая упущенную выгоду или потерянные
          данные, вред чести, достоинству или деловой репутации, вызванный в связи с использованием Платформы Qulap,
          содержимого Платформы Qulap или иных материалов, к которым Пользователь или иные лица получили доступ с
          помощью Платформы Qulap, даже если Лицензиар предупреждал или указывал на возможность такого вреда.
        </Paragraph>
        <Paragraph>
          6.3. Настоящие Правила составляют соглашение между Пользователем и Лицензиаром относительно порядка
          использования Платформы Qulap и его сервисов и заменяют собой все предыдущие соглашения между Пользователем и
          Лицензиаром;
        </Paragraph>
        <Paragraph>
          6.4. Настоящие Правила регулируются и толкуются в соответствии с законодательством Российской Федерации.
          Вопросы, не урегулированные Правилами, подлежат разрешению в соответствии с законодательством Российской
          Федерации.
        </Paragraph>
        <Paragraph>
          6.5. В случае возникновения любых споров или разногласий, связанных с исполнением настоящих Правил,
          Пользователь и Лицензиар приложат все усилия для их разрешения путем проведения переговоров между ними. В
          случае, если споры не будут разрешены путем переговоров, споры подлежат разрешению в порядке, установленном
          действующим законодательством Российской Федерации по месту нахождения Лицензиара.
        </Paragraph>
        <Paragraph>
          6.6. Настоящие Правила вступают в силу для Пользователя с момента его присоединения к ним и действуют в
          течение неопределенного срока.
        </Paragraph>
        <Paragraph>
          6.7. Если по тем или иным причинам одно или несколько положений настоящих Правил будут признаны
          недействительными или не имеющими юридической силы, это не оказывает влияния на действительность или
          применимость остальных положений.
        </Paragraph>
        <Paragraph>
          6.8. Обращения, предложения и претензии физических и юридических лиц к Лицензиару в связи с настоящими
          Правилами и всеми вопросами по функционированию Платформы Qulap, нарушениями прав и интересов третьих лиц при
          его использовании, а также для запросов уполномоченных законодательством Российской Федерации лиц могут быть
          направлены посредством электронной связи на адрес: <a href='mailto:hello@qulap.com'>hello@qulap.com</a> или на
          почтовый адрес Лицензиара, указанный в настоящих Правилах.
        </Paragraph>
      </PageHeader>
    </PageLayout>
  );
}

