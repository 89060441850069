export function formatTime(seconds: number) {
  const floored = Math.floor(seconds);
  const { from, length } =
    floored >= 3600
      ? {
          from: 11,
          length: 8,
        }
      : {
          from: 14,
          length: 5,
        };

  return new Date(floored * 1000).toISOString().substring(from, from + length);
}

