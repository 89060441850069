import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { routes } from 'api/routes';

import { useUser } from 'hooks/user';

export interface PrivateRouteProps {
  element: JSX.Element;
}

export function PrivateRoute({ element }: PrivateRouteProps) {
  const location = useLocation();
  const { user } = useUser();

  if (!user) {
    return <Navigate to={routes.login()} state={{ from: location }} replace={true} />;
  }

  return element;
}

