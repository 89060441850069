import moment from 'moment';

import { dateFormats } from 'helpers/date';

import { LocalDate } from 'models/date';

export const sorter = {
  strings: (a: string, b: string): number => a.localeCompare(b),
  numbers: (a: number, b: number): number => a - b,
  dates: (a: LocalDate, b: LocalDate): number =>
    moment(a, dateFormats.date).valueOf() - moment(b, dateFormats.date).valueOf(),
  dateTimes: (a: LocalDate, b: LocalDate): number =>
    moment(a, dateFormats.dateTime).valueOf() - moment(b, dateFormats.dateTime).valueOf(),
};

