import { paths } from 'api/paths';

import { SuccessResponse } from 'models/response';
import { Tariff } from 'models/tariff';

import { api } from 'store/service';

export const tariffApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTariff: builder.query<Tariff, void>({
      query: () => ({
        url: paths.tariff(),
      }),
      transformResponse: (response: SuccessResponse<Tariff>) => response.data,
      providesTags: ['Tariff'],
    }),
  }),
  overrideExisting: false,
});

export const { useGetTariffQuery } = tariffApi;

