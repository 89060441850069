import React, { ReactNode, useEffect } from 'react';

import { Spin } from 'shared/spin';

import { useAppDispatch, useAppSelector } from 'hooks/store';

import { useGetUserQuery, useGetUserPermissionsQuery } from 'store/services/user.service';
import { userActions, userSelector } from 'store/slices/user.slice';

import styles from './auth-middleware.module.scss';

const { setUser, setUserPermissions, setIsLoading } = userActions;

export interface AuthMiddlewareProps {
  children: ReactNode;
}

export function AuthMiddleware({ children }: AuthMiddlewareProps) {
  useAuth();
  const { isLoading } = useAppSelector(userSelector);

  if (isLoading) {
    return <Spin className={styles.spin} size='large' />;
  }

  return children as React.ReactElement;
}

const useAuth = () => {
  const dispatch = useAppDispatch();
  const { data: user, isLoading, isError } = useGetUserQuery();
  const { data: userPermissions } = useGetUserPermissionsQuery();

  useEffect(() => {
    if (isError) {
      dispatch(setUser(undefined));
      return;
    }
    if (user) {
      dispatch(setUser(user));
    }
    if (userPermissions) {
      dispatch(setUserPermissions(userPermissions));
    }
  }, [user, userPermissions, isError]);

  useEffect(() => {
    dispatch(setIsLoading(isLoading));
  }, [isLoading]);
};

