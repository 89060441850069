import { CheckCircleTwoTone, CloseCircleTwoTone, LoadingOutlined } from '@ant-design/icons';
import React from 'react';

import { DANGER_COLOR, SUCCESS_COLOR } from 'helpers/constants';

import { PaymentStatus } from 'models/payment';

import styles from './payment-status.page.module.scss';

export interface PaymentStatusIconProps {
  status?: PaymentStatus['status'];
}

export function PaymentStatusIcon({ status }: PaymentStatusIconProps) {
  const classes = styles.icon;

  if (status === 'success') {
    return <CheckCircleTwoTone twoToneColor={SUCCESS_COLOR} className={classes} />;
  }

  if (status === 'canceled') {
    return <CloseCircleTwoTone twoToneColor={DANGER_COLOR} className={classes} />;
  }

  if (status === 'pending') {
    return <LoadingOutlined className={classes} />;
  }

  return null;
}

