import { paths } from 'api/paths';

import { api } from 'store/service';

export interface SignUpRequest {
  email: string;
  password: string;
}

export const signUpApi = api.injectEndpoints({
  endpoints: (builder) => ({
    signUp: builder.mutation<void, SignUpRequest>({
      query: (credentials) => ({
        url: paths.signUp(),
        method: 'POST',
        body: credentials,
      }),
      invalidatesTags: (result) => (result ? ['User', 'UserPermissions', 'Projects'] : []),
    }),
  }),
  overrideExisting: false,
});

export const { useSignUpMutation } = signUpApi;

