import { paths } from 'api/paths';

import { api } from 'store/service';

export interface ResetPasswordRequest {
  password: string;
  email: string;
  token: string;
}

export const resetPasswordApi = api.injectEndpoints({
  endpoints: (builder) => ({
    resetPassword: builder.mutation<void, ResetPasswordRequest>({
      query: (credentials) => ({
        url: paths.resetPassword(),
        method: 'POST',
        body: credentials,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useResetPasswordMutation } = resetPasswordApi;

