import { CloseOutlined } from '@ant-design/icons';
import { PopoverContentProps } from '@reactour/tour';
import React from 'react';

import { Button } from 'shared/button';

import styles from './onboarding.popover.module.scss';

export interface OnboardingPopoverProps extends PopoverContentProps {
  onFinish: () => Promise<void>;
}

export function OnboardingPopover(props: OnboardingPopoverProps) {
  const { steps, currentStep, setCurrentStep, setIsOpen, onFinish } = props;
  const isLastStep = currentStep === steps.length - 1;
  const { content, disableActions } = steps[currentStep];

  const handleClose = () => {
    setIsOpen(false);
    onFinish();
  };

  return (
    <div className={styles.onboardingPopover}>
      <Button className={styles.close} type='link' size='small' icon={<CloseOutlined />} onClick={handleClose} />
      <div className={styles.content}>
        <>{typeof content === 'function' ? content({ ...props }) : content}</>
      </div>
      <div className={styles.actions}>
        {!disableActions && (
          <Button
            onClick={() => {
              if (isLastStep) {
                handleClose();
              } else {
                setCurrentStep((step) => step + 1);
              }
            }}
          >
            {isLastStep ? 'Завершить обучение' : 'Далее'}
          </Button>
        )}
      </div>
    </div>
  );
}

