import { notification } from 'antd';
import cn from 'classnames';
import React from 'react';

import { Paragraph, Text } from 'shared/typography';

import { EditableImage } from 'components/editable-image';

import { getResponseError } from 'helpers/response';

import { User } from 'models/user';

import styles from './profile.avatar.module.scss';
import { useAddAvatarMutation, useRemoveAvatarMutation } from './profile.service';

export interface ProfileAvatarProps {
  className?: string;
  isLoading: boolean;
  avatar?: User['image'];
}

export function ProfileAvatar({ className, isLoading, avatar }: ProfileAvatarProps) {
  const classes = cn(styles.profileAvatar, className);
  const [addAvatar, { isLoading: isAdding }] = useAddAvatarMutation();
  const [removeAvatar, { isLoading: isRemoving }] = useRemoveAvatarMutation();

  const onAddAvatar = async (avatar: User['image']) => {
    try {
      await addAvatar(avatar).unwrap();
      notification.success({
        message: 'Аватар успешно обновлен',
      });
    } catch (error) {
      notification.error({
        message: getResponseError(error),
      });
    }
  };

  const onRemoveAvatar = async () => {
    try {
      await removeAvatar().unwrap();
      notification.success({
        message: 'Аватар успешно удален',
      });
    } catch (error) {
      notification.error({
        message: getResponseError(error),
      });
    }
  };

  return (
    <div className={classes}>
      <div className={styles.profileAvatar__image}>
        <EditableImage
          isLoading={isLoading || isAdding || isRemoving}
          image={avatar}
          type='user'
          onAdd={(image: User['image']) => {
            onAddAvatar(image);
          }}
          onRemove={() => {
            onRemoveAvatar();
          }}
          size='large'
        />
      </div>
      <div className={styles.profileAvatar__info}>
        <Paragraph className={styles.profileAvatar__paragraph}>Рекомендации к фотографии:</Paragraph>
        <Paragraph className={styles.profileAvatar__paragraph} size='small'>
          <Text type='secondary'>Не более 20 Мб</Text>
        </Paragraph>
        <Paragraph className={styles.profileAvatar__paragraph} size='small'>
          <Text type='secondary'>Допустимые форматы: jpg, png</Text>
        </Paragraph>
      </div>
    </div>
  );
}

