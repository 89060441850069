import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';

import { Button } from 'shared/button';
import { Paragraph } from 'shared/typography';

import { EditableImage } from 'components/editable-image';

import { TranscriptionChannel } from 'models/transcription';

import styles from './editable-name.module.scss';

export interface EditableNameProps {
  className?: string;
  channel: TranscriptionChannel;
  isCurrent: boolean;
  onChangeChannel: (channel: TranscriptionChannel) => void;
  onSelectChannel: (channel: TranscriptionChannel) => void;
  onRemoveChannel: (channel: TranscriptionChannel) => void;
}

export function EditableName({
  className,
  channel,
  isCurrent,
  onChangeChannel,
  onSelectChannel,
  onRemoveChannel,
}: EditableNameProps) {
  const classes = cn(styles.editableChannel, className, {
    [styles.editableChannel_current]: isCurrent,
  });
  const [editing, setEditing] = useState(false);
  const [detectionDisabled, setDetectionDisabled] = useState(!editing);
  useEffect(() => {
    setDetectionDisabled(!editing);
  }, [editing]);
  const ref = useDetectClickOutside({ onTriggered: () => setEditing(false), disableClick: detectionDisabled });

  return (
    <div className={classes}>
      <EditableImage
        className={styles.editableChannel__image}
        isLoading={false}
        image={channel.image}
        type='respondent'
        onAdd={(image) => {
          onChangeChannel({
            ...channel,
            image,
          });
        }}
        onRemove={() => {
          onChangeChannel({
            ...channel,
            image: null,
          });
        }}
        size='small'
      />
      <div
        className={styles.editableChannel__nameWrapper}
        ref={ref}
        onClick={() => {
          if (!editing) {
            onSelectChannel(channel);
          }
        }}
      >
        <Paragraph
          className={styles.editableChannel__name}
          editable={{
            triggerType: [],
            editing,
            onChange: (value) => {
              onChangeChannel({
                ...channel,
                name: value,
              });
            },
            onCancel: () => setEditing(false),
            onEnd: () => setEditing(false),
          }}
        >
          {channel.name}
        </Paragraph>
      </div>
      <div className={styles.editableChannel__actions}>
        {editing ? (
          <Button type='link' size='small' icon={<SaveOutlined />} onClick={() => setEditing(false)} />
        ) : (
          <Button type='link' size='small' icon={<EditOutlined />} onClick={() => setEditing(true)} />
        )}
        <Button type='link' size='small' icon={<DeleteOutlined />} onClick={() => onRemoveChannel(channel)} />
      </div>
    </div>
  );
}
