import { PageHeader as AntdPageHeader } from 'antd';
import { PageHeaderProps as AntdPageHeaderProps } from 'antd/lib/page-header';
import cn from 'classnames';
import React from 'react';

import { TitleProps } from 'shared/typography';
import { Title } from 'shared/typography';

import styles from './page-header.module.scss';

export interface PageHeaderProps extends AntdPageHeaderProps {
  className?: string;
  titleLevel?: TitleProps['level'];
}

export function PageHeader({ className, title, titleLevel, ...props }: PageHeaderProps) {
  const classes = cn(styles.pageHeader, className);

  return (
    <AntdPageHeader
      className={classes}
      title={
        <Title level={titleLevel} className={styles.title}>
          {title}
        </Title>
      }
      {...props}
    />
  );
}

