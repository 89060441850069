import { getRandomColor, Color } from 'models/color';

export interface NewQuestion {
  name: string;
  color: Color;
  questions?: Question[];
}

export interface Question extends NewQuestion {
  id: number;
}

export function createNewQuestion(name?: NewQuestion['name'], color?: NewQuestion['color']): NewQuestion {
  return {
    name: name || '',
    color: color || getRandomColor(),
    questions: [],
  };
}

