import { Descendant } from 'slate';

import { CustomElement, InterviewItemElement, InterviewWrapElement } from 'components/editor/editor.types';

import { Interview } from 'models/interview';
import { Transcription, TranscriptionText } from 'models/transcription';

export function transcriptionToNodes(interview: Interview): Descendant[] {
  const { transcriptions } = interview;

  if (!transcriptions?.[0]?.texts.length) {
    return [
      {
        type: 'interview-wrap',
        children: [
          {
            type: 'interview-item',
            children: [
              {
                text: '',
              },
            ],
          },
        ],
        meta: {
          transcription: transcriptions[0],
        },
      },
    ] as Descendant[];
  }

  const content: Descendant[] =
    transcriptions?.[0].texts.map((text) => {
      const textData = JSON.parse(text.text);

      return {
        type: 'interview-item',
        children: textData.map((textItem: Record<string, string>) => {
          const tags = Object.entries(textItem)
            .filter(([key]) => key.slice(0, 1) === '_')
            .reduce((acc: Record<string, string>, [key, val]) => {
              acc[key] = val;
              return acc;
            }, {});

          return {
            text: textItem.text,
            ...tags,
          };
        }),
        meta: {
          transcriptionText: text,
        },
      };
    }) || [];

  const initialValue: Descendant[] = [
    {
      type: 'interview-wrap',
      children: content,
      meta: {
        transcription: transcriptions[0],
      },
    },
  ];

  return initialValue;
}

export function nodesToTranscriptionTexts(nodes: Descendant[]): Transcription['texts'] {
  const wrap = nodes[0] as CustomElement;
  const prevTexts = (nodes[0] as InterviewWrapElement).meta?.transcription?.texts || [];

  const filteredItems = (wrap.children as InterviewItemElement[]).filter((item) => {
    return item.meta?.transcriptionText?.id !== undefined || item.meta?.transcriptionText?.channel !== undefined;
  });

  if (prevTexts.length === filteredItems.length) {
    return sameNodesToTranscriptionTexts(filteredItems);
  }

  return prevTexts.length > filteredItems.length
    ? decreasedNodesToTranscriptionTexts(filteredItems, prevTexts)
    : increasedNodesToTranscriptionTexts(filteredItems, prevTexts);
}

function sameNodesToTranscriptionTexts(filteredItems: InterviewItemElement[]): Transcription['texts'] {
  return filteredItems.map((item, index) => {
    return {
      id: (item.meta?.transcriptionText?.id || 0) + index,
      channel: item.meta?.transcriptionText?.channel || 0,
      text: JSON.stringify(item.children),
      start: filteredItems[index].meta?.transcriptionText?.start,
      end: filteredItems[index].meta?.transcriptionText?.end,
    };
  });
}

function decreasedNodesToTranscriptionTexts(
  filteredItems: InterviewItemElement[],
  prevTexts: TranscriptionText[],
): Transcription['texts'] {
  const breakIndex = getBreakIndex(filteredItems, prevTexts);

  const breakEnd = prevTexts[breakIndex + 1].end;

  return filteredItems.map((item, index) => {
    const start = filteredItems[index].meta?.transcriptionText?.start;
    let end = filteredItems[index].meta?.transcriptionText?.end;

    if (index === breakIndex) {
      end = breakEnd;
    }

    return {
      id: (item.meta?.transcriptionText?.id || 0) + index,
      channel: item.meta?.transcriptionText?.channel || 0,
      text: JSON.stringify(item.children),
      start: start,
      end: end,
    };
  });
}

function getBreakIndex(filteredItems: InterviewItemElement[], prevTexts: TranscriptionText[]) {
  let breakIndex = prevTexts.length - 1;

  for (let i = 0; i < prevTexts.length; i++) {
    const prevText = prevTexts[i].text;
    const newText = JSON.stringify(filteredItems[i].children);

    if (newText.length !== prevText.length) {
      breakIndex = i;
      break;
    }
  }

  return breakIndex;
}

function increasedNodesToTranscriptionTexts(
  filteredItems: InterviewItemElement[],
  prevTexts: TranscriptionText[],
): Transcription['texts'] {
  const EMPTY_JSON_CHILDREN_LENGTH = 13;
  let breakIndex = getBreakIndex(filteredItems, prevTexts);

  const isEmptyBreak = JSON.stringify(filteredItems[breakIndex].children).length === EMPTY_JSON_CHILDREN_LENGTH;
  if (isEmptyBreak) {
    breakIndex -= 1;
  }

  const prevBreakStart = prevTexts[breakIndex].start || 0;
  const prevBreakEnd = prevTexts[breakIndex].end || 0;
  const newBreakTextLength = JSON.stringify(filteredItems[breakIndex].children).length;
  const breakTime = prevBreakEnd - prevBreakStart;
  let middleBreakTime = prevBreakStart + (newBreakTextLength / prevTexts[breakIndex].text.length) * breakTime;

  if (isEmptyBreak) {
    middleBreakTime = prevBreakEnd;
  }

  return filteredItems.map((item, index) => {
    let start = filteredItems[index].meta?.transcriptionText?.start;
    let end = filteredItems[index].meta?.transcriptionText?.end;

    if (index === breakIndex) {
      end = middleBreakTime;
    }
    if (index === breakIndex + 1) {
      start = middleBreakTime;
      end = prevTexts[breakIndex].end;
    }

    return {
      id: (item.meta?.transcriptionText?.id || 0) + index,
      channel: item.meta?.transcriptionText?.channel || 0,
      text: JSON.stringify(item.children),
      start: start,
      end: end,
    };
  });
}

