import cn from 'classnames';
import React, { ReactNode } from 'react';

import { Box } from 'components/box';
import { Container } from 'components/container';
import { ErrorBoundary } from 'components/error-boundary';
import { Header } from 'components/header';

import styles from './page-layout.module.scss';

export interface PageLayoutProps {
  className?: string;
  children: ReactNode;
}

export function PageLayout({ className, children }: PageLayoutProps) {
  const classes = cn(styles.pageLayout, className);

  return (
    <div className={classes}>
      <Header className={styles.header} />
      <Container className={styles.container}>
        <Box className={styles.box}>
          <ErrorBoundary>{children}</ErrorBoundary>
        </Box>
      </Container>
    </div>
  );
}

