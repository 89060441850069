import { Space as AntdSpace } from 'antd';
import { SpaceProps as AntdSpaceProps } from 'antd/lib/space';
import cn from 'classnames';
import React from 'react';

import styles from './space.module.scss';

export interface SpaceProps extends AntdSpaceProps {
  className?: string;
}

export function Space({ className, size = 'large', ...props }: SpaceProps) {
  const classes = cn(styles.space, className);

  return <AntdSpace className={classes} size={size} {...props} />;
}

