export const colors = [
  'GREEN',
  'YELLOW',
  'RED',
  'BLUE',
  'ORANGE',
  'PURPLE',
  'MAVI',
  'PINK',
  'DARK_RED',
  'DARK_BLUE',
] as const;

export type Color = typeof colors[number];

export function getRandomColor() {
  return colors[Math.floor(Math.random() * colors.length)];
}

export function transformColor(color: Color, alpha = 1): string {
  let output = '';

  switch (color) {
    case 'GREEN':
      output = '#3AC922';
      break;
    case 'YELLOW':
      output = '#F6D523';
      break;
    case 'RED':
      output = '#FF3030';
      break;
    case 'BLUE':
      output = '#5452FB';
      break;
    case 'ORANGE':
      output = '#FF7F37';
      break;
    case 'PURPLE':
      output = '#C12AE7';
      break;
    case 'MAVI':
      output = '#24DACF';
      break;
    case 'PINK':
      output = '#FFA3F0';
      break;
    case 'DARK_RED':
      output = '#9E0002';
      break;
    case 'DARK_BLUE':
      output = '#1422AA';
      break;
    default:
      throw new Error(`Неизвестный цвет ${color}`);
  }

  return `${output}${Math.floor(alpha * 255).toString(16)}`;
}

