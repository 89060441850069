import { paths } from 'api/paths';

import { SuccessResponse } from 'models/response';
import { User, UserPermissions } from 'models/user';

import { api } from 'store/service';

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<User, void>({
      query: () => ({
        url: paths.profile(),
      }),
      transformResponse: (response: SuccessResponse<User>) => response.data,
      providesTags: ['User'],
    }),
    getUserPermissions: builder.query<UserPermissions, void>({
      query: () => ({
        url: paths.profilePermissions(),
      }),
      transformResponse: (response: SuccessResponse<UserPermissions>) => response.data,
      providesTags: ['UserPermissions'],
    }),
    finishOnboarding: builder.mutation<UserPermissions, void>({
      query: () => ({
        url: paths.profilePermissions(),
        method: 'PATCH',
        body: { onboarding: true },
      }),
      invalidatesTags: (result) => (result ? ['UserPermissions'] : []),
    }),
    restartOnboarding: builder.mutation<UserPermissions, void>({
      query: () => ({
        url: paths.profilePermissions(),
        method: 'PATCH',
        body: { onboarding: false },
      }),
      invalidatesTags: (result) => (result ? ['UserPermissions'] : []),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserQuery,
  useGetUserPermissionsQuery,
  useFinishOnboardingMutation,
  useRestartOnboardingMutation,
} = userApi;

