import { useTour } from '@reactour/tour';
import { notification } from 'antd';
import { useMemo } from 'react';

import { getResponseError } from 'helpers/response';

import { useRestartOnboardingMutation } from 'store/services/user.service';

export const useOnboarding = () => {
  const { setIsOpen } = useTour();
  const [restartOnboarding] = useRestartOnboardingMutation();

  return useMemo(
    () => ({
      startOnboarding: async () => {
        setIsOpen(true);
        try {
          await restartOnboarding().unwrap();
        } catch (error) {
          notification.error({
            message: getResponseError(error),
          });
        }
      },
    }),
    [],
  );
};

