import React from 'react';

import { transformColor } from 'models/color';
import { Question } from 'models/question';

import styles from './editor.module.scss';
import { QuestionsList } from './editor.types';

export interface IQuestionsList {
  questionsList: QuestionsList;
  setQuestion: (id: string, color: string) => () => void;
}

export default function Questions({ questionsList, setQuestion }: IQuestionsList) {
  const renderQuestionTree = (questions?: Question[]) => {
    return (
      <div className={styles.tagList}>
        {!questions?.length && <span>Вы не заполнили Гайд на странице описания исследования</span>}
        {questions?.map((question) => (
          <div className={styles.tagListItem} key={question.id}>
            <div onClick={setQuestion(`${question.id}`, question.color)} className={styles.tagListItemLabel}>
              <div
                className={styles.tagListItemLabel__mark}
                style={{ ['--color' as string]: transformColor(question.color) }}
              ></div>
              {question.name}
            </div>
            {question?.questions?.length ? (
              <div className={styles.tagListItem__children}>{renderQuestionTree(question.questions)}</div>
            ) : null}
          </div>
        ))}
      </div>
    );
  };

  return <div className={styles.tagList_container}>{renderQuestionTree(Object.values(questionsList))}</div>;
}

