import cn from 'classnames';
import React, { ReactNode } from 'react';

import styles from './box.module.scss';

export interface BoxProps {
  size?: 'middle' | 'small';
  className?: string;
  onboardingSelector?: string;
  type?: 'colored';
  children: ReactNode;
}

export function Box({ size, className, onboardingSelector, type, children }: BoxProps) {
  const classes = cn(styles.box, className, {
    [styles.box_middle]: size === 'middle',
    [styles.box_small]: size === 'small',
    [styles.box_colored]: type === 'colored',
  });

  return (
    <div data-onboarding={onboardingSelector} className={classes}>
      {children}
    </div>
  );
}

