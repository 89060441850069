import { paths } from 'api/paths';

import { Image, ImageRelatedType } from 'models/image';
import { SuccessResponse } from 'models/response';

import { api } from 'store/service';

export const selectImageApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getImages: builder.query<Image[], ImageRelatedType>({
      query: (credentials) => ({
        url: paths.images(credentials),
      }),
      transformResponse: (response: SuccessResponse<Image[]>) => response.data,
      providesTags: ['Images'],
    }),
    removeImage: builder.mutation<Image[], Image['id']>({
      query: (id) => ({
        url: paths.image(id),
        method: 'DELETE',
        body: { id },
      }),
      invalidatesTags: (result) => (result ? ['Images'] : []),
    }),
    addImage: builder.mutation<Image, { type: ImageRelatedType; file: File }>({
      query: ({ type, file }) => {
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: paths.images(type),
          method: 'POST',
          body: formData,
        };
      },
      transformResponse: (response: SuccessResponse<Image>) => response.data,
      invalidatesTags: (result) => (result ? ['Images'] : []),
    }),
  }),
  overrideExisting: false,
});

export const { useGetImagesQuery, useAddImageMutation, useRemoveImageMutation } = selectImageApi;

