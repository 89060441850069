import { paths } from 'api/paths';

import { User, EditableUserFields } from 'models/user';

import { api } from 'store/service';

export const profileApi = api.injectEndpoints({
  endpoints: (builder) => ({
    editProfile: builder.mutation<User, EditableUserFields>({
      query: (credentials) => ({
        url: paths.profile(),
        method: 'POST',
        body: credentials,
      }),
      invalidatesTags: (result) => (result ? ['User'] : []),
    }),
    addAvatar: builder.mutation<User, User['image']>({
      query: (image) => ({
        url: paths.profile(),
        method: 'PATCH',
        body: { image },
      }),
      invalidatesTags: (result) => (result ? ['User'] : []),
    }),
    removeAvatar: builder.mutation<User, void>({
      query: () => ({
        url: paths.profile(),
        method: 'PATCH',
        body: { image: null },
      }),
      invalidatesTags: (result) => (result ? ['User'] : []),
    }),
  }),
  overrideExisting: false,
});

export const { useEditProfileMutation, useAddAvatarMutation, useRemoveAvatarMutation } = profileApi;

