import { paths } from 'api/paths';

import { Project } from 'models/project';
import { SuccessResponse } from 'models/response';

import { api } from 'store/service';

export const projectApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProject: builder.query<Project, Project['id']>({
      query: (credentials) => ({
        url: paths.project(credentials),
      }),
      transformResponse: (response: SuccessResponse<Project>) => response.data,
      providesTags: ['Project'],
    }),
    editProject: builder.mutation<Project, Project>({
      query: (credentials) => ({
        url: paths.project(credentials.id),
        method: 'PUT',
        body: credentials,
      }),
      invalidatesTags: (result) => (result ? ['Projects', 'Project', 'Report'] : []),
    }),
  }),
  overrideExisting: false,
});

export const { useGetProjectQuery, useEditProjectMutation } = projectApi;

