import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getCookie } from 'helpers/cookie';

const isProduction = process.env.NODE_ENV === 'production';

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: isProduction ? '/' : 'https://dev.qulap.com',
    prepareHeaders: (headers) => {
      const xsrfToken = getCookie('XSRF-TOKEN');
      if (xsrfToken) {
        headers.set('X-XSRF-TOKEN', decodeURIComponent(xsrfToken));
      }
      headers.set('accept', 'application/json, text/plain, */*');
      if (!isProduction) {
        headers.set('authorization', 'Basic cXVsYXAtc3J2OnNdZGkkYWxCOWRxSzU=');
        headers.set('cors-key', 'qkrXQmfknnWF4Dfk95BSnX');
      }
      return headers;
    },
    mode: 'cors',
    credentials: 'include',
  }),
  tagTypes: [
    'User',
    'UserPermissions',
    'Images',
    'Projects',
    'Project',
    'Interviews',
    'Interview',
    'Report',
    'Tariff',
    'BillingPayments',
    'BillingFiles',
  ],
  endpoints: () => ({}),
});

