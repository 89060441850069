import { Affix as AntdAffix } from 'antd';
import { AffixProps as AntdAffixProps } from 'antd/lib/affix';
import cn from 'classnames';
import React from 'react';

import styles from './affix.module.scss';

export interface AffixProps extends AntdAffixProps {
  className?: string;
}

export function Affix({ className, ...props }: AffixProps) {
  const classes = cn(styles.affix, className);

  return <AntdAffix className={classes} {...props} />;
}

