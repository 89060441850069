import { SaveOutlined } from '@ant-design/icons';
import cn from 'classnames';
import React, { useState } from 'react';

import { Search } from 'shared/input';
import { modalConfirm } from 'shared/modal';

import { EditableImage } from 'components/editable-image';

import { createNewTranscriptionChannel, NewTranscriptionChannel, TranscriptionChannel } from 'models/transcription';

import { EditableName } from '../editable-name';
import styles from './select-name.module.scss';

export interface SelectNameProps {
  onHide: () => void;
  onChangeToggle: (canToggle: boolean) => void;
  channels: TranscriptionChannel[];
  currentChannel?: TranscriptionChannel;
  onChangeChannel: (channel: TranscriptionChannel) => void;
  onRemoveChannel: (channel: TranscriptionChannel, callback: (canToggle: boolean) => void) => void;
  onAddChannel: (channel: NewTranscriptionChannel) => TranscriptionChannel;
  onSelectChannel: (channel: TranscriptionChannel) => void;
}

export function SelectName({
  onHide,
  onChangeToggle,
  currentChannel,
  channels,
  onChangeChannel,
  onRemoveChannel,
  onAddChannel,
  onSelectChannel,
}: SelectNameProps) {
  const [newChannel, setNewChannel] = useState<NewTranscriptionChannel>(() => createNewTranscriptionChannel());
  const [hasError, setHasError] = useState(false);

  const handleAddChannel = () => {
    if (newChannel.name.trim() !== '') {
      const addedChannel = onAddChannel(newChannel);
      handleSelectChannel(addedChannel);
      setNewChannel(createNewTranscriptionChannel());
    } else {
      setHasError(true);
    }
  };

  const handleRemoveChannel = (channel: TranscriptionChannel) => {
    onChangeToggle(false);
    modalConfirm({
      title: 'Вы уверены, что хотите удалить респондента?',
      okText: 'Удалить',
      okButtonProps: {
        danger: true,
      },
      onOk: () => onRemoveChannel(channel, onChangeToggle),
      onCancel: () => onChangeToggle(true),
    });
  };

  const handleSelectChannel = (channel: TranscriptionChannel) => {
    onHide();
    onSelectChannel(channel);
  };

  return (
    <div className={styles.selectChannel}>
      <div className={cn(styles.selectChannel__form, styles.selectChannel__item)}>
        <EditableImage
          className={styles.selectChannel__image}
          isLoading={false}
          image={newChannel.image}
          type='respondent'
          onAdd={(image) => {
            setNewChannel({
              ...newChannel,
              image,
            });
          }}
          onRemove={() => {
            setNewChannel({
              ...newChannel,
              image: null,
            });
          }}
          size='small'
        />
        <Search
          className={styles.selectChannel__input}
          placeholder='Новый респондент'
          status={hasError ? 'error' : undefined}
          value={newChannel.name}
          onChange={(event) => {
            setHasError(false);
            setNewChannel({ ...newChannel, name: event.target.value });
          }}
          onSearch={() => handleAddChannel()}
          onPressEnter={() => handleAddChannel()}
          enterButton={<SaveOutlined />}
        />
      </div>
      <div className={styles.selectChannel__list}>
        {channels?.map((channel) => (
          <EditableName
            key={channel.id}
            className={styles.selectChannel__item}
            channel={channel}
            isCurrent={channel.id === currentChannel?.id}
            onChangeChannel={onChangeChannel}
            onSelectChannel={handleSelectChannel}
            onRemoveChannel={handleRemoveChannel}
          />
        ))}
      </div>
    </div>
  );
}

