import { Select as AntdSelect } from 'antd';
import { SelectProps as AntdSelectProps } from 'antd/lib/select';
import cn from 'classnames';
import React from 'react';

import styles from './select.module.scss';

export interface SelectProps extends AntdSelectProps {
  className?: string;
}

export function Select({ className, size = 'large', ...props }: SelectProps) {
  const classes = cn(styles.select, className);

  return <AntdSelect className={classes} size={size} {...props} />;
}

