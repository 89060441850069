import { Empty as AntdEmpty } from 'antd';
import { EmptyProps as AntdEmptyProps } from 'antd/lib/empty';
import cn from 'classnames';
import React from 'react';

import styles from './empty.module.scss';

export interface EmptyProps extends AntdEmptyProps {
  className?: string;
}

export function Empty({ className, ...props }: EmptyProps) {
  const classes = cn(styles.empty, className);

  return <AntdEmpty className={classes} {...props} />;
}

