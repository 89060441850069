import { Form as AntdForm } from 'antd';
import { FormItemProps as AntdFormItemProps } from 'antd/lib/form';
import cn from 'classnames';
import React from 'react';

import styles from './form.module.scss';

export interface FormItemProps extends AntdFormItemProps {
  className?: string;
}

export function FormItem({ className, ...props }: FormItemProps) {
  const classes = cn(styles.formItem, className);

  return <AntdForm.Item className={classes} {...props} />;
}

