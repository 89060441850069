import { useCallback, useMemo } from 'react';
import { Editor, Transforms, Text, Descendant } from 'slate';

import { QuestionsList } from 'components/editor/editor.types';
import { setPath } from 'components/editor/editor.utils';

import { Question } from 'models/question';

interface UseQuestionsProps {
  editor: Editor;
  nodes: Descendant[];
  questionsList: Question[];
}

export default function useQuestions({ editor, nodes, questionsList }: UseQuestionsProps) {
  const questionsMap: QuestionsList = useMemo(
    () =>
      questionsList.reduce((acc: QuestionsList, val) => {
        acc[val.id] = val;
        return acc;
      }, {}),
    [questionsList],
  );

  const questionMarks = useMemo(() => {
    return setPath(nodes, questionsList);
  }, [nodes, questionsList]);

  const setQuestion = useCallback(
    (id: string, color: string) => () => {
      Transforms.setNodes(editor, { [`_${id}`]: color }, { match: (n) => Text.isText(n), split: true });

      Transforms.setNodes(editor, { path: editor.selection?.focus.path }, { match: (n) => Text.isText(n) });
    },
    [editor],
  );

  return {
    questions: questionsMap,
    questionMarks,
    setQuestion,
  };
}

