import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const useProjectId = () => {
  const params = useParams();

  const projectId = Number(params.projectId);

  return useMemo(
    () => ({
      projectId: projectId ? projectId : undefined,
    }),
    [projectId],
  );
};

