export const useFileDialog = (
  uploadFile: (file: File) => void,
  accept?: string,
): {
  openFileDialog: () => void;
} => {
  const openFileDialog = () => {
    const input = document.createElement('input');
    input.type = 'file';

    if (accept) {
      input.accept = accept;
    }

    input.addEventListener('change', (event: Event) => {
      const { files } = event.target as HTMLInputElement;

      if (files && files.length > 0) {
        uploadFile(files[0]);
      }
    });

    input.click();
  };

  return { openFileDialog };
};
