import { paths } from 'api/paths';

import { api } from 'store/service';

export interface LoginRequest {
  email: string;
  password: string;
}

export const loginApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<void, LoginRequest>({
      query: (credentials) => ({
        url: paths.login(),
        method: 'POST',
        body: credentials,
      }),
      invalidatesTags: (result) => (result ? ['User', 'UserPermissions', 'Projects'] : []),
    }),
  }),
  overrideExisting: false,
});

export const { useLoginMutation } = loginApi;

