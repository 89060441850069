import cn from 'classnames';
import React, { ReactNode, MouseEvent } from 'react';
import { useSlate } from 'slate-react';

import styles from './editor.module.scss';
import { CustomElement, CustomText } from './editor.types';
import { toggleMark, isMarkActive, toggleBlock, isBlockActive } from './editor.utils';

interface MarkButtonProps {
  format: keyof Omit<CustomText, 'text'>;
  children: ReactNode;
}

export function MarkButton({ format, children }: MarkButtonProps) {
  const editor = useSlate();
  return (
    <EditorToolbarButton
      active={isMarkActive(editor, format)}
      onClick={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {children}
    </EditorToolbarButton>
  );
}

interface BlockButtonProps {
  format: CustomElement['type'];
  children: ReactNode;
}

export function BlockButton({ format, children }: BlockButtonProps) {
  const editor = useSlate();
  return (
    <EditorToolbarButton
      active={isBlockActive(editor, format)}
      onClick={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {children}
    </EditorToolbarButton>
  );
}

interface EditorToolbarButtonProps {
  active: boolean;
  onClick: (event: MouseEvent) => void;
  children: ReactNode;
}

function EditorToolbarButton({ active, onClick, children }: EditorToolbarButtonProps) {
  const classes = cn(styles.toolbarButton, { [styles.toolbarButton_active]: active });

  return (
    <button className={classes} onMouseDown={onClick}>
      {children}
    </button>
  );
}

