import { notification } from 'antd';
import cn from 'classnames';
import React, { useState, useEffect, useMemo } from 'react';

import { routes } from 'api/routes';

import { PageHeader } from 'shared/page-header';
import { Table } from 'shared/table';

import { EditableTitle } from 'components/editable-title';
import { ProjectLayout } from 'components/project-layout';

import { getResponseError } from 'helpers/response';

import { useDocumentTitle } from 'hooks/document-title';
import { useProjectId } from 'hooks/project-id';
import { useAppDispatch, useAppSelector } from 'hooks/store';

import { InterviewRow } from 'models/interview';

import { NotFound } from 'features/not-found';
import { useGetProjectQuery } from 'features/project';

import { InterviewActions } from './interviews.actions';
import { getColumns } from './interviews.column-props';
import styles from './interviews.page.module.scss';
import { useGetInterviewsQuery } from './interviews.service';
import { interviewsActions, interviewsSelector } from './interviews.slice';

const { setSelectedInterviews, setValueForFilter } = interviewsActions;

export interface InterviewsPageProps {
  className?: string;
}

export function InterviewsPage({ className }: InterviewsPageProps) {
  const classes = cn(styles.InterviewsPage, className);
  const dispatch = useAppDispatch();
  const { valueForFilter } = useAppSelector(interviewsSelector);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const { projectId } = useProjectId();

  if (projectId === undefined) {
    return <NotFound />;
  }

  const { data: project, error, isError, isLoading: isProjectLoading } = useGetProjectQuery(projectId);
  useDocumentTitle('Интервью', project?.name || '');

  useEffect(() => {
    const key = `notification-${Date.now}`;
    if (isError) {
      notification.error({
        message: getResponseError(error),
        duration: 0,
        key,
      });
    }

    return () => {
      notification.close(key);
    };
  }, [isError]);

  const { data: interviews, isLoading } = useGetInterviewsQuery(projectId);

  useEffect(() => {
    dispatch(setSelectedInterviews([]));
    dispatch(setValueForFilter(''));
  }, []);

  const filteredInterviews = useMemo(
    () =>
      valueForFilter.trim()
        ? interviews?.filter((interview) =>
            interview.name.toLocaleLowerCase().includes(valueForFilter.trim().toLocaleLowerCase()),
          )
        : interviews,
    [interviews, valueForFilter],
  );

  const onSelectChange = (newSelectedRowKeys: React.Key[], newSelectedRow: InterviewRow[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    dispatch(setSelectedInterviews(newSelectedRow));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <ProjectLayout
      className={classes}
      projectId={projectId}
      headerSlot={
        <EditableTitle
          isLoading={isProjectLoading}
          to={routes.project(projectId)}
          image={project?.image}
          title={project?.name}
        />
      }
    >
      <PageHeader title={'Интервью'} extra={<InterviewActions projectId={projectId} />}>
        <Table<InterviewRow>
          data-onboarding='interviews'
          rowKey={(record) => record.id}
          columns={getColumns(projectId)}
          dataSource={filteredInterviews}
          rowSelection={rowSelection}
          loading={isLoading}
        />
      </PageHeader>
    </ProjectLayout>
  );
}

