import { Checkbox as AntdCheckbox } from 'antd';
import { CheckboxProps as AntdCheckboxProps } from 'antd/lib/checkbox';
import cn from 'classnames';
import React from 'react';

import styles from './checkbox.module.scss';

export interface CheckboxProps extends AntdCheckboxProps {
  className?: string;
}

export function Checkbox({ className, ...props }: CheckboxProps) {
  const classes = cn(styles.Checkbox, className);

  return <AntdCheckbox className={classes} {...props} />;
}

