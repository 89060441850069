import cn from 'classnames';
import React from 'react';

import { routes } from 'api/routes';

import { Logo } from 'components/logo';
import { SidebarMenu, SidebarMenuItemProps } from 'components/sidebar-menu';

import styles from './sidebar.module.scss';

export interface SidebarProps {
  className?: string;
  menuItems: SidebarMenuItemProps[];
}

export function Sidebar({ className, menuItems }: SidebarProps) {
  const classes = cn(styles.sidebar, className);

  return (
    <div className={classes}>
      <div className={styles.header}>
        <Logo to={routes.home()} />
      </div>
      <div className={styles.body}>
        <SidebarMenu items={menuItems} />
      </div>
    </div>
  );
}

