import { List as AntdList } from 'antd';
import { ListItemProps as AntdListItemProps } from 'antd/lib/list';
import cn from 'classnames';
import React from 'react';

import styles from './list.module.scss';

export interface ListItemProps extends AntdListItemProps {
  className?: string;
}

export function ListItem({ className, ...props }: ListItemProps) {
  const classes = cn(styles.listItem, className);

  return <AntdList.Item className={classes} {...props} />;
}

