import { useMemo } from 'react';

import { useAppSelector } from 'hooks/store';

import { userSelector } from 'store/slices/user.slice';

export const useUser = () => {
  const { user, userPermissions, isLoading } = useAppSelector(userSelector);

  return useMemo(
    () => ({
      user,
      userPermissions,
      isLoading,
    }),
    [user, userPermissions, isLoading],
  );
};

