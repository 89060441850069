import { CaretRightOutlined } from '@ant-design/icons';
import cn from 'classnames';
import isEqual from 'lodash.isequal';
import React, { useMemo, useState } from 'react';
import { Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';

import { Avatar } from 'shared/avatar';
import { Button } from 'shared/button';
import { modalError } from 'shared/modal';
import { Popover } from 'shared/popover';
import { Title } from 'shared/typography';

import { InterviewItemElement } from 'components/editor/editor.types';
import { PlayerCurrentTime, PlayerProgress } from 'components/player';

import { findMinByKey } from 'helpers/find-min-or-max-by-key';
import { formatTime } from 'helpers/format-time';

import { NewTranscriptionChannel, TranscriptionChannel } from 'models/transcription';

import { SelectName } from '../select-name';
import styles from './interview-item.module.scss';

export type InterviewItemProps = Omit<RenderElementProps, 'element'> & {
  channels?: TranscriptionChannel[];
  onChangeChannels?: (channels: TranscriptionChannel[]) => void;
  element: InterviewItemElement;
  activeChannelIds?: Set<TranscriptionChannel['id']>;
  playerProgress?: PlayerProgress;
  onReplicaPlayClick?: (currentTime: PlayerCurrentTime) => void;
};

export const InterviewItem = React.memo(
  function InterviewItem({
    channels,
    onChangeChannels,
    children,
    attributes,
    element,
    activeChannelIds,
    playerProgress,
    onReplicaPlayClick,
  }: InterviewItemProps) {
    const isCurrentPhrase = useMemo<boolean>(() => {
      const progressTime = playerProgress?.time || 0;
      const start = element.meta?.transcriptionText.start || 0;
      const end = element.meta?.transcriptionText.end || 0;

      return progressTime > start && progressTime < end;
    }, [element.meta?.transcriptionText.start, element.meta?.transcriptionText.end, playerProgress?.time]);

    const [openPopover, setOpenPopover] = useState(false);
    const [canTogglePopover, setCanTogglePopover] = useState(true);

    const editor = useSlateStatic();

    const onHidePopover = () => {
      setOpenPopover(false);
    };

    const onTogglePopover = (visible: boolean) => {
      if (canTogglePopover) {
        setOpenPopover(visible);
      }
    };

    const getReplicaName = () => {
      const replicaNameId = element.meta?.transcriptionText.channel;
      return channels?.find((channel) => channel.id === replicaNameId)?.name;
    };

    const getReplicaStartTime = () => {
      const replicaStart = element.meta?.transcriptionText.start;
      return replicaStart !== undefined ? formatTime(replicaStart) : '';
    };

    const getReplicaImage = () => {
      const replicaNameId = element.meta?.transcriptionText.channel;
      return channels?.find((channel) => channel.id === replicaNameId)?.image?.url;
    };

    const onAddChannel = (channel: NewTranscriptionChannel) => {
      const newId = findMinByKey(channels || [], 'id', 0) - 1;
      const newChannel = { ...channel, id: newId };

      if (channels && onChangeChannels) {
        onChangeChannels([...channels, newChannel]);
      }

      return newChannel;
    };

    const onRemoveChannel = (channel: TranscriptionChannel, callback: (canToggle: boolean) => void) => {
      if (activeChannelIds?.has(channel.id)) {
        modalError({
          title: 'Нельзя удалить используемого респондента',
          onOk: () => callback(true),
        });
      } else {
        callback(true);
        if (channels && onChangeChannels) {
          onChangeChannels(channels.filter((curChannel) => curChannel.id !== channel.id));
        }
      }
    };

    const onChangeChannel = (channel: TranscriptionChannel) => {
      if (channels && onChangeChannels) {
        onChangeChannels(
          channels.map((currentChannel) => (currentChannel.id === channel.id ? channel : currentChannel)),
        );
      }
    };

    const onSelectChannel = (channel: TranscriptionChannel) => {
      if (!element.meta) {
        return;
      }

      const path = ReactEditor.findPath(editor, element);
      const newProperties: Partial<InterviewItemElement> = {
        meta: {
          ...element.meta,
          transcriptionText: {
            ...element.meta.transcriptionText,
            channel: channel.id,
          },
        },
      };

      Transforms.setNodes(editor, newProperties, { at: path });
    };

    const onPlayClick = () => {
      const currentTime: PlayerCurrentTime = {
        id: element.meta?.transcriptionText.id || 0,
        seconds: element.meta?.transcriptionText.start || 0,
      };

      onReplicaPlayClick?.(currentTime);
    };

    return (
      <>
        <div {...attributes} className={styles.message}>
          <div contentEditable={false} suppressContentEditableWarning={true}>
            <Avatar className={styles.message__image} size={48} src={getReplicaImage()} />
          </div>
          <div className={styles.message__content}>
            <div contentEditable={false} suppressContentEditableWarning={true}>
              <Popover
                trigger='click'
                placement='rightTop'
                open={openPopover}
                onOpenChange={onTogglePopover}
                destroyTooltipOnHide={true}
                zIndex={900}
                content={
                  <SelectName
                    channels={channels || []}
                    onHide={onHidePopover}
                    onChangeToggle={setCanTogglePopover}
                    onSelectChannel={onSelectChannel}
                    onAddChannel={onAddChannel}
                    onChangeChannel={onChangeChannel}
                    onRemoveChannel={onRemoveChannel}
                  />
                }
              >
                <Title className={styles.message__name} level={5}>
                  {getReplicaName() || 'Неизвестно'}
                </Title>
              </Popover>
              <Button
                className={styles.message__play}
                type='default'
                onClick={onPlayClick}
                icon={playerProgress?.isReady ? <CaretRightOutlined /> : undefined}
                size='small'
                disabled={!playerProgress?.isReady}
              >
                {getReplicaStartTime()}
              </Button>
            </div>

            <div
              className={cn(styles.message__text, {
                [styles.message__text_active]: isCurrentPhrase,
              })}
            >
              {children}
            </div>
          </div>
        </div>
      </>
    );
  },
  (prevProps, nextProps) => {
    const prevProgressTime = prevProps.playerProgress?.time || 0;
    const prevStart = prevProps.element.meta?.transcriptionText.start || 0;
    const prevEnd = prevProps.element.meta?.transcriptionText.end || 0;
    const progressTime = nextProps.playerProgress?.time || 0;
    const start = nextProps.element.meta?.transcriptionText.start || 0;
    const end = nextProps.element.meta?.transcriptionText.end || 0;
    const wasCurrentPhrase = prevProgressTime > prevStart && prevProgressTime < prevEnd;
    const willCurrentPhrase = progressTime > start && progressTime < end;
    const prevProgressReady = Boolean(prevProps.playerProgress?.isReady);
    const nextProgressReady = Boolean(nextProps.playerProgress?.isReady);

    return (
      prevProgressReady === nextProgressReady &&
      wasCurrentPhrase === willCurrentPhrase &&
      prevProps.activeChannelIds === nextProps.activeChannelIds &&
      isEqual(prevProps.channels, nextProps.channels) &&
      isEqual(prevProps.element, nextProps.element)
    );
  },
);

