import { notification } from 'antd';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import { Affix } from 'shared/affix';
import { Button } from 'shared/button';
import { PageHeader } from 'shared/page-header';
import { Spin } from 'shared/spin';

import { PageLayout } from 'components/page-layout';

import { checkNotFoundError, getResponseError } from 'helpers/response';

import { useDocumentTitle } from 'hooks/document-title';
import { useAppSelector } from 'hooks/store';

import { Companies } from './components/companies/companies';
import styles from './marketplace.page.module.scss';
import { useGetCompaniesQuery } from './marketplace.service';
import { marketplaceSelector } from './marketplace.slice';

export interface MarketplacePageProps {
  className?: string;
}

export function MarketplacePage({ className }: MarketplacePageProps) {
  const classes = cn(styles.marketplacePage, className);
  const { selectedCompanies } = useAppSelector(marketplaceSelector);

  const { data, isLoading, error, isError } = useGetCompaniesQuery();
  useDocumentTitle('Маркетплейс рекрутинга респондентов');

  const [isSticky, setIsSticky] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const key = `notification-${Date.now}`;
    if (isError && !checkNotFoundError(error)) {
      notification.error({
        message: getResponseError(error),
        duration: 0,
        key,
      });
    }

    return () => {
      notification.close(key);
    };
  }, [isError]);

  const onOpenSurvey = () => {
    window.open(
      `https://adahtrbm6es.typeform.com/to/Gk7ou5BI#company_id=${selectedCompanies.map(({ id }) => id).join(',')}`,
      '_self',
    );
  };

  return (
    <PageLayout className={classes}>
      <PageHeader title={'Маркетплейс рекрутинга респондентов'}>
        {isLoading ? (
          <div className={styles.marketplacePage__spinWrapper}>
            <Spin />
          </div>
        ) : (
          <>
            <Companies companies={data || []} />
            <Affix className={styles.marketplacePage__actions} offsetBottom={24} onChange={setIsSticky}>
              <div
                className={cn(styles.marketplacePage__actionsContainer, {
                  [styles.marketplacePage__actionsContainer_sticky]: isSticky,
                })}
              >
                <Button
                  disabled={selectedCompanies.length === 0}
                  title={selectedCompanies.length === 0 ? 'Выберите хотя бы одну компанию' : undefined}
                  onClick={onOpenSurvey}
                >
                  Заполните бриф на рекрут
                </Button>
              </div>
            </Affix>
          </>
        )}
      </PageHeader>
    </PageLayout>
  );
}

