import React from 'react';

import styles from './questions.module.scss';

type PlaceholderProps = {
  depth: number;
};

export function Placeholder({ depth }: PlaceholderProps) {
  const indent = depth * 24;
  return <div className={styles.placeholder} style={{ left: indent }}></div>;
}

