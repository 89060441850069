import { PlusOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { routes } from 'api/routes';

import { Button } from 'shared/button';
import { Input } from 'shared/input';
import { modalConfirm } from 'shared/modal';
import { Space } from 'shared/space';

import { declOfNum } from 'helpers/decl-of-num';
import { getResponseError } from 'helpers/response';

import { useAppDispatch, useAppSelector } from 'hooks/store';

import { createNewProject } from 'models/project';

import { useAddProjectMutation, useRemoveProjectsMutation } from './projects.service';
import { projectsActions, projectsSelector } from './projects.slice';

const { setSelectedProjects, setValueForFilter } = projectsActions;

export function ProjectActions() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedProjects, valueForFilter } = useAppSelector(projectsSelector);
  const [removeProjectsMutation] = useRemoveProjectsMutation();
  const [addProject, { isLoading }] = useAddProjectMutation();

  const count = selectedProjects.length;
  const projectWord = declOfNum(count, ['проект', 'проекта', 'проектов']);
  const selectedWord = declOfNum(count, ['выбранный', 'выбранные', 'выбранные']);

  const removeProjects = async () => {
    try {
      await removeProjectsMutation(selectedProjects.map((project) => project.id)).unwrap();
      dispatch(setSelectedProjects([]));
      notification.success({
        message: `${declOfNum(count, ['Проект', 'Проекты', 'Проекты'])} ${declOfNum(count, [
          'удален',
          'удалены',
          'удалены',
        ])}`,
      });
    } catch (error) {
      notification.error({
        message: getResponseError(error),
      });
    }
  };

  const onRemoveProjects = () => {
    modalConfirm({
      title: `Вы уверены, что хотите удалить ${selectedWord} ${projectWord}?`,
      okText: 'Удалить',
      okButtonProps: {
        danger: true,
      },
      onOk: removeProjects,
    });
  };

  const onCreateProject = async () => {
    try {
      const createdProject = await addProject(createNewProject()).unwrap();
      navigate(routes.project(createdProject.id));
    } catch (error) {
      notification.error({
        message: getResponseError(error),
      });
    }
  };

  if (count === 0) {
    return (
      <Space>
        <Input
          allowClear={true}
          addonBefore={<SearchOutlined />}
          placeholder='Поиск...'
          value={valueForFilter}
          onChange={(e) => dispatch(setValueForFilter(e.target.value))}
        />
        <Button icon={<PlusOutlined />} disabled={isLoading} onClick={onCreateProject}>
          Создать проект
        </Button>
      </Space>
    );
  }

  return (
    <Space>
      <div>
        {declOfNum(count, ['Выбран', 'Выбрано', 'Выбрано'])} {count} {projectWord}
      </div>
      <Button danger={true} icon={<DeleteOutlined />} onClick={onRemoveProjects}>
        Удалить {selectedWord}
      </Button>
    </Space>
  );
}

