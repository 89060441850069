import { Collapse as AntdCollapse } from 'antd';
import { CollapseProps as AntdCollapseProps } from 'antd/lib/collapse';
import cn from 'classnames';
import React from 'react';

import styles from './collapse.module.scss';

export interface CollapseProps extends AntdCollapseProps {
  className?: string;
}

export function Collapse({ className, ...props }: CollapseProps) {
  const classes = cn(styles.collapse, className);

  return <AntdCollapse className={classes} {...props} />;
}

