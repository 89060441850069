import { notification } from 'antd';
import React from 'react';

import { ImageGallery } from 'components/image-gallery';

import { getResponseError } from 'helpers/response';

import { Image, ImageRelatedType } from 'models/image';

import { useGetImagesQuery, useAddImageMutation, useRemoveImageMutation } from './select-image.service';

export interface SelectImageGalleryProps {
  type: ImageRelatedType;
  onSelect: (image: Image) => void;
}

export function SelectImageGallery({ type, onSelect }: SelectImageGalleryProps) {
  const { data: images } = useGetImagesQuery(type);
  const [addImage] = useAddImageMutation();
  const [removeImage] = useRemoveImageMutation();

  const onAdd = async (file: File) => {
    try {
      await addImage({ type, file }).unwrap();
    } catch (error) {
      notification.error({
        message: getResponseError(error),
      });
    }
  };

  const onRemove = async (image: Image) => {
    try {
      await removeImage(image.id).unwrap();
    } catch (error) {
      notification.error({
        message: getResponseError(error),
      });
    }
  };

  return <ImageGallery items={images} onAdd={onAdd} onSelect={onSelect} onRemove={onRemove} />;
}

