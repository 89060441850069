import { paths } from 'api/paths';

import { saveFile } from 'helpers/save-file';

import { Project } from 'models/project';
import { convertReportTableRowsToRequest, Report, ReportTableRow } from 'models/report';
import { SuccessResponse } from 'models/response';

import { api } from 'store/service';

export const reportApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getReport: builder.query<Report, Project['id']>({
      query: (projectId) => ({
        url: paths.report(projectId),
      }),
      transformResponse: (response: SuccessResponse<Report>) => response.data,
      providesTags: ['Report'],
    }),
    getSharedReport: builder.query<Report, string>({
      query: (uuid) => ({
        url: paths.sharedReport(uuid),
      }),
      transformResponse: (response: SuccessResponse<Report>) => response.data,
    }),
    downloadReport: builder.query<void, { projectId: Project['id']; projectName?: Project['name'] }>({
      query: ({ projectId, projectName }) => ({
        url: paths.downloadReport(projectId),
        responseHandler: async (response) => {
          if (response.ok) {
            const blob = await response.blob();
            saveFile(blob, projectName ? `Отчет - ${projectName}` : 'Отчет');
          }
        },
        cache: 'no-cache',
      }),
    }),
    shareReport: builder.mutation<string, Project['id']>({
      query: (projectId) => ({
        url: paths.shareReport(projectId),
        method: 'PUT',
      }),
      transformResponse: (response: SuccessResponse<string>) => response.data,
    }),
    editReport: builder.mutation<Report, { projectId: Project['id']; reports: ReportTableRow[] }>({
      query: ({ projectId, reports }) => ({
        url: paths.report(projectId),
        method: 'PUT',
        body: convertReportTableRowsToRequest(reports),
      }),
      invalidatesTags: (result) => (result ? ['Report'] : []),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetReportQuery,
  useGetSharedReportQuery,
  useLazyDownloadReportQuery,
  useShareReportMutation,
  useEditReportMutation,
} = reportApi;

