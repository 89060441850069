import { DeleteOutlined, HolderOutlined } from '@ant-design/icons';
import { NodeModel } from '@minoru/react-dnd-treeview';
import cn from 'classnames';
import React, { ChangeEvent, ClipboardEvent, KeyboardEvent } from 'react';

import { Input } from 'shared/input';

import { NewQuestion, Question } from 'models/question';

import { Color } from './color';
import styles from './questions.module.scss';

interface CustomNodeProps {
  node: NodeModel<Question | NewQuestion>;
  depth: number;
  onCreate: (id?: NodeModel['id'], name?: string) => void;
  onRemove: (id: NodeModel['id']) => void;
  onTextChange: (id: NodeModel['id'], value: string) => void;
  onColorChange: (id: NodeModel['id'], parent: NodeModel['parent'], color: Question['color']) => void;
}

export function CustomNode({ node, depth, onCreate, onRemove, onTextChange, onColorChange }: CustomNodeProps) {
  const { data } = node;
  const indent = depth * 24;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onTextChange(node.id, event.target.value);
  };

  const handleCreate = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.trim()) {
      onCreate(node.id);
    }
  };

  const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    const values = event.clipboardData.getData('text').split('\n');
    const [name, ...names] = values;
    if (names.length > 0) {
      event.preventDefault();
      onTextChange(node.id, name);
      names.reverse().forEach((newName) => {
        onCreate(node.id, newName);
      });
    }
  };

  return (
    <div
      className={cn(styles.customNode, { [styles.customNode_droppable]: depth === 0 })}
      style={{ paddingInlineStart: indent }}
    >
      <HolderOutlined className={styles.customNode__grabButton} />
      <Input
        value={data?.name}
        onChange={handleChange}
        onPressEnter={handleCreate}
        onPaste={handlePaste}
        autoFocus={true}
      />
      {data?.color && <Color color={data.color} onChange={(color) => onColorChange(node.id, node.parent, color)} />}
      <button className={styles.customNode__deleteButton} onClick={() => onRemove(node.id)}>
        <DeleteOutlined />
      </button>
    </div>
  );
}

