import { History, Blocker, Transition } from 'history';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, UNSAFE_NavigationContext } from 'react-router-dom';

const useBlocker = (blocker: Blocker, when = true) => {
  const navigator = useContext(UNSAFE_NavigationContext).navigator as History;

  useEffect(() => {
    if (!when) return;

    const unblock = navigator.block((transition: Transition) => {
      const autoUnblockingTransition = {
        ...transition,
        retry() {
          unblock();
          transition.retry();
        },
      };

      blocker(autoUnblockingTransition);
    });

    return unblock;
  }, [navigator, blocker, when]);
};

export const usePrompt = (when: boolean) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState<Transition | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
  }, []);

  const handleBlockedNavigation = useCallback(
    (nextLocation: Transition) => {
      if (!confirmedNavigation && nextLocation.location.pathname !== location.pathname) {
        setShowPrompt(true);
        setLastLocation(nextLocation);
        return false;
      }
      return true;
    },
    [confirmedNavigation],
  );

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  useBlocker(handleBlockedNavigation, when);

  return { showPrompt, confirmNavigation, cancelNavigation };
};

