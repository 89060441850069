import { notification } from 'antd';
import cn from 'classnames';
import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';
import React, { useEffect, useState } from 'react';

import { routes } from 'api/routes';

import { Empty } from 'shared/empty';
import { PageHeader } from 'shared/page-header';
import { Table } from 'shared/table';

import { EditableTitle } from 'components/editable-title';
import { ProjectLayout } from 'components/project-layout';

import { checkNotFoundError, getResponseError } from 'helpers/response';

import { useDocumentTitle } from 'hooks/document-title';
import { useLeaveBlocker } from 'hooks/leave-blocker';
import { useProjectId } from 'hooks/project-id';

import { convertReportToTableRows, Report, ReportQuestion, ReportTableRow } from 'models/report';

import { NotFound } from 'features/not-found';
import { useGetProjectQuery } from 'features/project';

import empty from './empty.png';
import { ReportActions } from './report.actions';
import { getColumns } from './report.column-props';
import styles from './report.page.module.scss';
import { useGetReportQuery, useEditReportMutation } from './report.service';

export interface ReportPageProps {
  className?: string;
}

export function ReportPage({ className }: ReportPageProps) {
  const classes = cn(styles.report, className);
  const [editReport, { isLoading: isSaving }] = useEditReportMutation();

  const { projectId } = useProjectId();

  if (projectId === undefined) {
    return <NotFound />;
  }

  const { data: project, error, isError, isLoading: isProjectLoading } = useGetProjectQuery(projectId);
  useDocumentTitle('Отчет', project?.name || '');

  useEffect(() => {
    const key = `notification-${Date.now}`;
    if (isError && !checkNotFoundError(error)) {
      notification.error({
        message: getResponseError(error),
        duration: 0,
        key,
      });
    }

    return () => {
      notification.close(key);
    };
  }, [isError]);

  const { data: report, isLoading } = useGetReportQuery(projectId);

  const [convertedReports, setConvertedReports] = useState<ReportTableRow[]>([]);

  useEffect(() => {
    if (report) {
      setConvertedReports(convertReportToTableRows(report));
    }
  }, [report]);

  const onChangeConclusion = (id: ReportQuestion['id'], value: ReportQuestion['conclusion']) => {
    setConvertedReports((reports) => {
      const updatedReports = reports.map((r) => ({
        ...r,
        conclusion: r.id === id ? value : r.conclusion,
      }));
      if (project?.demo !== true && report) {
        compareReports(report, updatedReports, setIsBlocking);
      }
      return updatedReports;
    });
  };

  const onSave = async () => {
    try {
      await editReport({ projectId, reports: convertedReports }).unwrap();
      notification.success({
        message: 'Отчет сохранен',
      });
      setIsBlocking(false);
    } catch (error) {
      notification.error({
        message: getResponseError(error),
      });
    }
  };

  const { setIsBlocking } = useLeaveBlocker(onSave);

  const hasAnswers = report?.questions.some((question) => {
    return question.answers.length > 0;
  });

  if (checkNotFoundError(error)) {
    return <NotFound />;
  }

  return (
    <ProjectLayout
      className={classes}
      projectId={projectId}
      headerSlot={
        <EditableTitle
          isLoading={isProjectLoading}
          to={routes.project(projectId)}
          image={project?.image}
          title={project?.name}
        />
      }
    >
      <PageHeader
        title={'Отчет исследования'}
        extra={
          hasAnswers && (
            <ReportActions
              projectId={projectId}
              projectName={project?.name}
              isLoading={isLoading}
              onSave={onSave}
              isSaving={isSaving}
            />
          )
        }
      >
        {hasAnswers ? (
          <Table<ReportTableRow>
            rowKey={(record) => record.id}
            columns={getColumns(report?.respondents, onChangeConclusion)}
            dataSource={convertedReports}
            loading={isLoading}
            scroll={{ x: 250, y: 'max-content' }}
          />
        ) : (
          !isLoading && (
            <Empty
              image={empty}
              description='Загрузите "Интервью" и выделите ключевые моменты в них, чтобы увидеть Отчет исследования'
            />
          )
        )}
      </PageHeader>
    </ProjectLayout>
  );
}

const compareReports = debounce(function (
  originalReport: Report,
  updatedReport: ReportTableRow[],
  setIsBlocking: (isBlocking: boolean) => void,
): void {
  setIsBlocking(
    !isEqual(
      convertReportToTableRows(originalReport).map(({ conclusion }) => conclusion),
      updatedReport.map(({ conclusion }) => conclusion),
    ),
  );
},
750);

