import React, { useState } from 'react';

import { Popover } from 'shared/popover';

import { colors, transformColor } from 'models/color';
import { Question } from 'models/question';

import styles from './questions.module.scss';

export interface ColorProps {
  color: Question['color'];
  onChange: (color: Question['color']) => void;
}

export function Color({ color, onChange }: ColorProps) {
  const [open, setOpen] = useState(false);

  const onHide = () => {
    setOpen(false);
  };

  const onopenChange = (newopen: boolean) => {
    setOpen(newopen);
  };

  const onSelect = (colorName: Question['color']) => {
    onChange(colorName);
    onHide();
  };

  return (
    <Popover
      placement='bottom'
      content={
        <div className={styles.color__container}>
          {colors.map((colorName: Question['color']) => (
            <div
              key={colorName}
              className={styles.color__item}
              style={{ background: transformColor(colorName) }}
              onClick={() => onSelect(colorName)}
            ></div>
          ))}
        </div>
      }
      trigger='click'
      open={open}
      onOpenChange={onopenChange}
    >
      <div className={styles.color}>
        <div className={styles.color__action} style={{ background: transformColor(color) }} />
      </div>
    </Popover>
  );
}

