import { paths } from 'api/paths';

import { BillingRow } from 'models/billing';
import { SuccessResponse } from 'models/response';
import { TableRequest, TableResponse } from 'models/table';

import { api } from 'store/service';

export const billingApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPayments: builder.query<TableResponse<BillingRow>, TableRequest>({
      query: (params) => ({
        url: paths.billingPayments(),
        params,
      }),
      transformResponse: (response: SuccessResponse<TableResponse<BillingRow>>) => response.data,
      providesTags: ['BillingPayments'],
    }),
    getFiles: builder.query<TableResponse<BillingRow>, TableRequest>({
      query: (params) => ({
        url: paths.billingFiles(),
        params,
      }),
      transformResponse: (response: SuccessResponse<TableResponse<BillingRow>>) => response.data,
      providesTags: ['BillingFiles'],
    }),
  }),
  overrideExisting: false,
});

export const { useGetPaymentsQuery, useGetFilesQuery } = billingApi;

