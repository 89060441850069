import { notification } from 'antd';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PageHeader } from 'shared/page-header';
import { Table } from 'shared/table';

import { PageLayout } from 'components/page-layout';

import { checkNotFoundError, getResponseError } from 'helpers/response';

import { useDocumentTitle } from 'hooks/document-title';

import { convertReportToTableRows, ReportTableRow } from 'models/report';

import { NotFound } from 'features/not-found';

import { getColumns } from './report.column-props';
import styles from './report.page.module.scss';
import { useGetSharedReportQuery } from './report.service';

export interface ReportSharedPageProps {
  className?: string;
}

export function ReportSharedPage({ className }: ReportSharedPageProps) {
  const classes = cn(styles.report, className);
  const params = useParams();

  useDocumentTitle('Отчет');

  const uuid = params.uuid;

  if (uuid === undefined) {
    return <NotFound />;
  }

  const { data: report, isLoading, error, isError } = useGetSharedReportQuery(uuid);

  useEffect(() => {
    const key = `notification-${Date.now}`;
    if (isError && !checkNotFoundError(error)) {
      notification.error({
        message: getResponseError(error),
        duration: 0,
        key,
      });
    }

    return () => {
      notification.close(key);
    };
  }, [isError]);

  const [convertedReports, setConvertedReports] = useState<ReportTableRow[]>([]);

  useEffect(() => {
    if (report) {
      setConvertedReports(convertReportToTableRows(report));
    }
  }, [report]);

  if (checkNotFoundError(error)) {
    return <NotFound />;
  }

  return (
    <PageLayout className={classes}>
      <PageHeader title={'Отчет исследования'}>
        <Table<ReportTableRow>
          rowKey={(record) => record.id}
          columns={getColumns(report?.respondents)}
          dataSource={convertedReports}
          loading={isLoading}
          scroll={{ x: 250, y: 'max-content' }}
        />
      </PageHeader>
    </PageLayout>
  );
}

