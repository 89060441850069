import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const useInterviewId = () => {
  const params = useParams();

  const interviewId = Number(params.interviewId);

  return useMemo(
    () => ({
      interviewId: interviewId ? interviewId : undefined,
    }),
    [interviewId],
  );
};

