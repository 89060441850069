import { createSlice } from '@reduxjs/toolkit';

import { storeHelper } from 'helpers/store';

import { InterviewRow } from 'models/interview';

import { RootState } from 'store/store';

interface State {
  selectedInterviews: InterviewRow[];
  valueForFilter: string;
}

const initialState: State = {
  selectedInterviews: [],
  valueForFilter: '',
};

const { setAction } = storeHelper<State>();

export const interviewsSlice = createSlice({
  name: 'interviews',
  initialState,
  reducers: {
    setSelectedInterviews: setAction('selectedInterviews'),
    setValueForFilter: setAction('valueForFilter'),
  },
});

export const interviewsActions = interviewsSlice.actions;

export const interviewsSelector = (state: RootState): State => state.interviews;

