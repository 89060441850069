import { TourProvider } from '@reactour/tour';
import { notification } from 'antd';
import React, { ReactNode, useCallback } from 'react';

import { noop } from 'helpers/noop';
import { getResponseError } from 'helpers/response';

import { useFinishOnboardingMutation } from 'store/services/user.service';

import { OnboardingMiddleware } from './onboarding.middleware';
import { OnboardingPopover } from './onboarding.popover';

export interface OnboardingProviderProps {
  children: ReactNode;
}

export function OnboardingProvider({ children }: OnboardingProviderProps) {
  const [finishOnboarding] = useFinishOnboardingMutation();

  const handleFinish = useCallback(async () => {
    try {
      await finishOnboarding().unwrap();
    } catch (error) {
      notification.error({
        message: getResponseError(error),
      });
    }
  }, []);

  return (
    <TourProvider
      steps={[]}
      disableKeyboardNavigation={true}
      onClickMask={noop}
      ContentComponent={(props) => <OnboardingPopover onFinish={handleFinish} {...props} />}
      styles={{ popover: (base) => ({ ...base, padding: 0, borderRadius: 8 }) }}
    >
      <OnboardingMiddleware onFinish={handleFinish}>{children}</OnboardingMiddleware>
    </TourProvider>
  );
}

