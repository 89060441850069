import { Image } from 'models/image';
import { Question } from 'models/question';

export interface ReportRespondent {
  name: string;
  image: Image | null;
}

type ReportAnswer = string;

export interface ReportQuestion extends Pick<Question, 'id' | 'name' | 'color'> {
  number: string;
  answers: ReportAnswer[];
  conclusion: string;
}

export interface Report {
  respondents: ReportRespondent[];
  questions: ReportQuestion[];
}

export interface ReportTableRowAnswer {
  respondent: ReportRespondent;
  answers: ReportAnswer[];
}

export interface ReportTableRow {
  id: ReportQuestion['id'];
  question: Pick<ReportQuestion, 'color' | 'number' | 'name'>;
  conclusion: ReportQuestion['conclusion'];
  [respondent: number]: ReportTableRowAnswer;
}

interface ReportConclusion {
  id: ReportQuestion['id'];
  text: ReportQuestion['conclusion'];
}

export interface ReportRequest {
  conclusions: ReportConclusion[];
}

export function convertReportToTableRows(report: Report): ReportTableRow[] {
  return report.questions.map((question) => ({
    id: question.id,
    question: {
      color: question.color,
      number: question.number,
      name: question.name,
    },
    conclusion: question.conclusion,
    ...question.answers.map((answers, index) => ({
      respondent: report.respondents[index],
      answers,
    })),
  }));
}

export function convertReportTableRowsToRequest(reports: ReportTableRow[]): ReportRequest {
  return {
    conclusions: reports.map((report) => ({
      id: report.id,
      text: report.conclusion,
    })),
  };
}

