import { CloseOutlined } from '@ant-design/icons';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Text, Transforms } from 'slate';
import { useSlateStatic } from 'slate-react';

import { transformColor } from 'models/color';
import { Question } from 'models/question';

import styles from './editor.module.scss';
import { QuestionMarksGrouped, QuestionsList, QuestionsOnPath, QuestionsToNode } from './editor.types';
import { getElementOffsetPosition, getFlatProjectQuestions } from './editor.utils';

interface IQuestionMarks {
  questions: QuestionsList;
  questionsToPath: QuestionsOnPath[];
}

function findDomNode(path: number[]) {
  const iter = path[1] + 1;
  const leaf = path[2] + 1;

  return document.querySelector(
    `#editor-box [data-slate-node="element"]:nth-of-type(1) [data-slate-node="element"]:nth-of-type(${iter}) span[data-slate-node="text"]:nth-of-type(${leaf})`,
  ) as HTMLSpanElement;
}

function calculateTopOffsets(items: QuestionMarksGrouped[]) {
  let maxY = 0;
  const height = 32;
  const gap = 2;

  const fieldMarks: QuestionMarksGrouped[] = items.reduce((acc: QuestionMarksGrouped[], val) => {
    const node = findDomNode(val.nodesPaths[0]);
    const pos = getElementOffsetPosition(node);

    const top = Math.max(pos.top - height / 2 + pos.height / 2, maxY);
    acc.push({
      ...val,
      top,
    });
    maxY = top + height + gap;
    return acc;
  }, []);

  return fieldMarks;
}

export default function QuestionMarksFields(props: IQuestionMarks) {
  const flatQuestions = getFlatProjectQuestions(Object.values(props.questions));

  const questionsToNode = useMemo(() => {
    const questionToNode = props.questionsToPath.reduce((acc: QuestionsToNode[], item) => {
      const questionList: QuestionsToNode[] = item.questions.map((qId) => ({
        question: flatQuestions.find((q) => `${q.id}` === qId) as Question,
        path: item.path,
        questionId: qId,
      }));

      return [...acc, ...questionList];
    }, []);

    return questionToNode;
  }, [props.questionsToPath, props.questions]);

  const [marks, setMarks] = useState<QuestionsToNode[]>([]);
  useEffect(() => {
    setMarks(questionsToNode);
  }, [questionsToNode]);

  const removeDuplicates = useMemo(() => {
    return marks.reduce((acc: QuestionMarksGrouped[], item) => {
      if (!acc[acc.length - 1]) {
        acc = [
          {
            top: 0,
            question: item.question,
            questionId: item.questionId,
            nodesPaths: [item.path],
          },
        ];
        return acc;
      }

      const prevValueIndex = acc.findIndex((prevCandidate) => {
        const isNextPath = item.path[1] === prevCandidate.nodesPaths[prevCandidate.nodesPaths.length - 1][1];
        const isNextLeaf = item.path[2] - 1 === prevCandidate.nodesPaths[prevCandidate.nodesPaths.length - 1][2];

        const prevValueMergible = prevCandidate.questionId === item.questionId && isNextPath && isNextLeaf;

        return prevValueMergible;
      });

      if (prevValueIndex !== -1) {
        const prevValue = acc[prevValueIndex];
        acc[prevValueIndex] = {
          ...prevValue,
          nodesPaths: [...prevValue.nodesPaths, item.path],
        };
      } else {
        acc.push({
          top: 0,
          question: item.question,
          questionId: item.questionId,
          nodesPaths: [item.path],
        });
      }

      return acc;
    }, []);
  }, [marks]);

  const wrapRef = useRef<HTMLDivElement>(null);
  const withCoords = useMemo(() => {
    let topOffset = 0;
    if (wrapRef.current) {
      const { top } = getElementOffsetPosition(wrapRef.current);
      topOffset = top;
    }
    return calculateTopOffsets(removeDuplicates).map((mark) => ({
      ...mark,
      top: mark.top - topOffset,
    }));
  }, [removeDuplicates]);

  const editor = useSlateStatic();
  const onClick = (mark: QuestionMarksGrouped) => () => {
    mark.nodesPaths.forEach((path) => {
      const domNode = findDomNode(path);
      domNode.classList.remove(styles.questionElementFocused);

      Transforms.setNodes(
        editor,
        { [`_${mark.questionId}`]: false, isFocused: false },
        { match: (n) => Text.isText(n), at: path },
      );
    });
  };

  const onMouseEnter = (mark: QuestionMarksGrouped) => () => {
    mark.nodesPaths.forEach((path) => {
      const domNode = findDomNode(path);
      domNode.classList.add(styles.questionElementFocused);
    });
  };

  const onMouseLeave = (mark: QuestionMarksGrouped) => () => {
    mark.nodesPaths.forEach((path) => {
      const domNode = findDomNode(path);
      domNode.classList.remove(styles.questionElementFocused);
    });
  };

  const [wrapWidth, setWrapWidth] = useState(0);

  useEffect(() => {
    const handler = () => {
      setWrapWidth(wrapRef.current ? getElementOffsetPosition(wrapRef.current).width : 0);
    };

    window.addEventListener('resize', handler);

    return () => window.removeEventListener('resize', handler);
  }, [setWrapWidth]);

  useEffect(() => {
    if (wrapWidth === 0) {
      setWrapWidth(wrapRef.current ? getElementOffsetPosition(wrapRef.current).width : 0);
    }
  }, [wrapWidth, setWrapWidth]);
  const getColor = (questionId: string, alpha?: number) => {
    const question = flatQuestions.find((q) => `${q.id}` === questionId);

    if (question) {
      return transformColor(question.color, alpha);
    }
  };

  return (
    <div className={styles.questionMarks} ref={wrapRef}>
      {withCoords.map((mark, key) => (
        <div
          className={styles.questionMarkItem}
          key={key}
          style={{
            backgroundColor: getColor(mark.questionId, 0.15),
            color: getColor(mark.questionId),
            top: mark.top,
          }}
          onMouseEnter={onMouseEnter(mark)}
          onMouseLeave={onMouseLeave(mark)}
        >
          {mark.question.name}
          <CloseOutlined className={styles.questionMarkCloseIcon} onClick={onClick(mark)} />
        </div>
      ))}
    </div>
  );
}

