import React from 'react';
import { Navigate } from 'react-router-dom';

import { routes } from 'api/routes';

import { useUser } from 'hooks/user';

export interface PublicOnlyRouteProps {
  element: JSX.Element;
}

export function PublicOnlyRoute({ element }: PublicOnlyRouteProps) {
  const { user } = useUser();

  if (user) {
    return <Navigate to={routes.home()} replace={true} />;
  }

  return element;
}

