import React from 'react';

import { ColumnsType } from 'shared/table';
import { Text } from 'shared/typography';

import { BillingRow } from 'models/billing';

export const columns: ColumnsType<BillingRow> = [
  {
    title: 'Дата и время',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
  {
    title: 'Комментарий',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Сумма пополнения',
    dataIndex: 'amount',
    key: 'amount',
    render: (text) => <Text type='success'>{text}</Text>,
  },
];

