import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { storeHelper } from 'helpers/store';

import { Company } from 'models/company';

import { RootState } from 'store/store';

interface State {
  selectedCompanies: Company[];
}

const initialState: State = {
  selectedCompanies: [],
};

const { setAction } = storeHelper<State>();

export const marketplaceSlice = createSlice({
  name: 'marketplace',
  initialState,
  reducers: {
    setSelectedCompanies: setAction('selectedCompanies'),
    addSelectedCompany: (state, { payload: company }: PayloadAction<Company>) => {
      if (!state.selectedCompanies.find(({ id }) => id === company.id)) {
        state.selectedCompanies.push(company);
      }
    },
    removeSelectedCompany: (state, { payload: company }: PayloadAction<Company>) => {
      state.selectedCompanies = state.selectedCompanies.filter(({ id }) => id !== company.id);
    },
  },
});

export const marketplaceActions = marketplaceSlice.actions;

export const marketplaceSelector = (state: RootState): State => state.marketplace;

