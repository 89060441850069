import { LockOutlined } from '@ant-design/icons';
import cn from 'classnames';
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { routes } from 'api/routes';

import { Button } from 'shared/button';
import { Form, FormItem } from 'shared/form';
import { InputPassword } from 'shared/input';
import { Title, Paragraph } from 'shared/typography';

import { AuthLayout } from 'components/auth-layout';

import { getResponseError } from 'helpers/response';
import { validateRules } from 'helpers/validate-rules';

import { useDocumentTitle } from 'hooks/document-title';

import styles from './reset-password.page.module.scss';
import { ResetPasswordRequest, useResetPasswordMutation } from './reset-password.service';

export interface ResetPasswordPageProps {
  className?: string;
}

export function ResetPasswordPage({ className }: ResetPasswordPageProps) {
  const classes = cn(styles.ResetPasswordPage, className);
  useDocumentTitle('Восстановить пароль');
  const navigate = useNavigate();
  const [getSearchParams] = useSearchParams();
  const [forgotPassword, { isLoading }] = useResetPasswordMutation();
  const [error, setError] = useState<null | string>(null);

  const onFinish = async (values: Pick<ResetPasswordRequest, 'password'>) => {
    setError(null);
    const requestValues: ResetPasswordRequest = {
      ...values,
      email: getSearchParams.get('email') || '',
      token: getSearchParams.get('token') || '',
    };
    try {
      await forgotPassword(requestValues).unwrap();
      navigate(routes.home());
    } catch (error) {
      setError(getResponseError(error));
    }
  };

  return (
    <AuthLayout className={classes} hideHeaderLinks={true}>
      <Title>Восстановить пароль</Title>
      <Paragraph>Введите новый пароль</Paragraph>
      <Form
        onFinish={onFinish}
        autoComplete='off'
        error={error}
        actionSlot={
          <Button htmlType='submit' loading={isLoading}>
            Обновить пароль
          </Button>
        }
      >
        <FormItem name='password' rules={[validateRules.required('пароль')]}>
          <InputPassword addonBefore={<LockOutlined />} />
        </FormItem>
      </Form>
    </AuthLayout>
  );
}

