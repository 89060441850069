import { Spin as AntdSpin } from 'antd';
import { SpinProps as AntdSpinProps } from 'antd/lib/spin';
import cn from 'classnames';
import React from 'react';

import styles from './spin.module.scss';

export interface SpinProps extends AntdSpinProps {
  className?: string;
}

export function Spin({ className, size = 'large', ...props }: SpinProps) {
  const classes = cn(styles.spin, className);

  return <AntdSpin className={classes} size={size} {...props} />;
}

