import cn from 'classnames';
import React from 'react';

import { PageHeader } from 'shared/page-header';
import { Tabs } from 'shared/tabs';

import { PageLayout } from 'components/page-layout';

import { useDocumentTitle } from 'hooks/document-title';

import styles from './billing.page.module.scss';
import { Files } from './components/files';
import { Payments } from './components/payments';

export interface BillingPageProps {
  className?: string;
}

export function BillingPage({ className }: BillingPageProps) {
  const classes = cn(styles.billingPage, className);
  useDocumentTitle('Детализация оплат и списаний');

  return (
    <PageLayout className={classes}>
      <PageHeader title={'Детализация оплат и списаний'}>
        <Tabs
          items={[
            {
              label: 'Оплаты',
              key: 'payments',
              children: <Payments />,
            },
            {
              label: 'Списания',
              key: 'files',
              children: <Files />,
            },
          ]}
        />
      </PageHeader>
    </PageLayout>
  );
}

