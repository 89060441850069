import { paths } from 'api/paths';

import { NewProject, Project, ProjectRow } from 'models/project';
import { SuccessResponse } from 'models/response';

import { api } from 'store/service';

export const projectsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query<ProjectRow[], void>({
      query: () => ({
        url: paths.projects(),
      }),
      transformResponse: (response: SuccessResponse<ProjectRow[]>) => response.data,
      providesTags: ['Projects'],
    }),
    removeProjects: builder.mutation<ProjectRow[], ProjectRow['id'][]>({
      query: (credentials) => ({
        url: paths.projects(),
        method: 'DELETE',
        body: { id: credentials },
      }),
      invalidatesTags: (result) => (result ? ['Projects'] : []),
    }),
    addProject: builder.mutation<Project, NewProject>({
      query: (credentials) => ({
        url: paths.projects(),
        method: 'POST',
        body: credentials,
      }),
      transformResponse: (response: SuccessResponse<Project>) => response.data,
      invalidatesTags: (result) => (result ? ['Projects', 'Project'] : []),
    }),
  }),
  overrideExisting: false,
});

export const { useGetProjectsQuery, useRemoveProjectsMutation, useAddProjectMutation } = projectsApi;

