import { LeftOutlined } from '@ant-design/icons';
import cn from 'classnames';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Avatar } from 'shared/avatar';
import { Title } from 'shared/typography';

import { EditableImage } from 'components/editable-image';

import { Image, ImageRelatedType } from 'models/image';

import styles from './editable-title.module.scss';

export interface EditableTitleProps {
  className?: string;
  isLoading: boolean;
  to?: string;
  image?: Image | null;
  imageType?: ImageRelatedType;
  onAddImage?: (image: Image) => void;
  onRemoveImage?: () => void;
  title?: string;
  onChangeTitle?: (value: string) => void;
  actionSlot?: ReactNode;
  size?: 'small';
}

export function EditableTitle({
  className,
  isLoading,
  to,
  image,
  imageType = 'project',
  onAddImage,
  onRemoveImage,
  title,
  onChangeTitle,
  actionSlot,
  size,
}: EditableTitleProps) {
  const classes = cn(styles.editableTitle, className);

  return (
    <div className={classes}>
      {to && (
        <Link className={styles.backLink} to={to}>
          <LeftOutlined />
        </Link>
      )}
      {onAddImage && onRemoveImage ? (
        <EditableImage
          className={styles.image}
          isLoading={isLoading}
          image={image}
          type={imageType}
          onAdd={onAddImage}
          onRemove={onRemoveImage}
          size={size}
        />
      ) : (
        image && <Avatar className={styles.image} src={image?.url} size={size === 'small' ? 48 : undefined} />
      )}
      <Title
        className={cn(styles.title, {
          [styles.title_editable]: Boolean(onChangeTitle),
        })}
        level={size === 'small' ? 4 : 3}
        editable={
          onChangeTitle
            ? {
                triggerType: ['text'],
                onChange: (value) => {
                  if (value !== title) {
                    onChangeTitle(value);
                  }
                },
              }
            : undefined
        }
      >
        {title}
      </Title>
      {actionSlot && <div>{actionSlot}</div>}
    </div>
  );
}

