import { Input as AntdInput } from 'antd';
import { TextAreaProps as AntdTextAreaProps } from 'antd/lib/input';
import cn from 'classnames';
import React from 'react';

import styles from './input.module.scss';

export interface TextareaProps extends AntdTextAreaProps {
  className?: string;
}

export function Textarea({ className, size = 'large', ...props }: TextareaProps) {
  const classes = cn(styles.inputPassword, className);

  return <AntdInput.TextArea className={classes} size={size} {...props} />;
}

