import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import styles from './logo.module.scss';
import logo from './logo.svg';

export interface LogoProps {
  className?: string;
  to?: string;
}

export function Logo({ className, to }: LogoProps) {
  const classes = cn(styles.logo, className);

  if (to) {
    return (
      <Link to={to}>
        <LogoImage className={classes} />
      </Link>
    );
  }

  return <LogoImage className={classes} />;
}

export interface LogoImageProps {
  className?: string;
}

function LogoImage({ className }: LogoImageProps) {
  return <img className={className} src={logo} alt='Qulap' />;
}

