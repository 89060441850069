import { DownloadOutlined, DownOutlined, ShareAltOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import React from 'react';

import { Button } from 'shared/button';
import { Dropdown } from 'shared/dropdown';
import { Menu, MenuProps } from 'shared/menu';
import { Space } from 'shared/space';

import { getResponseError } from 'helpers/response';

import { Project } from 'models/project';

import { useLazyDownloadReportQuery, useShareReportMutation } from './report.service';

export interface ReportActionsProps {
  projectId: Project['id'];
  projectName?: Project['name'];
  isLoading: boolean;
  onSave: () => Promise<void>;
  isSaving: boolean;
}

export function ReportActions({ projectId, projectName, isLoading, onSave, isSaving }: ReportActionsProps) {
  const [shareReport, { isLoading: isSharing }] = useShareReportMutation();
  const [downloadReport, { isLoading: isDownloading }] = useLazyDownloadReportQuery();

  const onShareReport = async () => {
    try {
      const url = await shareReport(projectId).unwrap();
      await navigator.clipboard.writeText(url);
      notification.success({
        message: 'Ссылка скопирована',
      });
    } catch (error) {
      notification.error({
        message: getResponseError(error),
      });
    }
  };

  const handleMenuClick: MenuProps['onClick'] = async (event) => {
    switch (event.key) {
      case 'download': {
        try {
          await downloadReport({ projectId, projectName }).unwrap();
        } catch (error) {
          notification.error({
            message: getResponseError(error),
          });
        }
        return;
      }
      case 'share':
        return onShareReport();
    }
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          label: 'Выгрузить в xlsx',
          key: 'download',
          icon: <DownloadOutlined />,
          disabled: isDownloading,
        },
        {
          label: 'Скопировать ссылку',
          key: 'share',
          icon: <ShareAltOutlined />,
          disabled: isSharing,
        },
      ]}
    />
  );

  return (
    <Space>
      <Dropdown overlay={menu} placement='bottomRight' arrow={true}>
        <Button data-onboarding='report-share' type='default'>
          Поделиться <DownOutlined />
        </Button>
      </Dropdown>
      <Button onClick={onSave} loading={isSaving} disabled={isLoading}>
        Сохранить
      </Button>
    </Space>
  );
}

