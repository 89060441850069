import { Modal as AntdModal } from 'antd';
import { ModalProps as AntdModalProps, ModalFuncProps } from 'antd/lib/modal';
import cn from 'classnames';
import React from 'react';

import { DEFAULT_BUTTON_SIZE } from 'shared/button';

import styles from './modal.module.scss';

export interface ModalProps extends AntdModalProps {
  className?: string;
}

export function Modal({ className, centered = true, ...props }: ModalProps) {
  const classes = cn(styles.Modal, className);

  return <AntdModal className={classes} centered={centered} {...props} />;
}
export interface ModalErrorProps extends ModalFuncProps {
  className?: string;
}

export function modalError({ className, centered = true, icon = <></>, okButtonProps, ...props }: ModalErrorProps) {
  const classes = cn(styles.modal, className);

  AntdModal.error({
    className: classes,
    centered,
    icon,
    okButtonProps: { size: DEFAULT_BUTTON_SIZE, ...okButtonProps },
    ...props,
  });
}

export interface ModalConfirmProps extends ModalFuncProps {
  className?: string;
}

export function modalConfirm({
  className,
  centered = true,
  icon = <></>,
  okButtonProps,
  cancelButtonProps,
  closable = true,
  maskClosable = true,
  ...props
}: ModalConfirmProps) {
  const classes = cn(styles.modal, className);

  AntdModal.confirm({
    className: classes,
    centered,
    icon,
    okButtonProps: { size: DEFAULT_BUTTON_SIZE, ...okButtonProps },
    cancelButtonProps: { size: DEFAULT_BUTTON_SIZE, ...cancelButtonProps },
    closable,
    maskClosable,
    ...props,
  });
}

