import { Form as AntdForm } from 'antd';
import { FormProps as AntdFormProps } from 'antd/lib/form';
import cn from 'classnames';
import React, { ReactNode } from 'react';

import { Alert } from 'shared/alert';

import { FormItem } from './form.item';
import styles from './form.module.scss';

export interface FormProps extends AntdFormProps {
  className?: string;
  error?: string | null;
  success?: string | null;
  children: ReactNode;
  actionSlot?: ReactNode;
}

export function Form({ className, error, success, children, actionSlot, layout = 'vertical', ...props }: FormProps) {
  const classes = cn(styles.form, className);

  return (
    <AntdForm className={classes} layout={layout} {...props}>
      {children}
      {success && (
        <FormItem>
          <Alert message={success} type='success' />
        </FormItem>
      )}
      {error && (
        <FormItem>
          <Alert message={error} type='error' />
        </FormItem>
      )}
      {actionSlot}
    </AntdForm>
  );
}

export const useForm = AntdForm.useForm;

export const useWatch = AntdForm.useWatch;

