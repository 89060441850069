import { paths } from 'api/paths';

import { NewInterview, Interview, InterviewRow } from 'models/interview';
import { Project } from 'models/project';
import { SuccessResponse } from 'models/response';

import { api } from 'store/service';

export const interviewsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getInterviews: builder.query<InterviewRow[], Project['id']>({
      query: (projectId) => ({
        url: paths.interviews(projectId),
      }),
      transformResponse: (response: SuccessResponse<InterviewRow[]>) => response.data,
      providesTags: ['Interviews'],
    }),
    removeInterviews: builder.mutation<InterviewRow[], { projectId: Project['id']; id: InterviewRow['id'][] }>({
      query: ({ projectId, id }) => ({
        url: paths.interviews(projectId),
        method: 'DELETE',
        body: { id },
      }),
      invalidatesTags: (result) => (result ? ['Interviews'] : []),
    }),
    addInterview: builder.mutation<Interview, { projectId: Project['id']; interview: NewInterview }>({
      query: ({ projectId, interview }) => ({
        url: paths.interviews(projectId),
        method: 'POST',
        body: interview,
      }),
      transformResponse: (response: SuccessResponse<Interview>) => response.data,
      invalidatesTags: (result) => (result ? ['Interviews', 'Interview'] : []),
    }),
  }),
  overrideExisting: false,
});

export const { useGetInterviewsQuery, useRemoveInterviewsMutation, useAddInterviewMutation } = interviewsApi;

