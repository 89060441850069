import { Popover as AntdPopover } from 'antd';
import { PopoverProps as AntdPopoverProps } from 'antd/lib/popover';
import cn from 'classnames';
import React from 'react';

import styles from './popover.module.scss';

export interface PopoverProps extends AntdPopoverProps {
  className?: string;
  maxWidth?: number;
}

export function Popover({ className, maxWidth, zIndex = 1100, ...props }: PopoverProps) {
  const classes = cn(styles.popover, className);

  return <AntdPopover className={classes} overlayStyle={{ maxWidth }} zIndex={zIndex} {...props} />;
}

