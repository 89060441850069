import { List as AntdList } from 'antd';
import { ListProps as AntdListProps } from 'antd/lib/list';
import cn from 'classnames';
import React from 'react';

import styles from './list.module.scss';

export interface ListProps<T> extends AntdListProps<T> {
  className?: string;
}

export function List<T>({ className, ...props }: ListProps<T>) {
  const classes = cn(styles.List, className);

  return <AntdList className={classes} {...props} />;
}

