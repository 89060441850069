import { QuestionCircleTwoTone } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { Button } from 'shared/button';
import { List, ListItem, ListItemMeta } from 'shared/list';
import { Modal } from 'shared/modal';
import { Title } from 'shared/typography';

import { useModal } from 'hooks/modal';

import { Tariff } from 'models/tariff';

export interface TariffModalProps {
  tariff: Tariff;
  hideText?: boolean;
}

export function TariffModal({ tariff, hideText }: TariffModalProps) {
  const { open, toggleModal } = useModal();

  const dataForAuto = useMemo(
    () => [
      {
        title: tariff.auto.name,
        description: `${tariff.auto.price / 60} рублей за минуту. Неполная минута округляется в большую сторону.`,
      },
    ],
    [tariff],
  );

  const dataForManual = useMemo(
    () => [
      {
        title: tariff.manual.priority_4.name,
        description: `${
          tariff.manual.priority_4.price
        } рублей за первый час (материалы менее часа округляются до часа). ${
          tariff.manual.priority_4.price / 6
        } рублей за каждые последующие 10  минут (неполные 10 минут округляются в большую сторону)`,
      },
      {
        title: tariff.manual.priority_3.name,
        description: `${
          tariff.manual.priority_3.price
        } рублей за первый час (материалы менее часа округляются до часа). ${
          tariff.manual.priority_3.price / 6
        } рублей за каждые последующие 10  минут (неполные 10 минут округляются в большую сторону)`,
      },
      {
        title: tariff.manual.priority_2.name,
        description: `${
          tariff.manual.priority_2.price
        } рублей за первый час (материалы менее часа округляются до часа). ${
          tariff.manual.priority_2.price / 6
        } рублей за каждые последующие 10  минут (неполные 10 минут округляются в большую сторону)`,
      },
      {
        title: tariff.manual.priority_1.name,
        description: `${
          tariff.manual.priority_1.price
        } рублей за первый час (материалы менее часа округляются до часа). ${
          tariff.manual.priority_1.price / 6
        } рублей за каждые последующие 10  минут (неполные 10 минут округляются в большую сторону)`,
      },
    ],
    [tariff],
  );

  return (
    <>
      <Button type='link' size='small' icon={<QuestionCircleTwoTone />} onClick={() => toggleModal()}>
        {!hideText && 'Подробнее про стоимость транскрибации'}
      </Button>
      <Modal
        width={560}
        open={open}
        okButtonProps={{ hidden: true }}
        destroyOnClose={true}
        title='Стоимость транскрибаций'
        onCancel={toggleModal}
        cancelText={'Закрыть'}
      >
        <Title level={5}>Автоматическая транскрибация</Title>
        <List
          dataSource={dataForAuto}
          renderItem={(item) => (
            <ListItem>
              <ListItemMeta title={item.title} description={item.description} />
            </ListItem>
          )}
        ></List>
        <Title level={5}>Ручная транскрибация</Title>
        <List
          dataSource={dataForManual}
          renderItem={(item) => (
            <ListItem>
              <ListItemMeta title={item.title} description={item.description} />
            </ListItem>
          )}
        ></List>
      </Modal>
    </>
  );
}

