import { Tabs as AntdTabs } from 'antd';
import { TabsProps as AntdTabsProps } from 'antd/lib/tabs';
import cn from 'classnames';
import React from 'react';

import styles from './tabs.module.scss';

export interface TabsProps extends AntdTabsProps {
  className?: string;
}

export function Tabs({ className, size = 'large', ...props }: TabsProps) {
  const classes = cn(styles.tabs, className);

  return <AntdTabs className={classes} size={size} {...props} />;
}

