import { notification } from 'antd';
import React, { useState } from 'react';

import { Button } from 'shared/button';
import { Space } from 'shared/space';

import { Hint } from 'components/hint';

import { getResponseError } from 'helpers/response';

import { useFileDialog } from 'hooks/file-dialog';
import { useModal } from 'hooks/modal';
import { useAppSelector } from 'hooks/store';

import { Interview } from 'models/interview';

import { useAddCloudLinkMutation, useAddFileMutation } from 'store/services/file.service';
import { uploadProgressSelector } from 'store/slices/file.slice';

import { InterviewAddFile } from './interview.add-file';

export interface InterviewActionsProps {
  disabled: boolean;
  interviewId?: Interview['id'];
}

export function InterviewActions({ disabled, interviewId }: InterviewActionsProps) {
  const [isCloudChosen, setIsCloudChosen] = useState(false);

  const { open, toggleModal } = useModal();
  const [addFile, { isLoading: isFileAdding }] = useAddFileMutation();
  const [addCloudLink, { isLoading: isLinkAdding }] = useAddCloudLinkMutation();
  const { uploadProgress } = useAppSelector(uploadProgressSelector);

  const onAddFileClick = () => {
    toggleModal();
  };

  const onCloudUpload = async (link: string) => {
    if (!interviewId) {
      return;
    }
    toggleModal();

    try {
      await addCloudLink({
        type: 'interviews',
        id: interviewId,
        link,
        wasInvalidateTags: true,
        isTranscription: true,
      }).unwrap();
    } catch (error) {
      notification.error({
        message: getResponseError(error),
      });
    }
  };

  const uploadFile = async (file: File) => {
    if (!interviewId) {
      return;
    }
    toggleModal();

    try {
      await addFile({
        type: 'interviews',
        id: interviewId,
        file,
        wasInvalidateTags: true,
        isTranscription: true,
      }).unwrap();
    } catch (error) {
      notification.error({
        message: getResponseError(error),
      });
    }
  };

  const { openFileDialog } = useFileDialog(uploadFile, '.wav, .mp3, .ogg, .m4a, .mp4, .avi, .mpeg, .webm, .mov');

  return (
    <Space size='small'>
      <InterviewAddFile
        open={open}
        toggleModal={toggleModal}
        openFileDialog={openFileDialog}
        onCloudUpload={onCloudUpload}
        isCloudChosen={isCloudChosen}
        setIsCloudChosen={(chosen) => setIsCloudChosen(chosen)}
      />
      <Hint content='Файлы до 1ГБ. Аудио: wav, mp3, ogg, m4a. Видео: mp4, avi, mpeg, webm, mov.' />
      <Button
        data-onboarding='interview-upload'
        type='default'
        onClick={onAddFileClick}
        loading={isFileAdding || isLinkAdding}
        disabled={disabled}
        title={disabled ? 'Нельзя загружать больше одного транскрипта' : undefined}
      >
        {isFileAdding && <span>{uploadProgress}% </span>}
        Добавить файл для транскрибации
      </Button>
    </Space>
  );
}

