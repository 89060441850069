import { CheckOutlined, UploadOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import cn from 'classnames';
import React from 'react';

import { modalConfirm } from 'shared/modal';

import { noop } from 'helpers/noop';

import { Image } from 'models/image';

import styles from './image-gallery.module.scss';

export interface ImageGalleryProps {
  className?: string;
  items?: Image[];
  onAdd: (file: File) => void;
  onSelect: (image: Image) => void;
  onRemove: (image: Image) => void;
}

export function ImageGallery({ className, items, onAdd, onSelect, onRemove: onRemoveProp }: ImageGalleryProps) {
  const classes = cn(styles.imageGallery, className);

  const onChange: UploadProps<File>['onChange'] = ({ file }) => {
    if (file.status === 'uploading') {
      onAdd(file.originFileObj as File);
    }
  };

  const onPreview: UploadProps<File>['onPreview'] = (file) => {
    onSelect(formatUploadFileToImage(file));
  };

  const onRemove: UploadProps<File>['onRemove'] = (file) => {
    const image = formatUploadFileToImage(file);
    modalConfirm({
      title: 'Вы уверены, что хотите удалить изображение?',
      okText: 'Удалить',
      okButtonProps: {
        danger: true,
      },
      onOk: () => onRemoveProp(image),
    });
  };

  return (
    <Upload
      className={classes}
      accept='.png, .jpg, .jpeg'
      listType='picture-card'
      customRequest={noop}
      fileList={items ? items.map(formatImageToUploadFile) : []}
      showUploadList={{
        previewIcon: <CheckOutlined />,
      }}
      onChange={onChange}
      onPreview={onPreview}
      onRemove={onRemove}
    >
      <div className={styles.upload}>
        <UploadOutlined className={styles.upload__icon} />
        <div className={styles.upload__text}>Загрузить</div>
      </div>
    </Upload>
  );
}

function formatImageToUploadFile({ id, url, common }: Image): UploadFile {
  return {
    uid: String(id),
    name: url,
    url,
    status: common ? 'success' : undefined, // Чтобы скрыть кнопку удаления
  };
}

function formatUploadFileToImage({ uid, url, status }: UploadFile): Image {
  return {
    id: Number(uid),
    url: url ?? '',
    common: status === 'success',
  };
}

