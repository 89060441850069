import { Typography } from 'antd';
import { LinkProps as AntdLinkProps } from 'antd/lib/typography/Link';
import { ParagraphProps as AntdParagraphProps } from 'antd/lib/typography/Paragraph';
import { TextProps as AntdTextProps } from 'antd/lib/typography/Text';
import { TitleProps as AntdTitleProps } from 'antd/lib/typography/Title';
import cn from 'classnames';
import React from 'react';

import styles from './typography.module.scss';

export interface TitleProps extends AntdTitleProps {
  className?: string;
}

export function Title({ className, ...props }: TitleProps) {
  const classes = cn(styles.title, className);

  return <Typography.Title className={classes} {...props} />;
}

export interface ParagraphProps extends AntdParagraphProps {
  className?: string;
  size?: 'small' | 'middle' | 'large';
}

export function Paragraph({ className, size, ...props }: ParagraphProps) {
  const classes = cn(styles.paragraph, className, {
    [styles.paragraph_small]: size === 'small',
    [styles.paragraph_middle]: size === 'middle',
    [styles.paragraph_large]: size === 'large',
  });

  return <Typography.Paragraph className={classes} {...props} />;
}

export interface TextProps extends AntdTextProps {
  className?: string;
}

export function Text({ className, ...props }: TextProps) {
  const classes = cn(styles.text, className);

  return <Typography.Text className={classes} {...props} />;
}

export interface LinkProps extends AntdLinkProps {
  className?: string;
}

export function Link({ className, ...props }: LinkProps) {
  const classes = cn(styles.link, className);

  return <Typography.Link className={classes} {...props} />;
}

