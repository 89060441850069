import React from 'react';

import { Avatar } from 'shared/avatar';
import { Textarea } from 'shared/input';
import { ColumnsType } from 'shared/table';
import { Paragraph } from 'shared/typography';

import { transformColor } from 'models/color';
import { ReportRespondent, ReportQuestion, ReportTableRow } from 'models/report';

import styles from './report.page.module.scss';

export function getColumns(
  respondents: ReportRespondent[] = [],
  onChangeConclusion?: (id: ReportQuestion['id'], value: ReportQuestion['conclusion']) => void,
): ColumnsType<ReportTableRow> {
  return [
    {
      title: 'Вопросы',
      dataIndex: 'question',
      key: 'question',
      render: (text, record) => (
        <>
          <div className={styles.question_color} style={{ background: transformColor(record.question.color) }} />
          {record.question.number}. {record.question.name}
        </>
      ),
      width: 200,
      fixed: 'left',
    },
    ...respondents.map((respondent, index) => ({
      title: (
        <>
          {respondent.image && <Avatar className={styles.table_avatar} size='small' src={respondent.image.url} />}{' '}
          {respondent.name}
        </>
      ),
      dataIndex: index,
      key: index,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (_: any, record: any) =>
        (record as ReportTableRow)[index]?.answers?.map((answer, index) => (
          <Paragraph className={styles.table_answer} key={index}>
            {answer}
          </Paragraph>
        )),
      width: 250,
    })),
    {
      title: <div data-onboarding='report-conclusion'>Выводы</div>,
      dataIndex: 'conclusion',
      key: 'conclusion',
      render: (text, record) =>
        onChangeConclusion ? (
          <Textarea
            data-onboarding='report-conclusion-textarea'
            value={text}
            onChange={(event) => onChangeConclusion(record.id, event.target.value)}
            className={styles.table_conclusion}
            autoSize={true}
            bordered={false}
            size='middle'
            placeholder='Введите текст...'
          />
        ) : (
          text
        ),
      width: 250,
    },
  ];
}

