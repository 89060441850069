import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import { interviewsSlice } from 'features/interviews/interviews.slice';
import { marketplaceSlice } from 'features/marketplace/marketplace.slice';
import { projectsSlice } from 'features/projects/projects.slice';

import { api } from './service';
import { fileSlice } from './slices/file.slice';
import { userSlice } from './slices/user.slice';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    projects: projectsSlice.reducer,
    interviews: interviewsSlice.reducer,
    user: userSlice.reducer,
    marketplace: marketplaceSlice.reducer,
    file: fileSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
