import cn from 'classnames';
import React from 'react';

import { Modal } from 'shared/modal';

import { WithModalProps } from 'hooks/modal';

import { Image, ImageRelatedType } from 'models/image';

import { SelectImageGallery } from './select-image.gallery';
import styles from './select-image.module.scss';

export interface SelectImageProps extends WithModalProps {
  className?: string;
  type: ImageRelatedType;
  onSelect: (image: Image) => void;
}

export function SelectImage({ className, type, onSelect, open, toggleModal }: SelectImageProps) {
  const classes = cn(styles.SelectImage, className);

  const handleSelect = (image: Image) => {
    toggleModal();
    onSelect(image);
  };

  return (
    <Modal
      className={classes}
      width={752}
      open={open}
      title='Выберите из имеющихся или загрузите изображение'
      onCancel={toggleModal}
      okButtonProps={{ hidden: true }}
      destroyOnClose={true}
    >
      <SelectImageGallery type={type} onSelect={handleSelect} />
    </Modal>
  );
}

