import mix from 'mix-css-color';
import { Descendant, Editor, Element as SlateElement, Transforms } from 'slate';

import { Question } from 'models/question';

import { CustomEditor, CustomElement, CustomText, QuestionsOnPath } from './editor.types';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

export function isMarkActive(editor: CustomEditor, format: keyof Omit<CustomText, 'text'>) {
  const marks = Editor.marks(editor);

  return marks ? marks[format] === true : false;
}

export function toggleMark(editor: CustomEditor, format: keyof Omit<CustomText, 'text'>) {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
}

export function toggleBlock(editor: CustomEditor, format: CustomElement['type']) {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type),
    split: true,
  });
  const newProperties: Partial<CustomElement> = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  };
  Transforms.setNodes<CustomElement>(editor, newProperties);

  if (!isActive && isList) {
    const block: CustomElement = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
}

export function isBlockActive(editor: CustomEditor, format: CustomElement['type']) {
  const { selection } = editor;
  if (!selection) {
    return false;
  }

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n['type'] === format,
    }),
  );

  return Boolean(match);
}

export function getElementOffsetPosition(element: Element) {
  const { top, height, width } = element.getBoundingClientRect();

  return { top: top + window.pageYOffset, height, width };
}

export function setPath(nodes: Descendant[], question: Question[]) {
  const questions: QuestionsOnPath[] = [];

  const questionIds = getFlatProjectQuestions(question).map((q) => q.id);

  nodes.map((root) => {
    return {
      ...root,
      children: (root as CustomElement).children.map((it, iterItemN: number) => {
        return {
          ...it,
          children: (it as CustomElement).children?.map((leaf, textFragmentN: number) => {
            const tags = Object.entries(leaf)
              .filter(([key, val]) => {
                return key.includes('_') && val && questionIds.includes(parseInt(key.slice(1)));
              })
              .map(([key]) => key.slice(1));

            if (tags.length > 0) {
              questions.push({
                questions: tags,
                path: [0, iterItemN, textFragmentN],
              });
            }

            return {
              ...leaf,
            };
          }),
        };
      }),
    };
  });

  return questions;
}

export function createRandomColor() {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
}

export function mixColors(colors: string[]) {
  if (colors.length < 1) {
    return 'inherit';
  }

  return colors.slice(1).reduce<string>((acc, value) => {
    const mixed = mix(acc, value);
    return mixed.hexa;
  }, colors[0]);
}

export function getFlatProjectQuestions(questions: Question[]) {
  const flatQuestions = questions.reduce((acc: Question[], question) => {
    acc.push(question);

    if (question.questions?.length) {
      question.questions.forEach((q) => acc.push(q));
    }

    return acc;
  }, []);

  return flatQuestions;
}

