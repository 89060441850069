import { setTwoToneColor } from '@ant-design/icons';
import { ConfigProvider, notification } from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { App } from 'app';

import { AuthMiddleware } from 'components/auth-middleware';

import { store } from 'store/store';

import './index.less';
import reportWebVitals from './reportWebVitals';

notification.config({
  placement: 'bottomRight',
  bottom: 8,
});

setTwoToneColor('#5452FB');

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ConfigProvider locale={ruRU}>
      <Provider store={store}>
        <AuthMiddleware>
          <App />
        </AuthMiddleware>
      </Provider>
    </ConfigProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
