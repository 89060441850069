import { Button as AntdButton } from 'antd';
import { ButtonProps as AntdButtonProps } from 'antd/lib/button';
import cn from 'classnames';
import React from 'react';

import styles from './button.module.scss';

export interface ButtonProps extends AntdButtonProps {
  className?: string;
}

export function Button({ className, type = 'primary', size = DEFAULT_BUTTON_SIZE, ...props }: ButtonProps) {
  const classes = cn(styles.button, className);

  return <AntdButton className={classes} type={type} size={size} {...props} />;
}

export const DEFAULT_BUTTON_SIZE = 'large';

