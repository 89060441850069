import { StepType } from '@reactour/tour';
import React from 'react';

import { Paragraph } from 'shared/typography';

export const projectsPageSteps: StepType[] = [
  {
    selector: '[data-onboarding="app"]',
    content: (
      <>
        <Paragraph>Приветствую вас.</Paragraph>
        <Paragraph>Я проведу экскурсию по платформе Qulap.</Paragraph>
      </>
    ),
  },
  {
    selector: '[data-onboarding="projects"]',
    content: (
      <>
        <Paragraph>Здесь будут храниться все ваши исследования.</Paragraph>
      </>
    ),
  },
  {
    selector: '[data-onboarding="projects"]',
    content: (
      <>
        <Paragraph>Мы уже создали одно, кликните на его название, чтобы посмотреть как там все устроено.</Paragraph>
      </>
    ),
    disableActions: true,
    mutationObservables: ['[data-onboarding="projects"] .ant-table-row'],
    highlightedSelectors: ['[data-onboarding="projects"] .ant-table-row'],
  },
];

export const projectPageSteps: StepType[] = [
  {
    selector: '[data-onboarding="app"]',
    content: (
      <>
        <Paragraph>Вы находитесь на странице описания вашего исследования.</Paragraph>
      </>
    ),
  },
  {
    selector: '[data-onboarding="project-box"]',
    content: (
      <>
        <Paragraph>
          Опишите здесь всю информацию о данном исследовании: заполненный бриф, скрининг респондентов, важные моменты,
          которые нужно не забыть.
        </Paragraph>
      </>
    ),
  },
  {
    selector: '[data-onboarding="project-save"]',
    content: (
      <>
        <Paragraph>Не забывайте сохранять свои действия нажатием на кнопку «Сохранить».</Paragraph>
      </>
    ),
    position: 'left',
  },
  {
    selector: '[data-onboarding="project-additional-box"]',
    content: (
      <>
        <Paragraph>Заполните Гайд интервью вашими вопросами, которые зададите респонденту.</Paragraph>
        <Paragraph>
          Чуть позже, я покажу как с помощью этих вопросов вы будете тегировать (кодировать) транскрипт интервью и
          получите отчет вашего исследования.
        </Paragraph>
      </>
    ),
  },
  {
    selector: '[data-onboarding="sidebar-menu-interview"]',
    content: (
      <>
        <Paragraph>
          После того, как вся информация по отчету будет заполнена, можете переходить на страницу интервью, чтобы
          приступить к самому интересному.
        </Paragraph>
      </>
    ),
    disableActions: true,
  },
];

export const interviewsPageSteps: StepType[] = [
  {
    selector: '[data-onboarding="app"]',
    content: (
      <>
        <Paragraph>
          Здесь вы создаете интервью. Называйте их как привыкли и устанавливайте иконки из нашей библиотеки, чтобы
          быстрее ориентироваться в списке.
        </Paragraph>
      </>
    ),
  },
  {
    selector: '[data-onboarding="interviews"]',
    content: (
      <>
        <Paragraph>Мы уже провели и создали несколько интервью, перейдите в любое из них.</Paragraph>
      </>
    ),
    disableActions: true,
  },
];

export const interviewPageSteps: StepType[] = [
  {
    selector: '[data-onboarding="app"]',
    content: (
      <>
        <Paragraph>Здесь хранится вся информация об интервью с респондентом.</Paragraph>
      </>
    ),
  },
  {
    selector: '[data-onboarding="interview-upload"]',
    content: (
      <>
        <Paragraph>
          Вы можете загрузить аудиозапись интервью и получить его транскрипт. Мы делаем автоматическую и ручную
          транскрибацию материалов
        </Paragraph>
      </>
    ),
  },
  {
    selector: '[data-onboarding="editor"]',
    content: (
      <>
        <Paragraph>В редакторе вы сможете тегировать (кодировать) основные моменты в интервью.</Paragraph>
        <Paragraph>Выделите часть текста и в появившемся меню выберите вопрос из гайда интервью.</Paragraph>
      </>
    ),
  },
  {
    selector: '[data-onboarding="sidebar-menu-report"]',
    content: (
      <>
        <Paragraph>
          После того, как все интервью будет размечено, можете перейти в отчет и посмотреть как он отобразит результат
          вашей работы.
        </Paragraph>
      </>
    ),
    disableActions: true,
  },
];

export const reportPageSteps = (onFinish: () => void): StepType[] => [
  {
    selector: '[data-onboarding="app"]',
    content: (
      <>
        <Paragraph>На этой странице вы найдете отчет исследования.</Paragraph>
        <Paragraph>Все теги из интервью, которые вы выделяли в транскрипте собираются здесь.</Paragraph>
      </>
    ),
  },
  {
    selector: '[data-onboarding="report-conclusion"]',
    content: (
      <>
        <Paragraph>
          Колонка выводы редактируемая, здесь вы можете описать свои заключения по каждому вопросу из гайда.
        </Paragraph>
      </>
    ),
    highlightedSelectors: [
      '[data-onboarding="report-conclusion"]',
      '.ant-table-row:last-child [data-onboarding="report-conclusion-textarea"]',
    ],
    position: 'left',
    padding: 30,
  },
  {
    selector: '[data-onboarding="report-share"]',
    content: (
      <>
        <Paragraph>
          А нажав на кнопку «Поделиться», сможете сохранить отчет в Excel или отправить ссылку на него коллеге, даже
          если он не зарегистрирован на платформе Qulap.
        </Paragraph>
      </>
    ),
  },
  {
    selector: '[data-onboarding="menu-projects"]',
    content: (
      <>
        <Paragraph>На этом этапе наше обучение закончилось.</Paragraph>
        <Paragraph>
          Теперь вы можете перейти на страницу проектов, создать свой проект и начать работу над ним.
        </Paragraph>
      </>
    ),
    padding: {
      mask: [10, -10],
    },
    disableActions: true,
    actionAfter: () => {
      onFinish();
    },
  },
];

