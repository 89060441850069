import { Dropdown as AntdDropdown } from 'antd';
import { DropdownProps as AntdDropdownProps } from 'antd/lib/dropdown';
import cn from 'classnames';
import React from 'react';

import styles from './dropdown.module.scss';

export interface DropdownProps extends AntdDropdownProps {
  className?: string;
}

export function Dropdown({ className, ...props }: DropdownProps) {
  const classes = cn(styles.dropdown, className);

  return <AntdDropdown className={classes} {...props} />;
}

