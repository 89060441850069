import React from 'react';
import { Link } from 'react-router-dom';

import { routes } from 'api/routes';

import { Avatar } from 'shared/avatar';
import { ColumnsType } from 'shared/table';

import { sorter } from 'helpers/sorter';

import { InterviewRow, translateInterviewStatus } from 'models/interview';
import { Project } from 'models/project';
import { translateTranscriptionType } from 'models/transcription';

import styles from './interviews.page.module.scss';

export function getColumns(projectId: Project['id']): ColumnsType<InterviewRow> {
  return [
    {
      title: 'Название интервью',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => sorter.strings(a.name, b.name),
      render: (text, record) => (
        <>
          {record.image && <Avatar className={styles.table_avatar} size='small' src={record.image.url} />}{' '}
          <Link to={routes.interview(projectId, record.id)}>{text}</Link>
        </>
      ),
    },
    {
      title: 'Создано',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => sorter.dateTimes(a.createdAt, b.createdAt),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => sorter.strings(a.status, b.status),
      render: (text) => translateInterviewStatus(text),
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => sorter.strings(a.type, b.type),
      render: (text) => (text ? translateTranscriptionType(text) : null),
    },
    {
      title: 'Количество тегов',
      dataIndex: 'tags',
      key: 'tags',
      sorter: (a, b) => sorter.numbers(a.tags, b.tags),
    },
  ];
}

