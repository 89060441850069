import { paths } from 'api/paths';

import { Company } from 'models/company';
import { SuccessResponse } from 'models/response';

import { api } from 'store/service';

export const marketplaceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCompanies: builder.query<Company[], void>({
      query: () => ({
        url: paths.companies(),
      }),
      transformResponse: (response: SuccessResponse<Company[]>) => response.data,
    }),
  }),
  overrideExisting: false,
});

export const { useGetCompaniesQuery } = marketplaceApi;

