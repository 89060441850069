import { Row as AntdRow, Col as AntdCol } from 'antd';
import { ColProps as AntdColProps } from 'antd/lib/col';
import { RowProps as AntdRowProps } from 'antd/lib/row';
import cn from 'classnames';
import React from 'react';

import styles from './grid.module.scss';

export interface RowProps extends AntdRowProps {
  className?: string;
}

export function Row({ className, gutter = 40, ...props }: RowProps) {
  const classes = cn(styles.row, className);

  return <AntdRow className={classes} gutter={gutter} {...props} />;
}

export interface ColProps extends AntdColProps {
  className?: string;
}

export function Col({ className, ...props }: ColProps) {
  const classes = cn(styles.col, className);

  return <AntdCol className={classes} {...props} />;
}

