import { DEFAULT_EDITOR_VALUE } from 'components/editor';

import { LocalDateTime } from 'models/date';
import { Image } from 'models/image';
import { Transcription, TranscriptionChannel, TranscriptionType } from 'models/transcription';

export type InterviewStatus =
  | 'without_material'
  | 'with_material'
  | 'transcription_in_progress'
  | 'transcription_done'
  | 'transcription_error';

export interface NewInterview {
  name: string;
  image: Image | null;
  description: string;
}

export interface Interview extends NewInterview {
  id: number;
  status: InterviewStatus;
  transcriptions: Transcription[];
  channels: TranscriptionChannel[];
}

export interface InterviewRow extends Pick<Interview, 'id' | 'name' | 'image' | 'status'> {
  createdAt: LocalDateTime;
  tags: number;
  type: TranscriptionType;
}

export function createNewInterview(): NewInterview {
  return {
    name: 'Название интервью',
    image: null,
    description: JSON.stringify(DEFAULT_EDITOR_VALUE),
  };
}

export function translateInterviewStatus(status: InterviewStatus): string {
  switch (status) {
    case 'without_material':
      return 'Без материала';
    case 'with_material':
      return 'Материал загружен';
    case 'transcription_in_progress':
      return 'Транскрибируется';
    case 'transcription_done':
      return 'Транскрибация выполнена';
    case 'transcription_error':
      return 'Ошибка транскрибации';
    default:
      throw new Error(`Неизвестный статус ${status}`);
  }
}

