import cn from 'classnames';
import React from 'react';
import { useLocation, Location } from 'react-router-dom';

import { Col, Row } from 'shared/grid';
import { PageHeader } from 'shared/page-header';
import { Paragraph } from 'shared/typography';

import { PageLayout } from 'components/page-layout';

import { useDocumentTitle } from 'hooks/document-title';
import { useUser } from 'hooks/user';

import { LogoutButton } from 'features/logout';

import { ProfileAvatar } from './profile.avatar';
import { ProfileBalance } from './profile.balance';
import { ProfileForm } from './profile.form';
import styles from './profile.page.module.scss';

interface LocationWithState extends Location {
  state: {
    isFirstVisit?: boolean;
  };
}
export interface ProfilePageProps {
  className?: string;
}

export function ProfilePage({ className }: ProfilePageProps) {
  const classes = cn(styles.profilePage, className);
  useDocumentTitle('Профиль');
  const location = useLocation() as LocationWithState;
  const { user, isLoading } = useUser();

  const isFirstVisit = location.state?.isFirstVisit;

  return (
    <PageLayout className={classes}>
      <PageHeader title={isFirstVisit ? 'Заполните профиль' : 'Профиль'} extra={<LogoutButton />}>
        {isFirstVisit && <Paragraph>Для работы на платформе, Вам необходимо заполнить данные:</Paragraph>}
        <Row justify='space-between'>
          <Col span={14}>
            <ProfileAvatar avatar={user?.image} isLoading={isLoading} />
            <ProfileForm profile={user} isLoading={isLoading} isFirstVisit={isFirstVisit} />
          </Col>
          <Col span={8}>
            <ProfileBalance balance={user?.balance} />
          </Col>
        </Row>
      </PageHeader>
    </PageLayout>
  );
}

