import { CaretRightOutlined, PauseOutlined, LoadingOutlined } from '@ant-design/icons';
import { MenuProps, Dropdown } from 'antd';
import React, { useCallback, useEffect, useRef, useState, MouseEvent } from 'react';

import { Button } from 'shared/button';
import { Link } from 'shared/typography';

import { formatTime } from 'helpers/format-time';

import styles from './player-controls.module.scss';

export interface PlayerProgress {
  isReady: boolean;
  isPlaying: boolean;
  time: number;
}

export interface PlayerControlsProps extends PlayButtonProps, BarProps, RateProps, TimeProps {
  onProgressChange?: (progress: PlayerProgress) => void;
}

export function PlayerControls({
  file,
  loading,
  playing,
  onLoad,
  onTogglePlayPause,
  duration,
  onSeek,
  percentComplete,
  ready,
  onRate,
  position,
  onProgressChange,
}: PlayerControlsProps) {
  useEffect(() => {
    if (onProgressChange) {
      onProgressChange({ isReady: ready, isPlaying: playing, time: position });
    }
  }, [position, ready, playing]);

  return (
    <div className={styles.playerControls}>
      <PlayButton
        file={file}
        loading={loading}
        playing={playing}
        onLoad={onLoad}
        onTogglePlayPause={onTogglePlayPause}
      />
      <Bar playing={playing} duration={duration} percentComplete={percentComplete} onSeek={onSeek} />
      <Rate ready={ready} onRate={onRate} />
      <Time duration={duration} position={position} />
    </div>
  );
}

export interface PlayButtonProps {
  file: string;
  loading: boolean;
  playing: boolean;
  onLoad: (src: string) => void;
  onTogglePlayPause: () => void;
}

export function PlayButton({ file, loading, playing, onLoad, onTogglePlayPause }: PlayButtonProps) {
  const [isWaiting, setIsWaiting] = useState(true);

  useEffect(() => {
    // Запускать воспроизведение после завершений загрузки
    if (!loading) {
      onTogglePlayPause();
    }
  }, [loading]);

  if (loading) {
    return (
      <Button
        type='default'
        onClick={() => {
          if (isWaiting) {
            onLoad(file);
            setIsWaiting(false);
          }
        }}
        icon={isWaiting ? <CaretRightOutlined /> : <LoadingOutlined />}
      />
    );
  }

  return (
    <Button type='default' onClick={onTogglePlayPause} icon={playing ? <PauseOutlined /> : <CaretRightOutlined />} />
  );
}

export interface BarProps {
  playing: boolean;
  duration: number;
  percentComplete: number;
  onSeek: (position: number) => void;
}

export function Bar({ playing, duration, onSeek, percentComplete }: BarProps) {
  const [barWidth, setBarWidth] = useState('0%');

  const barRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setBarWidth(`${percentComplete}%`);
  }, [percentComplete]);

  const goTo = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      const { pageX: eventOffsetX } = event;

      if (barRef.current) {
        const elementOffsetX = barRef.current.getBoundingClientRect().left;
        const elementWidth = barRef.current.clientWidth;
        const percent = (eventOffsetX - elementOffsetX) / elementWidth;
        onSeek(percent * duration);
      }
    },
    [duration, playing, onSeek],
  );

  return (
    <div className={styles.bar} ref={barRef} onClick={goTo}>
      <div className={styles.bar__action} />
      <div className={styles.bar__tick} style={{ width: barWidth }} />
    </div>
  );
}

const items: MenuProps['items'] = [
  {
    key: '0.5',
    label: '0.5X',
  },
  {
    key: '0.75',
    label: '0.75X',
  },
  {
    key: '1',
    label: '1X',
  },
  {
    key: '1.25',
    label: '1.25X',
  },
  {
    key: '1.5',
    label: '1.5X',
  },
  {
    key: '1.75',
    label: '1.75X',
  },
  {
    key: '2',
    label: '2X',
  },
];

export interface RateProps {
  ready: boolean;
  onRate: (rate: number) => void;
}

export function Rate({ ready, onRate }: RateProps) {
  const [rate, setRate] = useState('1');

  if (!ready) {
    return null;
  }

  return (
    <Dropdown
      menu={{
        items,
        selectable: true,
        defaultSelectedKeys: [rate],
        onClick: ({ key }) => {
          setRate(key);
          onRate(Number(key));
        },
        className: styles.rate__menu,
      }}
      trigger={['click']}
      placement={'bottomRight'}
    >
      <Link className={styles.rate}>{rate}X</Link>
    </Dropdown>
  );
}

export interface TimeProps {
  duration: number;
  position: number;
}

export function Time({ duration, position }: TimeProps) {
  if (duration === Infinity || !duration) {
    return null;
  }

  const elapsed = typeof position === 'number' ? position : 0;

  return <div>{`${formatTime(elapsed)} / ${formatTime(duration)}`}</div>;
}

