import {
  BoldOutlined,
  ItalicOutlined,
  UnderlineOutlined,
  StrikethroughOutlined,
  OrderedListOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import cn from 'classnames';
import React, { useState } from 'react';

import { Affix } from 'shared/affix';

import { HEADER_HEIGHT } from 'helpers/constants';

import styles from './editor.module.scss';
import { MarkButton, BlockButton } from './editor.toolbar-button';

export function EditorToolbar() {
  const [isSticky, setIsSticky] = useState<boolean | undefined>(undefined);

  return (
    <Affix offsetTop={HEADER_HEIGHT} onChange={setIsSticky}>
      <div className={cn(styles.toolbar, { [styles.toolbar_sticky]: isSticky })}>
        <div className={styles.toolbar__group}>
          <MarkButton format='bold'>
            <BoldOutlined />
          </MarkButton>
          <MarkButton format='italic'>
            <ItalicOutlined />
          </MarkButton>
          <MarkButton format='underline'>
            <UnderlineOutlined />
          </MarkButton>
          <MarkButton format='strike'>
            <StrikethroughOutlined />
          </MarkButton>
        </div>
        <hr className={styles.toolbar__separator} />
        <div className={styles.toolbar__group}>
          <BlockButton format='heading-one'>H1</BlockButton>
          <BlockButton format='heading-two'>H2</BlockButton>
          <BlockButton format='heading-three'>H3</BlockButton>
          <BlockButton format='heading-four'>H4</BlockButton>
          <BlockButton format='heading-five'>H5</BlockButton>
        </div>
        <hr className={styles.toolbar__separator} />
        <div className={styles.toolbar__group}>
          <BlockButton format='numbered-list'>
            <OrderedListOutlined />
          </BlockButton>
          <BlockButton format='bulleted-list'>
            <UnorderedListOutlined />
          </BlockButton>
        </div>
      </div>
    </Affix>
  );
}

