import { Collapse as AntdCollapse } from 'antd';
import { CollapsePanelProps as AntdCollapsePanelProps } from 'antd/lib/collapse';
import cn from 'classnames';
import React from 'react';

import styles from './collapse.module.scss';

export interface CollapsePanelProps extends AntdCollapsePanelProps {
  className?: string;
}

export function CollapsePanel({ className, ...props }: CollapsePanelProps) {
  const classes = cn(styles.collapsePanel, className);

  return <AntdCollapse.Panel className={classes} {...props} />;
}

