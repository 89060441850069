import { paths } from 'api/paths';

import { saveFile } from 'helpers/save-file';

import { Interview } from 'models/interview';
import { Project } from 'models/project';
import { SuccessResponse } from 'models/response';
import { Transcription } from 'models/transcription';

import { api } from 'store/service';

export const interviewApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getInterview: builder.query<Interview, { projectId: Project['id']; id: Interview['id'] }>({
      query: ({ projectId, id }) => ({
        url: paths.interview(projectId, id),
      }),
      transformResponse: (response: SuccessResponse<Interview>) => response.data,
      providesTags: ['Interview'],
    }),
    editInterview: builder.mutation<Interview, { projectId: Project['id']; interview: Interview }>({
      query: ({ projectId, interview }) => ({
        url: paths.interview(projectId, interview.id),
        method: 'PUT',
        body: interview,
      }),
      invalidatesTags: (result) => (result ? ['Interviews', 'Interview', 'Report'] : []),
    }),
    downloadTranscription: builder.query<
      void,
      {
        projectId: Project['id'];
        interviewId: Interview['id'];
        interviewName?: Interview['name'];
        id: Transcription['id'];
      }
    >({
      query: ({ projectId, interviewId, interviewName, id }) => ({
        url: paths.downloadTranscription(projectId, interviewId, id),
        responseHandler: async (response) => {
          if (response.ok) {
            const blob = await response.blob();
            saveFile(blob, interviewName ?? 'Интервью');
          }
        },
        cache: 'no-cache',
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetInterviewQuery, useEditInterviewMutation, useLazyDownloadTranscriptionQuery } = interviewApi;

