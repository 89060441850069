import React from 'react';

import { Paragraph, Title } from 'shared/typography';

import { PaymentStatus } from 'models/payment';

export interface PaymentStatusInfoProps {
  status?: PaymentStatus['status'];
  description?: PaymentStatus['description'];
}

export function PaymentStatusInfo({ status, description }: PaymentStatusInfoProps) {
  if (status === 'success') {
    return (
      <>
        <Title level={3}>Деньги зачислены на Ваш счёт</Title>
        <Paragraph>Если деньги не поступили, пожалуйста, обновите страницу</Paragraph>
      </>
    );
  }

  if (status === 'canceled') {
    return (
      <>
        <Title level={3}>Не удалось провести оплату</Title>
        <Paragraph>{description}</Paragraph>
      </>
    );
  }

  if (status === 'pending') {
    return (
      <>
        <Title level={3}>Поступление денег ожидается</Title>
        <Paragraph>Пожалуйста, обновите страницу позже</Paragraph>
      </>
    );
  }

  return null;
}

