import cn from 'classnames';
import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './sidebar-menu.module.scss';

export interface SidebarMenuItemProps {
  label: string;
  to: string;
  icon: ReactNode;
  end?: boolean;
  onboardingSelector?: string;
}

export interface SidebarMenuProps {
  className?: string;
  items: SidebarMenuItemProps[];
}

export function SidebarMenu({ className, items }: SidebarMenuProps) {
  const classes = cn(styles.sidebarMenu, className);

  return (
    <ul className={classes}>
      {items.map(({ label, to, icon, end = true, onboardingSelector }) => (
        <li className={styles.item} key={to}>
          <NavLink
            data-onboarding={onboardingSelector}
            className={({ isActive }) =>
              cn(styles.link, {
                [styles.link_active]: isActive,
              })
            }
            to={to}
            end={end}
          >
            <span className={styles.link__icon}>{icon}</span>
            {label}
          </NavLink>
        </li>
      ))}
    </ul>
  );
}

