import { useCallback, useState } from 'react';

export interface PlayerCurrentTime {
  seconds: number;
  id: number;
}

export const usePlayer = (): { currentTime?: PlayerCurrentTime; onSeek: (seconds: number) => void } => {
  const [seconds, setSeconds] = useState<number>();
  const [id, setId] = useState<number>();

  const onSeek = useCallback((currentSeconds: number) => {
    setSeconds(currentSeconds);
    setId(Math.random());
  }, []);

  if (typeof seconds === 'number' && typeof id === 'number') {
    return {
      currentTime: {
        seconds,
        id,
      },
      onSeek,
    };
  }

  return {
    onSeek,
  };
};

