import { paths } from 'api/paths';

import { api } from 'store/service';

export interface ForgotPasswordRequest {
  email: string;
}

export const forgotPasswordApi = api.injectEndpoints({
  endpoints: (builder) => ({
    forgotPassword: builder.mutation<void, ForgotPasswordRequest>({
      query: (credentials) => ({
        url: paths.forgotPassword(),
        method: 'POST',
        body: credentials,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useForgotPasswordMutation } = forgotPasswordApi;

