import { Input as AntdInput } from 'antd';
import { InputProps as AntdInputProps } from 'antd/lib/input';
import cn from 'classnames';
import React from 'react';

import styles from './input.module.scss';

export interface InputPasswordProps extends AntdInputProps {
  className?: string;
}

export function InputPassword({ className, size = 'large', ...props }: InputPasswordProps) {
  const classes = cn(styles.inputPassword, className);

  return <AntdInput.Password className={classes} size={size} {...props} />;
}

