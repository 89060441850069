import React from 'react';
import { Link } from 'react-router-dom';

import { routes } from 'api/routes';

import { Avatar } from 'shared/avatar';
import { ColumnsType } from 'shared/table';

import { sorter } from 'helpers/sorter';

import { ProjectRow } from 'models/project';

import styles from './projects.page.module.scss';

export const columns: ColumnsType<ProjectRow> = [
  {
    title: 'Название проекта',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => sorter.strings(a.name, b.name),
    render: (text, record) => (
      <>
        {record.image && <Avatar className={styles.table_avatar} size='small' src={record.image.url} />}{' '}
        <Link to={routes.project(record.id)}>{text}</Link>
      </>
    ),
  },
  {
    title: 'Количество интервью',
    dataIndex: 'interviews',
    key: 'interviews',
    sorter: (a, b) => sorter.numbers(a.interviews, b.interviews),
  },
  {
    title: 'Создан',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: (a, b) => sorter.dates(a.createdAt, b.createdAt),
  },
  {
    title: 'Изменен',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    sorter: (a, b) => sorter.dates(a.updatedAt, b.updatedAt),
  },
];

