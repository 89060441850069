import { Input as AntdInput } from 'antd';
import { SearchProps as AntdSearchProps } from 'antd/lib/input';
import cn from 'classnames';
import React from 'react';

import styles from './input.module.scss';

export interface SearchProps extends AntdSearchProps {
  className?: string;
}

export function Search({ className, size = 'large', ...props }: SearchProps) {
  const classes = cn(styles.search, className);

  return <AntdInput.Search className={classes} size={size} {...props} />;
}

