import { Radio as AntdRadio } from 'antd';
import { RadioProps as AntdRadioProps } from 'antd/lib/radio';
import cn from 'classnames';
import React from 'react';

import styles from './radio.module.scss';

export interface RadioItemProps extends AntdRadioProps {
  className?: string;
}

export function RadioItem({ className, ...props }: RadioItemProps) {
  const classes = cn(styles.radio, className);

  return <AntdRadio className={classes} {...props} />;
}

