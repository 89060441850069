import { ErrorResponse } from 'models/response';

import { isFetchBaseQueryError } from './service';

export function isErrorResponse(response: unknown): response is ErrorResponse {
  return typeof response === 'object' && response !== null && 'reason' in response;
}

export function getResponseError(error: unknown): string {
  if (isFetchBaseQueryError(error) && isErrorResponse(error.data)) {
    return error.data.reason;
  }
  return 'Упс... Что-то пошло не так';
}

export function checkNotFoundError(error: unknown): boolean {
  if (isFetchBaseQueryError(error) && isErrorResponse(error.data)) {
    return error.status === 404;
  }
  return false;
}

