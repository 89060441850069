import { PayloadAction } from '@reduxjs/toolkit';

export function storeHelper<State extends object>() {
  return {
    setAction:
      <Key extends keyof State>(key: Key) =>
      (state: State, action: PayloadAction<State[Key]>) => {
        state[key] = action.payload;
      },
  };
}

