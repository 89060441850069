import cn from 'classnames';
import React, { ReactNode } from 'react';

import styles from './container.module.scss';

export interface ContainerProps {
  className?: string;
  children: ReactNode;
}

export function Container({ className, children }: ContainerProps) {
  const classes = cn(styles.container, className);

  return <div className={classes}>{children}</div>;
}
