import {
  Tree,
  TreeMethods,
  DropOptions,
  NodeModel,
  MultiBackend,
  getBackendOptions,
  getDescendants,
} from '@minoru/react-dnd-treeview';
import React, { useEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';

import { Button } from 'shared/button';

import { createCounter } from 'helpers/counter';
import { insertToArray } from 'helpers/insert-to-array';

import { NewQuestion, Question, createNewQuestion } from 'models/question';

import { CustomNode } from './custom-node';
import { Placeholder } from './placeholder';
import styles from './questions.module.scss';

export interface ProjectQuestionsProps {
  questions: Question[];
  onChange: (questions: Question[]) => void;
}

export function ProjectQuestions({ questions, onChange }: ProjectQuestionsProps) {
  const ref = useRef<TreeMethods>(null);
  const [treeData, setTreeData] = useState<NodeModel<Question | NewQuestion>[]>(() =>
    createTreeFromQuestions(questions, createCounter()),
  );

  useEffect(() => {
    onChange(createQuestionsFromTree(treeData));
  }, [treeData]);

  const onDrop = (tree: NodeModel<Question | NewQuestion>[], options: DropOptions<Question | NewQuestion>) => {
    const { dropTarget, dropTargetId, dragSourceId } = options;
    ref.current?.open(dropTargetId);
    const updatedTree = tree.map((node: NodeModel<Question | NewQuestion>) => {
      // Получает и устанавливает цвет родителя в data, если он есть
      const parentColor = dropTarget?.data?.color;
      const data = node.data
        ? {
            ...node.data,
            color: parentColor || node.data.color,
          }
        : undefined;
      // Меняет для перетаскиваемого элемента свойство droppable,
      // так как может быть всего два уровня вложенности
      if (dragSourceId === node.id) {
        return {
          ...node,
          droppable: dropTargetId === ROOT_ID,
          data,
        };
      }
      // Меняет потомкам перетаскиваемого элемента родителя, если он сам стал вложенным
      if (dragSourceId === node.parent) {
        return {
          ...node,
          parent: dropTargetId,
          data,
        };
      }
      return node;
    });
    setTreeData(updatedTree);
  };

  const onCreate = (id?: NodeModel['id'], name?: string) => {
    setTreeData((currentTreeData) => {
      const newId = getLastId(currentTreeData) + 1;
      const index = currentTreeData.findIndex((node) => node.id === id);
      if (index === -1) {
        return insertToArray(currentTreeData, {
          id: newId,
          text: '',
          parent: ROOT_ID,
          droppable: true,
          data: createNewQuestion(),
        });
      }
      const node = currentTreeData[index];
      const isChild = node.parent !== ROOT_ID;
      return insertToArray(
        currentTreeData,
        {
          id: newId,
          text: '',
          parent: isChild ? node.parent : ROOT_ID,
          droppable: !isChild,
          data: createNewQuestion(name, isChild ? node.data?.color : undefined),
        },
        index + 1,
      );
    });
  };

  const onRemove = (id: NodeModel['id']) => {
    const deleteIds = [id, ...getDescendants(treeData, id).map((node) => node.id)];
    const updatedTree = treeData.filter((node) => !deleteIds.includes(node.id));
    setTreeData(updatedTree);
  };

  const onTextChange = (id: NodeModel['id'], value: string) => {
    const updatedTree = treeData.map((node) => {
      if (node.id === id) {
        const data = node.data
          ? {
              ...node.data,
              name: value,
            }
          : undefined;
        return {
          ...node,
          data,
        };
      }
      return node;
    });
    setTreeData(updatedTree);
  };

  const onColorChange = (id: NodeModel['id'], parent: NodeModel['parent'], color: Question['color']) => {
    const updatedTree = treeData.map((node) => {
      const isCurrent = node.id === id;
      const isChild = node.parent === id;
      const isParent = node.id === parent;
      const isSibling = parent !== ROOT_ID && node.parent === parent;
      if (isCurrent || isChild || isParent || isSibling) {
        const data = node.data
          ? {
              ...node.data,
              color,
            }
          : undefined;
        return {
          ...node,
          data,
        };
      }
      return node;
    });
    setTreeData(updatedTree);
  };

  return (
    <DndProvider backend={MultiBackend} options={getBackendOptions()}>
      <Tree
        ref={ref}
        tree={treeData}
        rootId={ROOT_ID}
        initialOpen={true}
        sort={false}
        insertDroppableFirst={false}
        canDrop={(_, { dragSource, dropTargetId }) => {
          if (dragSource?.parent === dropTargetId) {
            return true;
          }
        }}
        dropTargetOffset={-2}
        classes={{
          root: styles.tree,
          draggingSource: styles.draggingSource,
          placeholder: styles.placeholderContainer,
          dropTarget: styles.dropTarget,
        }}
        render={(node, { depth }) => (
          <CustomNode
            node={node}
            depth={depth}
            onCreate={onCreate}
            onRemove={onRemove}
            onTextChange={onTextChange}
            onColorChange={onColorChange}
          />
        )}
        dragPreviewRender={(monitorProps) => <div>{monitorProps.item.data?.name}</div>}
        onDrop={onDrop}
        placeholderRender={(_, { depth }) => <Placeholder depth={depth} />}
      />
      <Button onClick={() => onCreate()} block={true} type='default'>
        Добавить вопрос
      </Button>
    </DndProvider>
  );
}

const ROOT_ID = 0;

const getLastId = (treeData: NodeModel[]): number => {
  const reversedArray = [...treeData].sort((a, b) => {
    if (a.id < b.id) {
      return 1;
    } else if (a.id > b.id) {
      return -1;
    }

    return 0;
  });

  return reversedArray.length > 0 ? Number(reversedArray[0].id) : 0;
};

function createTreeFromQuestions(
  questions: Question[],
  counter: () => number,
  parent = ROOT_ID,
): NodeModel<Question>[] {
  return questions.flatMap((q) => {
    const { questions: children, ...question } = q;
    const id = counter();
    return [
      {
        id: id,
        parent: parent,
        droppable: parent === ROOT_ID,
        text: '',
        data: question,
      },
      ...(children ? createTreeFromQuestions(children, counter, id) : []),
    ];
  });
}

function createQuestionsFromTree(tree: NodeModel<Question | NewQuestion>[]): Question[] {
  const nodesWithoutParent = tree.filter((node) => node.parent === ROOT_ID);
  const nodesWithParent = tree.filter((node) => node.parent !== ROOT_ID);
  return nodesWithoutParent.map((node) => {
    const question = node.data as Question;
    const children: Question[] = nodesWithParent
      .filter((cnildNode) => cnildNode.parent === node.id)
      .map((childNode) => childNode.data as Question);
    return {
      ...question,
      questions: children,
    };
  });
}

