import { InputNumber as AntdNumberInput } from 'antd';
import { InputNumberProps as AntdInputNumberProps } from 'antd/lib/input-number';
import cn from 'classnames';
import React from 'react';

import styles from './input.module.scss';

export interface InputProps extends AntdInputNumberProps {
  className?: string;
}

export interface InputNumberProps extends AntdInputNumberProps {
  className?: string;
}

export function InputNumber({ className, size = 'large', ...props }: InputNumberProps) {
  const classes = cn(styles.inputNumber, className);

  return <AntdNumberInput className={classes} size={size} style={{ width: '100%' }} {...props} />;
}

