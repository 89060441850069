import React from 'react';

import { Button } from 'shared/button';
import { Space } from 'shared/space';

export interface RetryButtonProps {
  file: string;
  onLoad: (src: string) => void;
}

export function RetryButton({ file, onLoad }: RetryButtonProps) {
  return (
    <Space>
      <div>Файл недоступен</div>
      <Button type='default' onClick={() => onLoad(file)}>
        Попробовать еще
      </Button>
    </Space>
  );
}

