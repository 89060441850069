import { Alert as AntdAlert } from 'antd';
import { AlertProps as AntdAlertProps } from 'antd/lib/alert';
import cn from 'classnames';
import React from 'react';

import styles from './alert.module.scss';

export interface AlertProps extends AntdAlertProps {
  className?: string;
}

export function Alert({ className, ...props }: AlertProps) {
  const classes = cn(styles.alert, className);

  return <AntdAlert className={classes} {...props} />;
}

