import cn from 'classnames';
import React, { useEffect, useMemo } from 'react';

import { Checkbox, CheckboxChangeEvent } from 'shared/checkbox';
import { Collapse, CollapsePanel } from 'shared/collapse';
import { Title, Paragraph } from 'shared/typography';

import { useAppDispatch, useAppSelector } from 'hooks/store';

import { Company } from 'models/company';

import { marketplaceActions, marketplaceSelector } from '../../marketplace.slice';
import styles from './companies.module.scss';

const { setSelectedCompanies, addSelectedCompany, removeSelectedCompany } = marketplaceActions;

export interface CompaniesProps {
  className?: string;
  companies: Company[];
}

export function Companies({ className, companies }: CompaniesProps) {
  const classes = cn(styles.companies, className);
  const dispatch = useAppDispatch();
  const { selectedCompanies } = useAppSelector(marketplaceSelector);
  const selectedCompaniesIds = useMemo(() => new Set(selectedCompanies.map(({ id }) => id)), [selectedCompanies]);

  useEffect(() => {
    dispatch(setSelectedCompanies([]));
  }, []);

  const onCheckAllChange = (event: CheckboxChangeEvent) => {
    const {
      target: { checked },
    } = event;
    dispatch(setSelectedCompanies(checked ? companies : []));
  };

  const onCheckCompanyChange = (event: CheckboxChangeEvent, company: Company) => {
    const {
      target: { checked },
    } = event;
    if (checked) {
      dispatch(addSelectedCompany(company));
    } else {
      dispatch(removeSelectedCompany(company));
    }
  };

  return (
    <div className={classes}>
      <Checkbox
        className={styles.companies__checkAll}
        indeterminate={selectedCompanies.length > 0 && selectedCompanies.length !== companies.length}
        onChange={onCheckAllChange}
        checked={selectedCompanies.length === companies.length}
      >
        Выбрать всех
      </Checkbox>
      {companies.map((company) => (
        <div key={company.id} className={cn(styles.companies__item, styles.company)}>
          <Checkbox
            className={styles.company__checkbox}
            onChange={(event) => onCheckCompanyChange(event, company)}
            checked={selectedCompaniesIds.has(company.id)}
          >
            <Title level={4}>{company.name}</Title>
          </Checkbox>

          <div className={styles.company__infoWrapper}>
            {company.image && <img className={styles.company__image} src={company.image.url} />}
            <div className={styles.company__info}>
              {company.services && (
                <Paragraph>
                  <strong>Услуги рекрутинга:</strong> {company.services}
                </Paragraph>
              )}
              {company.methods && (
                <Paragraph>
                  <strong>Для каких методов:</strong> {company.methods}
                </Paragraph>
              )}
              {company.segments && (
                <Paragraph>
                  <strong>Сегменты:</strong> {company.segments}
                </Paragraph>
              )}
              {company.additional && (
                <Collapse ghost={true} className={styles.company__collapse}>
                  <CollapsePanel header='Дополнительно' key='additional'>
                    {company.additional}
                  </CollapsePanel>
                </Collapse>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

