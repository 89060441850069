import axios from 'axios';

import { getCookie } from 'helpers/cookie';

const isProduction = process.env.NODE_ENV === 'production';

export const instance = axios.create({
  baseURL: isProduction ? '/' : 'https://dev.qulap.com',
  withCredentials: true,
});

instance.interceptors.request.use((config) => {
  const xsrfToken = getCookie('XSRF-TOKEN');

  if (xsrfToken) {
    config.headers.set('X-XSRF-TOKEN', decodeURIComponent(xsrfToken));
  }

  config.headers.set('accept', 'application/json, text/plain, */*');

  if (!isProduction) {
    config.headers.set('authorization', 'Basic cXVsYXAtc3J2OnNdZGkkYWxCOWRxSzU=');
    config.headers.set('cors-key', 'qkrXQmfknnWF4Dfk95BSnX');
  }

  return config;
});

