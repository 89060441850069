import { useState, useCallback } from 'react';

export interface WithModalProps {
  open: boolean;
  toggleModal: () => void;
}

export const useModal = (): WithModalProps => {
  const [open, setOpen] = useState(false);

  const toggleModal = useCallback(() => {
    setOpen((currentopen) => !currentopen);
  }, []);

  return {
    open,
    toggleModal,
  };
};

