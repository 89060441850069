import { paths } from 'api/paths';

import { api } from 'store/service';

export const logoutApi = api.injectEndpoints({
  endpoints: (builder) => ({
    logout: builder.mutation<void, void>({
      query: () => ({
        url: paths.logout(),
        method: 'POST',
      }),
      invalidatesTags: (result) => (result ? ['User'] : []),
    }),
  }),
  overrideExisting: false,
});

export const { useLogoutMutation } = logoutApi;

