import { Input as AntdInput } from 'antd';
import { InputProps as AntdInputProps, InputRef } from 'antd/lib/input';
import cn from 'classnames';
import React, { forwardRef } from 'react';

import styles from './input.module.scss';

export interface InputProps extends AntdInputProps {
  className?: string;
}

export const Input = forwardRef<InputRef, InputProps>((initialProps, ref) => {
  const { className, size = 'large', ...props } = initialProps;
  const classes = cn(styles.input, className);

  return <AntdInput className={classes} size={size} ref={ref} {...props} />;
});

Input.displayName = 'Input';

