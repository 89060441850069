import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { routes } from 'api/routes';

import { Button } from 'shared/button';
import { Title } from 'shared/typography';

import { Container } from 'components/container';
import { Header } from 'components/header';

import { useDocumentTitle } from 'hooks/document-title';

import image from './image.png';
import styles from './not-found.module.scss';

export interface NotFoundProps {
  className?: string;
}

export function NotFound({ className }: NotFoundProps) {
  const classes = cn(styles.notFound, className);
  useDocumentTitle('Страница не найдена');

  return (
    <div className={classes}>
      <Header />
      <Container className={styles.container}>
        <img src={image} alt='Ошибка 404' />
        <Title level={3}>Извините, мы не смогли найти эту страницу</Title>
        <Link to={routes.home()}>
          <Button>Вернуться на главную</Button>
        </Link>
      </Container>
    </div>
  );
}

