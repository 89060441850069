import { UserOutlined } from '@ant-design/icons';
import { Avatar as AntdAvatar } from 'antd';
import { AvatarProps as AntdAvatarProps } from 'antd/lib/avatar';
import cn from 'classnames';
import React from 'react';

import styles from './avatar.module.scss';

export interface AvatarProps extends AntdAvatarProps {
  className?: string;
}

export function Avatar({ className, size = 56, icon = <UserOutlined />, ...props }: AvatarProps) {
  const classes = cn(styles.avatar, className);

  return <AntdAvatar className={classes} size={size} icon={icon} {...props} />;
}

