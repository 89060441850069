import cn from 'classnames';
import React from 'react';

import { Spin } from 'shared/spin';
import { Paragraph } from 'shared/typography';

import { TariffModal } from 'components/tariff-modal';

import { useGetTariffQuery } from 'store/services/tariff.service';

import { TariffItem } from './tariff.item';
import styles from './tariff.module.scss';

export interface TariffProps {
  className?: string;
}

export function Tariff({ className }: TariffProps) {
  const classes = cn(styles.tariff, className);

  const { data: tariff, isLoading } = useGetTariffQuery();

  if (isLoading) {
    return <Spin size='large' />;
  }

  if (!tariff) {
    return null;
  }

  return (
    <div className={classes}>
      <Paragraph>В автоматическом режиме:</Paragraph>
      <ul className={styles.tariff__list}>
        <TariffItem tariffItem={tariff.auto} timeFormat='minute' />
      </ul>
      <Paragraph>В ручном режиме:</Paragraph>
      <ul className={styles.tariff__list}>
        <TariffItem tariffItem={tariff.manual.priority_4} timeFormat='hour' />
        <TariffItem tariffItem={tariff.manual.priority_3} timeFormat='hour' />
        <TariffItem tariffItem={tariff.manual.priority_2} timeFormat='hour' />
        <TariffItem tariffItem={tariff.manual.priority_1} timeFormat='hour' />
      </ul>
      <TariffModal tariff={tariff} />
    </div>
  );
}

