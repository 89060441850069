import React from 'react';
import { RenderLeafProps } from 'slate-react';

import { transformColor } from 'models/color';

import { QuestionNodeQuestionIds, QuestionsList } from './editor.types';
import { getFlatProjectQuestions, mixColors } from './editor.utils';

export function EditorLeaf({
  attributes,
  children,
  leaf,
  questions,
}: RenderLeafProps & {
  questions: QuestionsList;
}) {
  const flatQuestions = getFlatProjectQuestions(Object.values(questions));
  const questionsForNode = flatQuestions
    .filter(({ id }) => (leaf as QuestionNodeQuestionIds)?.[`_${id}`])
    .map((question) => ({ id: question.id, question }));

  const colors = questionsForNode.map((q) => transformColor(q.question.color, 0.3));
  const ids = questionsForNode.map((q) => q.id);

  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }
  if (leaf.strike) {
    children = <s>{children}</s>;
  }
  if (leaf.italic) {
    children = <em>{children}</em>;
  }
  if (leaf.underline) {
    children = <u>{children}</u>;
  }
  return (
    <span
      {...attributes}
      className={`question_node ${colors.length > 0 ? 'node_with_questions' : 'node_no_question'}`}
      data-questions={ids.join(',')}
      style={{
        backgroundColor: mixColors(colors),
      }}
    >
      {children}
    </span>
  );
}

