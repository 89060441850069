type FilterFlags<Base, Condition> = { [Key in keyof Base]: Base[Key] extends Condition ? Key : never };
type AllowedNames<Base, Condition> = FilterFlags<Base, Condition>[keyof Base];
type SubType<Base, Condition> = Pick<Base, AllowedNames<Base, Condition>>;

export function findMaxByKey<T extends object, Key extends keyof SubType<T, number>>(
  list: T[],
  key: Key,
  initialValue: number,
): number {
  return list.reduce((acc, value) => (acc < Number(value[key]) ? Number(value[key]) : acc), initialValue);
}

export function findMinByKey<T extends object, Key extends keyof SubType<T, number>>(
  list: T[],
  key: Key,
  initialValue: number,
): number {
  return list.reduce((acc, value) => (acc > Number(value[key]) ? Number(value[key]) : acc), initialValue);
}

