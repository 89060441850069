import { QuestionCircleTwoTone } from '@ant-design/icons';
import cn from 'classnames';
import React, { ReactNode } from 'react';

import { Popover, PopoverProps } from 'shared/popover';

import styles from './hint.module.scss';

export interface HintProps extends Pick<PopoverProps, 'maxWidth' | 'trigger' | 'zIndex'> {
  className?: string;
  children?: ReactNode;
  content: ReactNode;
}

export function Hint({
  className,
  children = <QuestionCircleTwoTone />,
  content,
  maxWidth = 400,
  trigger,
  zIndex,
}: HintProps) {
  const classes = cn(styles.hint, className);

  return (
    <Popover className={classes} content={content} maxWidth={maxWidth} trigger={trigger} zIndex={zIndex}>
      {children}
    </Popover>
  );
}

