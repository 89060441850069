import { Modal } from 'antd';
import { useEffect, useState } from 'react';

import { modalConfirm } from 'shared/modal';

import { usePrompt } from 'hooks/prompt';

export const useLeaveBlocker = (onSave: () => Promise<void>) => {
  const [isBlocking, setIsBlocking] = useState<boolean>(false);
  const { showPrompt, confirmNavigation, cancelNavigation } = usePrompt(isBlocking);

  useEffect(() => {
    if (showPrompt) {
      modalConfirm({
        title: 'Покинуть страницу?',
        content: 'Внесенные изменения не сохранятся',
        okText: 'Сохранить и выйти',
        onOk: () => {
          return onSave().then(() => {
            Modal.destroyAll();
            confirmNavigation();
          });
        },
        cancelText: 'Выйти без сохранения',
        onCancel: cancelNavigation,
        cancelButtonProps: {
          onClick: () => {
            Modal.destroyAll();
            confirmNavigation();
          },
        },
        width: 480,
      });
    }
  }, [showPrompt]);

  return { setIsBlocking };
};

