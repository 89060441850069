import { WechatOutlined } from '@ant-design/icons';
import cn from 'classnames';
import React from 'react';

import { Button } from 'shared/button';
import { Popover } from 'shared/popover';
import { Space } from 'shared/space';

import styles from './help.module.scss';

export interface HelpProps {
  className?: string;
}

export function Help({ className }: HelpProps) {
  const classes = cn(styles.help, className);

  return (
    <div className={classes}>
      <Popover
        placement='topRight'
        trigger='click'
        getPopupContainer={(trigger) => trigger.parentElement!} // eslint-disable-line @typescript-eslint/no-non-null-assertion
        content={
          <Space size='small' direction='vertical'>
            <Button type='default' block={true} href='mailto:support@qulap.com'>
              Написать на почту
            </Button>
            <Button type='default' block={true} href='https://t.me/EgorOnOff' target='_blank'>
              Написать в Telegram
            </Button>
          </Space>
        }
      >
        <Button
          className={styles.help__button}
          shape='circle'
          icon={<WechatOutlined style={{ fontSize: 'inherit' }} />}
        />
      </Popover>
    </div>
  );
}

