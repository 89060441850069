import cn from 'classnames';
import React, { LegacyRef, useEffect, useRef, useState } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';

import type { SharedPlayerProps } from '../../player';
import { PlayerControls } from '../player-controls';
import { RetryButton } from '../retry-button';
import styles from './video-player.module.scss';

export interface VideoPlayerProps extends SharedPlayerProps {
  className?: string;
}

export function VideoPlayer({ onProgressChange, className, file, currentTime, isSticky }: VideoPlayerProps) {
  const classes = cn(styles.videoPlayer, className);

  const player = useRef<ReactPlayerProps>(null);

  useEffect(() => {
    if (typeof currentTime?.seconds === 'number') {
      player.current?.seekTo(currentTime.seconds);
      setPlaying(true);
    }
  }, [currentTime]);

  const [percentComplete, setPercentComplete] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [position, setPosition] = useState<number>(0);
  const [ready, setReady] = useState<boolean | null>(null);
  const [playing, setPlaying] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [playbackRate, setPlaybackRate] = useState<number>(1);

  if (ready === false) {
    return (
      <div className={classes}>
        <RetryButton
          file={file}
          onLoad={() => {
            setReady(null);
          }}
        />
      </div>
    );
  }

  return (
    <div className={classes}>
      <div
        className={cn(styles.videoPlayer__wrapper, {
          [styles.videoPlayer__wrapper_loading]: loading,
          [styles.videoPlayer__wrapper_sticky]: isSticky,
        })}
        onClick={() => setPlaying((prev) => !prev)}
      >
        <ReactPlayer
          className={styles.videoPlayer__video}
          url={loading ? '' : file}
          ref={player as LegacyRef<ReactPlayer>}
          playing={playing}
          onDuration={(dur) => setDuration(dur)}
          onStart={() => setPlaying(true)}
          onEnded={() => setPlaying(false)}
          onPause={() => setPlaying(false)}
          onPlay={() => setPlaying(true)}
          onError={() => setReady(false)}
          playbackRate={playbackRate}
          width='100%'
          height='100%'
          onProgress={({ played, playedSeconds }) => {
            setPercentComplete(played * 100);
            setPosition(playedSeconds);
          }}
          onReady={() => setReady(true)}
        />
      </div>
      <PlayerControls
        onProgressChange={onProgressChange}
        file={file}
        loading={loading}
        playing={playing}
        onLoad={() => {
          setLoading(false);
        }}
        onTogglePlayPause={() => setPlaying((prev) => !prev)}
        duration={duration}
        percentComplete={percentComplete}
        onSeek={player.current?.seekTo}
        ready={Boolean(ready)}
        onRate={(rate: number) => setPlaybackRate(rate)}
        position={position}
      />
    </div>
  );
}

