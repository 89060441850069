import cn from 'classnames';
import React from 'react';

import { PageHeader } from 'shared/page-header';
import { Title, Paragraph } from 'shared/typography';

import { PageLayout } from 'components/page-layout';

import { useDocumentTitle } from 'hooks/document-title';

import styles from './cookie-policy.page.module.scss';

export interface CookiePolicyPageProps {
  className?: string;
}

export function CookiePolicyPage({ className }: CookiePolicyPageProps) {
  const classes = cn(styles.cookiePolicyPage, className);
  useDocumentTitle('Политика использования Cookie');

  return (
    <PageLayout className={classes}>
      <PageHeader title={'Политика использования Cookie'}>
        <Paragraph>
          Если ваш браузер поддерживает файлы cookie, мы можем с их помощью и с помощью кодов отслеживания в пассивном
          режиме собирать личную информацию, не позволяющую идентифицировать пользователя.
        </Paragraph>
        <Title level={4}>Что такое файл cookie?</Title>
        Файл cookie — это небольшой текстовый файл, который может быть отправлен на ваш компьютер или мобильное
        устройство для идентификации вашего веб-браузера и действий, совершаемых на вашем компьютере при пользовании
        Платформой Qulap (далее Платформа).
        <Title level={4}>Что такое код отслеживания?</Title>
        <Paragraph>
          Коды отслеживания, или веб-маяки — это веб-технология, которая используется для отслеживания активности
          пользователей на веб-сайтах и в контексте электронных писем, например для определения количества просмотров
          определенной страницы или электронного письма. Коды отслеживания могут содержаться в любом контенте Платформы
          Qulap, в том числе в рекламных текстах и электронных письмах, которые мы рассылаем. Коды отслеживания не видны
          пользователю.
        </Paragraph>
        <Title level={4}>Использует ли Компания файлы cookie и если да, то с какой целью?</Title>
        <Paragraph>
          Да. Мы используем файлы cookie, чтобы: персонализировать ваше взаимодействие с Платформой; делать Платформой
          удобнее (например, чтобы вам не приходилось вводить свое имя при каждом взаимодействии с Платформой);
          отслеживать статистику вашего пользования Платформой и совершенствовать пользовательский интерфейс. Совместное
          использование файлов cookie и кодов отслеживания помогает нам улучшать Платформу и оценивать эффективность
          наших рекламных и маркетинговых кампаний.
        </Paragraph>
        <Paragraph>
          Обратите внимание, что данная политика в отношении файлов cookie не распространяется на сторонние веб-сайты,
          услуги и поставщиков сторонних услуг.
        </Paragraph>
        <Title level={4}>Обязательно ли мне их принимать?</Title>
        <Paragraph>
          Вам необязательно принимать файлы cookie, чтобы пользоваться Платформой. Однако, если вы заблокировали файлы
          cookie, некоторые функции и ресурсы Платформы могут начать работать некорректно либо перестанут работать
          совсем, что скажется на качестве вашего взаимодействия с Платформой.
        </Paragraph>
        <Paragraph>
          Большинство браузеров принимает файлы cookie по умолчанию, однако вы можете настроить браузер так, чтобы он
          уведомлял вас о получении файла cookie, либо заблокировать получение файлов cookie в принципе.
        </Paragraph>
        <Paragraph>
          Некоторые сторонние рекламные сети, например Google, позволяют пользователям отказываться от получения
          определенного контента и настраивать индивидуальные параметры просмотра веб-страниц. Подробнее о том, как
          настраиваются эти параметры, читайте в документации Google.
        </Paragraph>
        <Title level={4}>Файлы журналов</Title>
        <Paragraph>
          Мы собираем информацию, не содержащую персональных данных, с помощью файлов журналов. В них регистрируются
          такие данные, как типы браузеров, доменные имена и другие статистические данные, связанные с использованием
          Платформы. Эта информация может применяться для анализа тенденций, для управления Платформой и для мониторинга
          того, как используется Платформа. Мы можем использовать эту информацию совместно с информацией, содержащей
          персональные данные, для вышеперечисленных и других целей, например для персонализации вашего взаимодействия с
          Платформой и для оценки Платформы в целом.
        </Paragraph>
      </PageHeader>
    </PageLayout>
  );
}

