import { createSlice } from '@reduxjs/toolkit';

import { storeHelper } from 'helpers/store';

import { ProjectRow } from 'models/project';

import { RootState } from 'store/store';

interface State {
  selectedProjects: ProjectRow[];
  valueForFilter: string;
}

const initialState: State = {
  selectedProjects: [],
  valueForFilter: '',
};

const { setAction } = storeHelper<State>();

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setSelectedProjects: setAction('selectedProjects'),
    setValueForFilter: setAction('valueForFilter'),
  },
});

export const projectsActions = projectsSlice.actions;

export const projectsSelector = (state: RootState): State => state.projects;

