import { Menu as AntdMenu } from 'antd';
import { MenuProps as AntdMenuProps } from 'antd/lib/menu';
import cn from 'classnames';
import React from 'react';

import styles from './menu.module.scss';

export interface MenuProps extends AntdMenuProps {
  className?: string;
}

export function Menu({ className, ...props }: MenuProps) {
  const classes = cn(styles.menu, className);

  return <AntdMenu className={classes} {...props} />;
}

