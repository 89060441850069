import { MailOutlined } from '@ant-design/icons';
import cn from 'classnames';
import React, { useState } from 'react';

import { Button } from 'shared/button';
import { Form, FormItem } from 'shared/form';
import { Input } from 'shared/input';
import { Title, Paragraph } from 'shared/typography';

import { AuthLayout } from 'components/auth-layout';

import { getResponseError } from 'helpers/response';
import { validateRules } from 'helpers/validate-rules';

import { useDocumentTitle } from 'hooks/document-title';

import styles from './forgot-password.page.module.scss';
import { ForgotPasswordRequest, useForgotPasswordMutation } from './forgot-password.service';

export interface ForgotPasswordPageProps {
  className?: string;
}

export function ForgotPasswordPage({ className }: ForgotPasswordPageProps) {
  const classes = cn(styles.forgotPasswordPage, className);
  useDocumentTitle('Восстановить пароль');
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
  const [error, setError] = useState<null | string>(null);
  const [success, setSuccess] = useState<null | string>(null);

  const onFinish = async (values: ForgotPasswordRequest) => {
    setError(null);
    try {
      await forgotPassword(values).unwrap();
      setSuccess('Пожалуйста, проверьте указанную почту');
    } catch (error) {
      setError(getResponseError(error));
    }
  };

  return (
    <AuthLayout className={classes} hideHeaderLinks={true}>
      <Title>Восстановить пароль</Title>
      <Paragraph>На указанный Вами email будет отправлено письмо для восстановления пароля</Paragraph>
      <Form
        onFinish={onFinish}
        autoComplete='off'
        error={error}
        success={success}
        actionSlot={
          <Button htmlType='submit' loading={isLoading}>
            Отправить письмо
          </Button>
        }
      >
        <FormItem name='email' rules={[validateRules.required('email'), validateRules.email()]}>
          <Input addonBefore={<MailOutlined />} />
        </FormItem>
      </Form>
    </AuthLayout>
  );
}

